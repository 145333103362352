import React from 'react';
import { globalErrorModalData } from '../../redux-tool-kit/globalErrorModalSlice';
import { useAppSelector } from '../../redux-tool-kit/hooks';
import { Modal } from '@mui/material';
import { ReportSubmitError, ReportSubmitSoftError } from '.';
import { store } from '../../redux-tool-kit/store';
import { clearModal } from '../../redux-tool-kit/globalErrorModalSlice';
import { GlobalErrorModalState } from '../../types';

type ModalOptions = {
  ReportSubmitErr: string;
  ReportSubmitSoftErr: string;
  DeepLinkErr: string;
  ClaimRequired: string;
  None: string;
};

const GlobalErrorModal = () => {
  const modalData = useAppSelector(globalErrorModalData) as GlobalErrorModalState;

  const modalType: ModalOptions = {
    ReportSubmitErr: 'REPORT_SUBMIT_ERROR',
    ReportSubmitSoftErr: 'REPORT_SUBMIT_SOFT_ERROR',
    DeepLinkErr: 'DEEP_LINK_ERROR',
    ClaimRequired: 'CLAIM_REQUIRED',
    None: '',
  };

  const selectComponentContent = () => {
    if (modalData.type && modalType[modalData.type as keyof ModalOptions]) {
      switch (modalType[modalData.type as keyof ModalOptions]) {
        case 'REPORT_SUBMIT_SOFT_ERROR':
          return <ReportSubmitSoftError errorMsgs={modalData.modalContent?.errors || []} close={closeHandler} />;
        default: // 'REPORT_SUBMIT_ERROR'
          return <ReportSubmitError errorMsgs={modalData.modalContent?.errors} close={closeHandler} />;
      }
    } else {
      return <div />;
    }
  };

  const closeHandler = () => {
    store.dispatch(clearModal());
  };

  return (
    <Modal data-testid='global-modal' open={modalData.displaying} onClose={closeHandler}>
      {selectComponentContent()}
    </Modal>
  );
};

export default GlobalErrorModal;
