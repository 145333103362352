import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { RootState } from './store';

export interface Document {
  name: string;
  bytes: string;
}

const documentsSlice = createSlice({
  name: 'documents',
  initialState: [] as Document[],
  reducers: {
    addDocument: (state, action: PayloadAction<Document>) => {
      return state.concat(action.payload);
    },
  },
});

export const { addDocument } = documentsSlice.actions;
export const selectDocuments = (state: RootState) => state.documents;

export default documentsSlice.reducer;
