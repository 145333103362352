import styled from 'styled-components';

export const StyledImagePreview = styled.div`
  position: relative;
  background-color: #1f1f1f;
  img {
    max-width: 100%;
    height: auto;
    display: block;
    margin-left: auto;
    margin-right: auto;
  }
  .image-preview-buttons {
    position: absolute;
    bottom: 5%;
    z-index: 2;
    cursor: pointer;
  }
`;
