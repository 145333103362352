import styled from 'styled-components';

export const StyledDot = styled.div`
  height: 15px;
  width: 15px;
  border-radius: 50%;
`;

export const StyledVehicleCondition = styled.div`
  .supplementals-container {
    border: 1px solid;
    border-color: lightgray;
    border-radius: 2px;
  }
  .bold {
    font-weight: bold;
  }
  .extras-title {
    font-size: 16px;
  }
  .slim > div {
    padding: 8px;
    padding-left: 16px;
  }
  .slim input {
    padding: 0px;
  }
  .inline {
    overflow: hidden;
    white-space: nowrap;
  }
  .damages-list-item-container {
    padding-top: 10px;
    display: flex;
    padding-bottom: 10px;
  }
  .damages-list-group-container {
    border: 1px solid;

    border-color: lightgray;
    border-radius: 2px;
    margin-top: 5px;
    margin-bottom: 5px;
  }
  .flatfish {
  }
  .flatfish-wrapper {
    overflow-y: hidden;
    overflow-x: hidden;
    display: block;
    align-items: center;
    text-align: center;
    padding: 25px;
    position: relative;
  }
  .flatfish-add-button {
    margin-top: -50px;
    background-color: white;
    height: 40px;
    width: 85px;
    left: 0px;
    top: 0px;
    border-radius: 100000000px;
    padding: 5px 15px 5px 10px;
  }
  .flatfish-add-button-text {
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 16px;
  }
  .flatfish-add-button:hover {
    background-color: white;
  }
  .condition-list-title {
    font-size: 20px;
    font-weight: 500;
    line-height: 24px;
    letter-spacing: 0.15000000596046448px;
    text-align: left;
  }
  .condition-heading {
    font-weight: 500;
    font-size: 20px;
    line-height: 24px;
    margin-left: 6px;

    margin-right: auto;
    color: rgba(0, 0, 0, 0.54);
    letter-spacing: 0.15px;

    color: rgba(0, 0, 0, 0.54);
  }
  .condition-list-subtitle {
    font-size: 16px;
    font-weight: 700;
    line-height: 22px;
    letter-spacing: 0.5px;
    text-align: right;
  }
`;
