/* istanbul ignore file */
import { LogLevel, PublicClientApplication } from '@azure/msal-browser';

export const getMsalConfig = (clientId: string): PublicClientApplication =>
  new PublicClientApplication({
    auth: {
      authority: 'https://login.microsoftonline.com/5a9bb941-ba53-48d3-b086-2927fea7bf01',
      clientId: clientId,
      postLogoutRedirectUri: window.origin,
      redirectUri: window.location.origin,
    },
    cache: {
      cacheLocation: 'localStorage',
      storeAuthStateInCookie: true,
    },
    system: {
      loggerOptions: {
        loggerCallback: (level: LogLevel, message: string, containsPii: boolean): void => {
          if (containsPii) {
            return;
          }
          switch (level) {
            case LogLevel.Error:
              console.error(message);
              return;
            case LogLevel.Info:
              console.info(message);
              return;
            case LogLevel.Verbose:
              console.debug(message);
              return;
            case LogLevel.Warning:
              console.warn(message);
              return;
          }
        },
        piiLoggingEnabled: false,
      },
      allowRedirectInIframe: true,
    },
  });
