import { TextField, StandardTextFieldProps } from '@mui/material';
import React from 'react';

import { StyledValidationTextField, MultilineInputAdornment } from '../../styles/mui';

interface ValidatedTextFieldProps extends StandardTextFieldProps {
  maxLength?: number;
  validatedVariant?: 'outlined' | 'standard' | 'filled';
  count?: number;
  showAdornment?: boolean;
}

const ValidatedTextField = ({
  maxLength = 524288,
  validatedVariant,
  count = 0,
  children,
  showAdornment,
  ...rest
}: ValidatedTextFieldProps) => {
  const charsRemainingSlug = `${count}/${maxLength}`;
  return (
    <StyledValidationTextField>
      <TextField
        {...rest}
        fullWidth
        variant={validatedVariant}
        inputProps={{ maxLength: maxLength }}
        InputProps={{
          endAdornment: showAdornment ? (
            <MultilineInputAdornment position='start'>{maxLength && charsRemainingSlug}</MultilineInputAdornment>
          ) : null,
        }}
        data-testid='validated-text-field-input'>
        {children}
      </TextField>
    </StyledValidationTextField>
  );
};

export default ValidatedTextField;
