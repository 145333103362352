import React from 'react';
import TabContext from '@mui/lab/TabContext';
import TabPanel from '@mui/lab/TabPanel';
import { StyledVehicleConditionCapture } from '../../../styles/screens/StyledVehicleConditionCapture';
import ConditionCaptureListView from './ConditionCaptureListView';
import ConditionCaptureFlatfishView from './ConditionCaptureFlatfishView';
import { useComponentFiltered } from '../../../hooks/useComponent';

interface VehicleConditionCaptureProps {
  isExterior: boolean;
  listOverride?: boolean;
}

const VehicleConditionCapture: React.FC<VehicleConditionCaptureProps> = ({
  isExterior,
  listOverride,
}: VehicleConditionCaptureProps) => {
  const componentList = useComponentFiltered(isExterior ? 'EXTERIOR' : 'INTERIOR');

  return (
    <StyledVehicleConditionCapture>
      <TabContext value={isExterior ? '1' : '2'}>
        <TabPanel value='1'>
          {listOverride ? <ConditionCaptureListView componentList={componentList} /> : <ConditionCaptureFlatfishView />}
        </TabPanel>
        <TabPanel value='2'>
          <ConditionCaptureListView componentList={componentList} />
        </TabPanel>
      </TabContext>
    </StyledVehicleConditionCapture>
  );
};

export default VehicleConditionCapture;
