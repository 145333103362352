import { RentalAgreement, RentalAgreementResponse } from '../types';
import useSWRInfinite from 'swr/infinite';
import { getConfig } from '../utils/config';
import useAppParams from './useAppParams';
import { useAppSelector } from '../redux-tool-kit/hooks';
import { selectJwt } from '../redux-tool-kit/userSlice';
import { edgePostFetcher } from '../services';

const RA_PER_PAGE = 25;

const fetcher = ([url, pageIndex, vid, jwt, statuses]: [string, number, string, string | null, string[]]) => {
  const data = {
    rentalAgreementCriteria: [
      {
        vehicle: {
          fleetVehicles: [vid.toUpperCase()],
        },
        statuses,
      },
    ],
    reservationCriteria: [],
    pagination: {
      startRecordIndex: pageIndex * RA_PER_PAGE + 1, // +1 because they are 1 indexed
      pageSize: RA_PER_PAGE,
    },
  };
  return edgePostFetcher(url, jwt, data, 'errors.noMatchingRentalAgreementsFound').then(
    ({ results }: RentalAgreementResponse) => results
  );
};

export const useRentalAgreements = (statuses?: string[]) => {
  const { vid } = useAppParams();
  const jwt = useAppSelector(selectJwt);
  const getKey = (pageIndex: number, previousPageData: RentalAgreement[]) => {
    if (previousPageData && previousPageData.length == 0) {
      return null;
    } else {
      return [getConfig().rentalAgreementsTarget, pageIndex, vid, jwt, statuses];
    }
  };
  return useSWRInfinite<RentalAgreement[]>(getKey, fetcher);
};
