import React from 'react';
import ListItem from '@mui/material/ListItem';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemText from '@mui/material/ListItemText';
import Box from '@mui/material/Box';
import { FixedSizeList, ListChildComponentProps } from 'react-window';
import { useTranslation } from 'react-i18next';
import { Typography } from '@mui/material';

import { VehicleComponent } from '../../../types/claim/vehicle/VehicleComponents';
import { useAppDispatch } from '../../../redux-tool-kit/hooks';
import { setActiveComponent } from '../../../redux-tool-kit/activeComponentSlice';
import { useAppNavigation } from '../../../utils/useURLs';

interface ConditionCaptureListViewProps {
  componentList: VehicleComponent[];
}

const ConditionCaptureListView = ({ componentList }: ConditionCaptureListViewProps) => {
  const { t } = useTranslation();
  const navigation = useAppNavigation();
  const dispatch = useAppDispatch();
  function onRowSelect(component: VehicleComponent) {
    dispatch(setActiveComponent(component));
    navigation.push('/add/NONE');
  }

  function renderRow(props: ListChildComponentProps) {
    const { index, style } = props;

    return (
      <ListItem style={style} key={index} component='li' disablePadding className='list-container'>
        <ListItemButton onClick={() => onRowSelect(componentList[index])}>
          <ListItemText primary={componentList[index].name} />
        </ListItemButton>
      </ListItem>
    );
  }

  return (
    <Box
      sx={{
        width: '100%',
        height: 'auto',
        bgcolor: 'background.paper',
        borderWidth: '2px',
        borderColor: 'gray',
        alignItems: 'center',
        marginLeft: '-20px',
      }}
      data-testid='condition-capture'>
      <Typography className='interiorLabel'>{t('condition.capture.interiorListLabel')}</Typography>
      <FixedSizeList height={1000} width={'102%'} itemSize={49} itemCount={componentList.length} overscanCount={5}>
        {renderRow}
      </FixedSizeList>
    </Box>
  );
};

export default ConditionCaptureListView;
