import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { Condition, ConditionSource, ConditionState } from '../types';
import { NewCondition } from '../types/conditionTypes/Condition';
import { conditionReportPayloadInitialState } from '../types/conditionTypes/ConditionReportPayload';
import type { RootState } from './store';
import { uniqueId, isEmpty, pick } from 'lodash';
import { getConfig } from '../utils/config';

export const conditionInitialState: ConditionState = {
  currentConditionReport: conditionReportPayloadInitialState,
  conditionCheckBoxes: {},
  newConditions: [],
  fuelPercentage: null,
  odometer: { value: null, unit: null },
};

export const conditionSlice = createSlice({
  name: 'condition',
  initialState: conditionInitialState,
  reducers: {
    setCondition: (state, action: PayloadAction<object>) => {
      const newState: ConditionState = {
        ...state,
        ...action.payload,
      };
      return newState;
    },
    addNewCondition: {
      prepare: (condition: NewCondition) => ({ payload: [condition, uniqueId()] as [NewCondition, string] }),
      reducer: (state, action: PayloadAction<[NewCondition, string]>) => {
        const [condition, id] = action.payload;
        const checkboxArray = Object.entries(state.conditionCheckBoxes);

        const urnToBeRemoved = checkboxArray?.find(([urn, check]) => {
          if (
            check.component ===
              `${[`urn:com.ehi:${getConfig().environment}:reference:vehicle:condition:component:key`]}` &&
            condition.component ===
              `${[`urn:com.ehi:${getConfig().environment}:reference:vehicle:condition:component:key`]}`
          ) {
            return urn;
          }
        });

        const newState: ConditionState = {
          ...state,
          currentConditionReport: {
            ...state.currentConditionReport,
            hasNewDamage: true,
            hasClaimableDamage: condition.isClaimable || state.currentConditionReport.hasClaimableDamage,
          },
          newConditions: [...state.newConditions, { ...condition, urn: id }],
          conditionCheckBoxes: {
            ...state.conditionCheckBoxes,
            [id]: {
              isClaimable: condition.isClaimable,
              checked: true,
              component: condition.component,
            },
            ...(urnToBeRemoved?.[0] && {
              [urnToBeRemoved[0]]: {
                isClaimable: condition.isClaimable,
                checked: false,
                component: condition.component,
              },
            }),
          },
        };
        return newState;
      },
    },
    setConditionSource: (state, action: PayloadAction<ConditionSource>) => {
      const newState: ConditionState = {
        ...state,
        currentConditionReport: {
          ...state.currentConditionReport,
          source: action.payload,
        },
      };
      return newState;
    },
    // Get the previous conditions, add and set checked field to false
    initCheckboxes: (state, { payload }: PayloadAction<Condition[]>) => {
      if (isEmpty(state.conditionCheckBoxes)) {
        const conditions = [...(payload || [])] as Condition[];
        const checks = conditions.map((condition: Condition) => [
          condition.urn,
          {
            component: condition.component,
            checked: true,
            isClaimable: condition.isClaimable,
          },
        ]);

        return { ...state, conditionCheckBoxes: Object.fromEntries(checks) };
      } else {
        return state;
      }
    },
    // Change checked state in Redux (use in getConditionListGrouping)
    toggleCheckbox: (state, action: PayloadAction<string>) => {
      const checked = state.conditionCheckBoxes[action.payload]?.checked;
      if (checked !== undefined) {
        state.conditionCheckBoxes[action.payload].checked = !checked;
      }
    },
    setCheckbox: {
      prepare: (id: string, checked: boolean) => ({ payload: [id, checked] as [string, boolean] }),
      reducer: (state, { payload: [id, checked] }: PayloadAction<[string, boolean]>) => {
        const checkbox = state.conditionCheckBoxes[id];
        if (checkbox) {
          checkbox.checked = checked;
        }
      },
    },
    // affect checkBoxes array according to "check all" checkboxes being checked or unchecked
    checkAllOfType: (state, action: PayloadAction<boolean>) => {
      const checking = Object.values(state.conditionCheckBoxes).some(
        (checkbox) => checkbox.isClaimable === action.payload && !checkbox.checked
      );
      Object.values(state.conditionCheckBoxes).forEach((checkbox) => {
        if (checkbox.isClaimable === action.payload) {
          checkbox.checked = checking;
        }
      });
    },
    removeNewUnchecked: (state) => {
      state.newConditions = state.newConditions.filter(({ urn }) => state.conditionCheckBoxes[urn].checked);
    },
    setFuelPercentage: (state, action: PayloadAction<number | null>) => {
      state.fuelPercentage = action.payload;
    },
    setOdometerValue: (state, action: PayloadAction<number | null>) => {
      state.odometer.value = action.payload;
    },
    setOdometerUnit: (state, action: PayloadAction<string | null>) => {
      state.odometer.unit = action.payload;
    },
  },
});

export const {
  setCondition,
  addNewCondition,
  setConditionSource,
  initCheckboxes,
  setCheckbox,
  toggleCheckbox,
  checkAllOfType,
  removeNewUnchecked,
  setFuelPercentage,
  setOdometerValue,
  setOdometerUnit,
} = conditionSlice.actions;

export const selectCheckboxes = (state: RootState) => state.condition.conditionCheckBoxes;
export const selectNewConditions = (state: RootState) => state.condition.newConditions;
export const selectFuelPercentage = (state: RootState) => state.condition.fuelPercentage;
export const selectOdometer = (state: RootState) => state.condition.odometer;
export const selectMetrics = (state: RootState) => {
  return pick(state.condition, ['fuelPercentage', 'odometer']);
};

export default conditionSlice.reducer;
