import React from 'react';
import { Button } from '@mui/material';
import { StyledReportSubmitError } from '../../styles/modals/StyledReportSubmitError';
import { setSoftErrorDismiss } from '../../redux-tool-kit/globalErrorModalSlice';
import { store } from '../../redux-tool-kit/store';
import { useTranslation } from 'react-i18next';

interface ReportSubmitSoftErrorProps {
  errorMsgs: string[];
  close: () => void;
}

const ReportSubmitSoftError = ({ errorMsgs, close }: ReportSubmitSoftErrorProps) => {
  const { t } = useTranslation();

  const handleDismiss = () => store.dispatch(setSoftErrorDismiss({ softErrorDismiss: true }));

  return (
    <StyledReportSubmitError data-testid='report-submit-soft-error'>
      <div id='fuel-mileage-header' data-testid='fuel-mileage-header'>
        {t('errors.modalHead.odometer')}
      </div>
      <div data-testid='error-msg'>
        <ul>
          {errorMsgs?.map((msg) => (
            <li key={msg}>{msg}</li>
          ))}
        </ul>
      </div>
      <div id='close-button'>
        <Button data-testid='modal-dismiss' onClick={() => handleDismiss()}>
          {t('yes')}
        </Button>
        <Button data-testid='modal-close' onClick={() => close()}>
          {t('no')}
        </Button>
      </div>
    </StyledReportSubmitError>
  );
};

export default ReportSubmitSoftError;
