import React from 'react';
import { Grid } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { groupBy, mapValues } from 'lodash';
import { useAppDispatch, useAppSelector } from '../../../redux-tool-kit/hooks';
import { additionalEquipmentURNs, equipmentURNs } from '../../../shared/Equipment/Constants';
import { useSearchParams } from '../../../utils/useURLs';
import { EquipmentToggle } from './EquipmentToggle';
import { addNewCondition, selectCheckboxes, setCheckbox } from '../../../redux-tool-kit/conditionSlice';
import { mostSignificant } from '../../../utils/urn';
import { VehicleComponent } from '../../../types/claim/vehicle/VehicleComponents';
import { useGetConditions } from '../../../utils/condition/ConditionHelper';
import { EquipmentHeader } from '../../../styles/screens/StyledConditionCapture';
import useComponent from '../../../hooks/useComponent';
import { getConfig } from '../../../utils/config';

const EquipmentConditionsView = () => {
  const { t } = useTranslation();
  const dispatch = useAppDispatch();

  const { data: components } = useComponent();
  const getConditions = useGetConditions();
  const allConditions = getConditions(true).concat(getConditions(false));
  const conditions = groupBy(allConditions, 'component');
  const checkboxes = mapValues(useAppSelector(selectCheckboxes), 'checked');

  const searchObject = useSearchParams();
  const isSecondKeyRequired = searchObject.get('showSecondKey');
  const isAdditionalEquipmentRequired = searchObject.get('requireAdditionalEquipFlow') === 'true';

  let equipmentList: string[];
  if (isAdditionalEquipmentRequired) {
    equipmentList = [...equipmentURNs, ...additionalEquipmentURNs];
  } else {
    equipmentList = [...equipmentURNs];
  }
  const componentList = components?.filter((component) => equipmentList.includes(component.urn)) ?? [];

  const updateEquipment = (component: VehicleComponent, isPresent: boolean) => {
    const condition = conditions[component.urn]?.find(
      (condition) => mostSignificant(condition.conditionType) === 'missing'
    );

    if (!condition) {
      if (!isPresent) {
        dispatch(
          addNewCondition({
            images: [],
            component: component.urn,
            conditionType: `urn:com.ehi:${getConfig().environment}:reference:vehicle:condition:conditionType:missing`,
            isClaimable: true,
            comment: '',
          })
        );
      }
    } else {
      dispatch(setCheckbox(condition.urn, !isPresent));
    }
  };

  const keyConditions =
    conditions[`urn:com.ehi:${getConfig().environment}:reference:vehicle:condition:component:key`] ?? [];
  const updateKeys = (key: 1 | 2, isPresent: boolean) => {
    const key1Present = key === 1 ? isPresent : isKeyPresent(1);
    const key2Present = key === 2 ? isPresent : isKeyPresent(2);
    keyConditions.forEach((condition) => {
      if (condition.urn && condition.conditionType.includes('Missing')) {
        dispatch(setCheckbox(condition.urn, false));
      }
    });
    if (key1Present && key2Present) return;
    let conditionKey: string;
    if (!key1Present && !key2Present) {
      conditionKey = 'bothSetsMissing';
    } else if (!key1Present) {
      conditionKey = 'firstSetMissing';
    } else {
      conditionKey = 'secondSetMissing';
    }
    conditionKey = `urn:com.ehi:${getConfig().environment}:reference:vehicle:condition:conditionType:${conditionKey}`;
    const condition = keyConditions.find((condition) => condition.conditionType === conditionKey);
    if (condition) {
      dispatch(setCheckbox(condition.urn, true));
    } else {
      dispatch(
        addNewCondition({
          images: [],
          component: `urn:com.ehi:${getConfig().environment}:reference:vehicle:condition:component:key`,
          conditionType: conditionKey,
          isClaimable: true,
          comment: '',
        })
      );
    }
  };

  const isKeyPresent = (key: 1 | 2): boolean => {
    const condition = keyConditions.find(
      (condition) => condition.conditionType.includes('Missing') && checkboxes[condition.urn]
    );
    if (!condition) return true;
    const conditionType = mostSignificant(condition.conditionType);
    return !(
      conditionType === 'bothSetsMissing' ||
      (key === 1 && conditionType === 'firstSetMissing') ||
      (key === 2 && conditionType === 'secondSetMissing')
    );
  };

  return (
    <>
      <EquipmentHeader>{t('equipment')}</EquipmentHeader>
      <Grid container spacing={2}>
        <Grid item xs={12} sm={6} md={4}>
          <EquipmentToggle
            onChange={(isPresent) => updateKeys(1, isPresent)}
            isEquipmentPresent={isKeyPresent(1)}
            name={t('condition.capture.key')}
          />
        </Grid>
        {isSecondKeyRequired === null || isSecondKeyRequired === 'true' ? (
          <Grid item xs={12} sm={6} md={4}>
            <EquipmentToggle
              onChange={(isPresent) => updateKeys(2, isPresent)}
              isEquipmentPresent={isKeyPresent(2)}
              name={t('condition.capture.key2')}
            />
          </Grid>
        ) : null}

        {componentList.map((component) => {
          const isPresent = !conditions[component.urn]?.some(
            (condition) => mostSignificant(condition.conditionType) === 'missing' && checkboxes[condition.urn]
          );
          return (
            <Grid item xs={12} sm={6} md={4} key={component.urn}>
              <EquipmentToggle
                name={component.name}
                onChange={(present) => updateEquipment(component, present)}
                isEquipmentPresent={isPresent}
              />
            </Grid>
          );
        })}
      </Grid>
    </>
  );
};

export default EquipmentConditionsView;
