import styled from 'styled-components';

export const StyledSchematicContainer = styled.div`
  position: relative;
  width: calc(100% - 20px);

  span {
    float: right;
    font-size: 14px;
  }

  div#skeleton-holder {
    height: 500px;
    width: 100%;

    #schematic-placeholder {
      width: 100%;
    }
  }
`;
