export interface vehicleComponentsState {
  components: VehicleComponent[];
}

export interface VehicleComponent {
  applicableConditionTypes: string[];
  location: string;
  urn: string;
  name: string;
}

// Set the initial state
export const initialState: vehicleComponentsState = {
  components: [],
};

// Set the initial state for a single component
export const initialStateSingle: VehicleComponent = {
  applicableConditionTypes: [],
  location: '',
  urn: '',
  name: '',
};
