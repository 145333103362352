import useSWR from 'swr';
import { edgePostFetcher } from '../services';
import { getConditionsRequestPayloadInitialState } from '../types/conditionTypes/request/GetConditionsRequestPayload';
import { useAppSelector } from '../redux-tool-kit/hooks';
import { selectJwt } from '../redux-tool-kit/userSlice';
import type { Condition } from '../types';
import { getConfig } from '../utils/config';
import useAppParams from './useAppParams';
import useRaWearAndTear from './useRaWearAndTear';

const fetcher = ([url, jwt]: [string, string | null]) =>
  edgePostFetcher(url, jwt, getConditionsRequestPayloadInitialState, 'errors.getConditionsError').then(
    ({ conditions }) => conditions
  );

const usePreviousClaimableConditions = () => {
  const jwt = useAppSelector(selectJwt);
  const { vid } = useAppParams();
  return useSWR<Condition[]>(
    vid ? [`${getConfig().getConditionReports}&id=${vid && vid.toUpperCase()}`, jwt] : null,
    fetcher
  );
};

const useCondition = () => {
  const { data: previousWearAndTear, isLoading: isWearAndTearLoading } = useRaWearAndTear();
  const { data: previousDamage, isLoading: isDamageLoading } = usePreviousClaimableConditions();
  if (isDamageLoading || isWearAndTearLoading) {
    return { data: undefined, isLoading: true };
  } else {
    return { data: (previousWearAndTear ?? []).concat(previousDamage ?? []), isLoading: false };
  }
};

export default useCondition;
