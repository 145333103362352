import React from 'react';
import styled from 'styled-components';

interface SRSEProps {
  children: React.ReactNode;
}

export const StyledReportSubmitError = styled.div<SRSEProps>`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 0px;
  isolation: isolate;
  width: 561px;
  background: #fff;
  transform: translate(-50%, -50%);
  position: absolute;
  top: 40%;
  left: 50%;
  box-shadow: 0px 24px 38px rgba(0, 0, 0, 0.14), 0px 9px 46px rgba(0, 0, 0, 0.12), 0px 11px 15px rgba(0, 0, 0, 0.2);
  border-radius: 4px;

  ul {
    list-style: none;
    li {
      padding-right: 1.5rem;
    }
  }

  div#fuel-mileage-header {
    font-style: normal;
    font-weight: 500;
    font-size: 20px;
    line-height: 24px;
    letter-spacing: 0.15px;
    border-bottom: 1px solid rgba(0, 0, 0, 0.12);
    width: inherit;
    padding: 20px;
  }

  div#close-button {
    align-self: end;
    padding: 0 10px 10px 0;

    button {
      color: #fff;
      background-color: #4d789d;
      transition: background-color 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms,
        box-shadow 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms, border-color 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms,
        color 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;

      box-shadow: 0px 3px 1px -2px rgb(0 0 0 / 20%), 0px 2px 2px 0px rgb(0 0 0 / 14%), 0px 1px 5px 0px rgb(0 0 0 / 12%);
    }

    button:hover {
      text-decoration: none;
      background-color: rgb(53, 84, 109);
      box-shadow: 0px 2px 4px -1px rgb(0 0 0 / 20%), 0px 4px 5px 0px rgb(0 0 0 / 14%), 0px 1px 10px 0px rgb(0 0 0 / 12%);
    }
  }
`;
