import { Insurance } from '../insurance/Insurance';
import { Renter } from '../person/Renter';
import { BaseVehicleInformation } from './BaseVehicleInformation';
import { Vehicle } from './Vehicle';

export interface FirstPartyVehicleInformation extends BaseVehicleInformation {
  authorizedDriver: boolean;
  renter?: Renter;
  renterIsAlsoDriver?: boolean;
  renterIsAlsoPassenger?: boolean;
  uninsuredRenter?: boolean;
  vehicle?: Vehicle;
  vehicleInsurance?: Insurance;
}

export const firstPartyVehicleInformationInitialState: FirstPartyVehicleInformation = {
  authorizedDriver: true,
};
