import * as React from 'react';
import Paper from '@mui/material/Paper';
import StepperMUI from '@mui/material/Stepper';
import Step from '@mui/material/Step';
import { StepLabel } from '@mui/material';
import { Check } from '@mui/icons-material';
import { useTranslation } from 'react-i18next';

const steps = ['claim', 'loss', 'sign'];
interface StepperProps {
  activeStep: number;
}
export default function Stepper({ activeStep }: StepperProps) {
  const { t } = useTranslation();
  return (
    <Paper square sx={{ width: '100%', marginBottom: '5px', paddingTop: '20px', paddingBottom: '20px' }}>
      <StepperMUI alternativeLabel activeStep={activeStep}>
        {steps.map((label, index) => (
          <Step
            sx={{
              '& .MuiStepConnector-line': {
                borderColor: activeStep >= index ? '#0E897B' : ' rgba(0, 0, 0, 0.12)',
                borderWidth: '2px',
              },
            }}
            key={t(label)}>
            <StepLabel
              icon={
                <div
                  style={{
                    height: '25px',
                    width: '25px',
                    borderRadius: '50%',
                    borderWidth: '2px',
                    borderStyle: 'solid',
                    borderColor: activeStep < index ? 'rgba(0, 0, 0, 0.54)' : '#4D789D',
                    backgroundColor: activeStep <= index ? 'transparent' : '#0E897B',
                  }}>
                  {activeStep >= index && <Check sx={{ color: activeStep === index ? '#4D789D' : 'white' }} />}
                </div>
              }>
              {t(label)}
            </StepLabel>
          </Step>
        ))}
      </StepperMUI>
    </Paper>
  );
}
