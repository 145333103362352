import useSWR from 'swr';

import { useAppSelector } from '../redux-tool-kit/hooks';
import { selectJwt } from '../redux-tool-kit/userSlice';
import { ImageSearchResponse } from '../types/apiTypes';
import { useSearchParams } from '../utils/useURLs';
import { getConfig } from '../utils/config';
import { edgePostFetcher } from '../services';
import useVehicle from './useVehicle';

const fetcher = ([url, payload, jwt]: [string, { vin: string; context: string }, string | null]) =>
  edgePostFetcher(url, jwt, payload, 'errors.postImagesError');
const useImageSearch = () => {
  const jwt = useAppSelector(selectJwt);
  const searchObject = useSearchParams();
  const { data: vehicle } = useVehicle();
  const vin = vehicle?.vehicleAsset.vin;
  const conditionContext = searchObject.get('conditionContext');

  const payload = {
    vin,
    context: conditionContext,
  };
  return useSWR<ImageSearchResponse>(payload ? [`${getConfig().imageSearch}`, payload, jwt] : null, fetcher);
};

export default useImageSearch;
