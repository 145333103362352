import React from 'react';
import { CircularProgress } from '@mui/material';
import { noop } from 'lodash';

import { AuthProvider, AuthTimers, Environment, useLogout } from '@ehi/auth';
import { useConfig } from '../../services/Config/ConfigService';
import { useClearCache } from '../../utils/useClearCache';
import { MsalProvider } from './MsalProvider';
import { CALLING_APP_ID } from '../../shared/constants';
import { getConfig } from '../../utils/config';
import useLogoutParams from '../../hooks/useLogoutParams';

interface ConditionCaptureAuthProviderProps {
  children: React.ReactNode;
}

const ConditionCaptureAuthProvider: React.FunctionComponent<ConditionCaptureAuthProviderProps> = ({
  children,
}: ConditionCaptureAuthProviderProps): JSX.Element => {
  const { data: config } = useConfig();
  const appConfig = getConfig();
  const { clearCacheAndLogout } = useClearCache();
  const logoutParams = useLogoutParams();
  const onLogout = useLogout(logoutParams);

  const audience = config?.audience;
  const authEndpoint = config?.webEnvironment as Environment;
  const locationKey = config?.locationKey;

  const onFailure = (errorResponse: Response | any): void => {
    console.error(errorResponse);
  };

  if (audience === undefined || locationKey === undefined) {
    return <CircularProgress />;
  }

  return (
    <MsalProvider>
      <AuthProvider
        authParams={{
          isDevMode: location.port === '3000',
          audience: config?.audience ?? '',
          ehiCallingApplication: 'Condition Capture',
          environment: authEndpoint,
          jwtLocationKey: config?.locationKey ?? '',
          authWebUrl: new URL(appConfig['authWebUrl']),
          callingAppId: CALLING_APP_ID,
          port: Number(location.port),
          redirectUrl: new URL(location.href),
        }}
        loginErrorProps={{
          handleRefresh: () => clearCacheAndLogout(),
          appName: 'Condition Capture',
        }}
        onLoginFailure={onFailure}
        onLoginSuccess={noop}>
        <AuthTimers
          authIdleTimerProps={{
            // VEM or GreenLight will handle the actual timeout so setting this to a large amount.
            // There is an issue when running VEM locally with cookies so this will prevent it from logging out.
            timeout: 999999999999999,
            logout: onLogout,
          }}
          logout={onLogout}>
          {children}
        </AuthTimers>
      </AuthProvider>
    </MsalProvider>
  );
};

export default ConditionCaptureAuthProvider;
