import { formatISO } from 'date-fns';
import { find, map, transform } from 'lodash';

// Order of transformers matters wrt subclassing
export const walk = (tree: any, transformers: [Function, Function][]): any => {
  const transformer = find(transformers, ([constructor, _transformer]) => tree instanceof constructor)?.[1];
  if (transformer) {
    return transformer(tree);
  } else if (typeof tree === 'object') {
    return transform(tree, (result, value, key) => {
      result[key] = walk(value, transformers);
    });
  } else if (Array.isArray(tree)) {
    return map(tree, (value) => walk(value, transformers));
  } else {
    return tree;
  }
};

export const localTimeIsoTransformer = formatISO;
