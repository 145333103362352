import React from 'react';
import { Grid } from '@mui/material';
import { useTranslation } from 'react-i18next';

export const QuestionsHeader = () => {
  const { t } = useTranslation();
  return (
    <>
      <Grid item xs={12}>
        <strong style={{ fontSize: '20px' }}>{t('questions')}</strong>
      </Grid>
      <Grid item xs={12} sx={{ paddingBottom: '5%', fontSize: '12px', color: ' rgba(0, 0, 0, 0.54)' }}>
        {`*${t('required')}`}
      </Grid>
    </>
  );
};
