import useSWRImmutable from 'swr/immutable';
import { find } from 'lodash';

import { getConfig } from '../../utils/config';
import { showNetworkErrorSnackbar } from '../../utils/snackBar/snackBarAlert';
import useVehicle from '../../hooks/useVehicle';
import { failConditionCapture } from '../../utils/navigation';
import { addSuccessCall } from '../../redux-tool-kit/networkSuccessSlice';
import { useAppDispatch, useAppSelector } from '../../redux-tool-kit/hooks';
import { edgeGetFetcher } from '../index';
import { selectJwt } from '../../redux-tool-kit/userSlice';

type View = 'FRONT_RIGHT' | 'RIGHT' | 'FRONT_LEFT' | 'LEFT' | 'TOP' | 'REAR' | 'BACK' | 'FRONT' | 'NONE';

export const useVehicleSVG = () => {
  const { data: vehicle } = useVehicle();
  const dispatch = useAppDispatch();
  const sipp = vehicle?.vehicleAsset.specification.sippCode;
  const country = vehicle?.vehicleAsset.registrationPlate.jurisdiction.country;
  const url = `${getConfig().vehicleSchematicsTarget}&vehicleClass=${sipp}&vehicleCountry=${country}`;
  const jwt = useAppSelector(selectJwt);

  return useSWRImmutable<Record<View, string>>(vehicle && url, async (url) => {
    try {
      const { schematics } = await edgeGetFetcher(url, jwt, 'errors.getVehicleSvgError');
      const entries = await Promise.all(
        ['BACK', 'LEFT', 'FRONT', 'RIGHT', 'TOP'].map(async (view) => {
          const schematicResponse = await fetch(find(schematics, ['name', view]).urlTemplate);
          return [view, await schematicResponse.text()];
        })
      );

      dispatch(addSuccessCall('svgs'));
      return Object.fromEntries(entries);
    } catch (error) {
      console.error(error);
      showNetworkErrorSnackbar('errors.getVehicleSvgError');
      failConditionCapture();
    }
  });
};

export const useFindLocationView = (): ((locationId: string) => View) => {
  const { data, error, isLoading } = useVehicleSVG();

  return (locationId: string): View => {
    if (error || isLoading || !data) {
      return 'NONE';
    }

    const parser = new DOMParser();
    const matching = Object.entries(data).find(([_key, data]) => {
      const doc = parser.parseFromString(data, 'application/xml');
      return doc.querySelector(`use[id="${locationId}I"]`);
    });

    if (matching) {
      return matching[0] as View;
    } else {
      return 'NONE';
    }
  };
};
