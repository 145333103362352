import { store } from '../../redux-tool-kit/store';
import { hideSnackBar, showSnackBar } from '../../redux-tool-kit/snackBarSlice';
import { showNetworkErrorAlert } from '../../redux-tool-kit/networkErrorAlertSlice';

export const showSnackBarAlert = (message?: string) => {
  store.dispatch(
    showSnackBar({
      show: true,
      message,
    })
  );
};

export const hideSnackBarAlert = () => {
  store.dispatch(hideSnackBar());
};

export const showNetworkErrorSnackbar = (message?: string) => {
  store.dispatch(
    showNetworkErrorAlert({
      showAlert: true,
      message: message ? message : 'errors.networkErrorOccurred',
    })
  );
};
