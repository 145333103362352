import React, { useEffect, useState } from 'react';
import { Box, Button, Divider, Grid, Typography, InputLabel, Dialog, CircularProgress } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { StyledViewImages } from '../../../styles/shared/images/StyledViewImages';
import { setImgElemStyle } from '../../../utils/condition/ImageDisplayHelper';
import { ImageSearchResponse } from '../../../types/apiTypes';
import { ImageCarousel } from '../ImageCarousel/ImageCarousel';
import { retrieveImageFromURN } from '../../../services/Images/ImageService';
import { useAppSelector } from '../../../redux-tool-kit/hooks';
import { selectJwt } from '../../../redux-tool-kit/userSlice';
import { mostSignificant } from '../../../utils/urn';

interface ViewImagesProps {
  imageData?: ImageSearchResponse;
}

const ViewImages = ({ imageData }: ViewImagesProps) => {
  const { t } = useTranslation();
  const jwt = useAppSelector(selectJwt);
  const [showCarousel, setShowCarousel] = useState(false);
  const [initialImageSelection, setInitialImageSelection] = useState(0);
  const [images, setImages] = React.useState(['']);

  useEffect(() => {
    async function getImages() {
      if (imageData) {
        const imageArray = [];
        for (const imgData of imageData.images) {
          if (imgData.urn)
            imageArray.push(
              (await retrieveImageFromURN(mostSignificant(imgData.files[0]?.imageFile) || '', jwt || '')).data.data
                .imageBytes
            );
        }
        setImages(imageArray);
      }
    }
    getImages();
  }, []);

  const displayThumbnails = () =>
    imageData &&
    imageData.images.map((img, index) => (
      <Grid key={img.captureDateTime.toString()} data-testid={img.captureDateTime.toString()} item xs={4}>
        {images[0] && (
          <Box
            component='img'
            sx={setImgElemStyle()}
            onClick={() => {
              setInitialImageSelection(index);
              setShowCarousel(true);
            }}
            src={`data:image/jpeg;base64,${images[index]}`}
          />
        )}
        {!images[0] && (
          <Box sx={setImgElemStyle()} onClick={() => {}}>
            <CircularProgress id='spinner' />
          </Box>
        )}
        <Typography sx={{ textAlign: 'center' }}>{t('takenOn')}</Typography>
        <Typography sx={{ textAlign: 'center' }}>
          <b>{t('intlImageDate', { date: new Date(img.captureDateTime) })}</b>
          {t('intlImageTime', { date: new Date(img.captureDateTime) })}
        </Typography>
      </Grid>
    ));

  return (
    <StyledViewImages>
      <Box padding={2} className='header'>
        {t('claims.viewCustomerPhotos')}
      </Box>
      <Divider />
      <Box padding={2}>
        {imageData && imageData.images.length > 0 && (
          <>
            <Grid item xs={12}>
              <InputLabel>{t('condition.view.selectImageInstructions')}</InputLabel>
            </Grid>
            <Grid container item columnSpacing={1} xs={12}>
              {displayThumbnails()}
            </Grid>
          </>
        )}
      </Box>
      <Dialog open={showCarousel} onClose={() => setShowCarousel(false)}>
        <ImageCarousel
          images={
            imageData?.images.map((condImage, index) => ({
              src: `data:image/jpeg;base64,${images[index]}`,
              date: condImage.captureDateTime,
            })) || []
          }
          initialIndex={initialImageSelection}
          header={t('claims.viewCustomerPhotos')}
        />
        <Divider />
        <Grid container justifyContent='right'>
          <Grid item>
            <Button size='small' variant='contained' onClick={() => setShowCarousel(false)}>
              {t('close')}
            </Button>
          </Grid>
        </Grid>
      </Dialog>
    </StyledViewImages>
  );
};

export default ViewImages;
