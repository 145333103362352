import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import {
  AddConditionDetailsBox,
  AddConditionHeader,
  ConditionColumn,
} from '../../../styles/screens/StyledConditionModal';
import { Box, Button, Dialog, Divider, Grid, InputLabel, Typography } from '@mui/material';
import { Condition } from '../../../types';
import { ImageCarousel } from '../../../shared/images/ImageCarousel/ImageCarousel';
import useConditionType from '../../../hooks/useConditionType';
import { useComponentMap } from '../../../hooks/useComponent';
import ImageThumbnail from './ImageThumbnail';
import { VIS_FULL_RESOLUTION } from '../../../shared/constants';
import { visTimestampToEpoch } from '../../../utils/image';
interface ViewConditionDetailsProps {
  data: Condition;
}

const ViewConditionDetails = ({ data }: ViewConditionDetailsProps) => {
  const { t } = useTranslation();
  const components = useComponentMap();
  const { data: conditionTypes } = useConditionType();
  const [showCarousel, setShowCarousel] = useState(false);
  const [initialImageSelection, setInitialImageSelection] = useState(0);

  const displayThumbnails = () =>
    data.images.map((img, index) => (
      <ImageThumbnail
        key={index}
        img={img}
        onClick={() => {
          setInitialImageSelection(index);
          setShowCarousel(true);
        }}
      />
    ));

  return (
    <AddConditionDetailsBox>
      <Box>
        <AddConditionHeader sx={{ float: 'inline-start' }}>{t('condition.view.conditionDetails')}</AddConditionHeader>
      </Box>

      <Grid container rowSpacing={1} alignItems='baseline'>
        <Grid item md={6} sm={12}>
          <ConditionColumn>
            <Grid xs={12}>
              <InputLabel>{t('condition.view.labels.location')}</InputLabel>
              <Typography fontWeight={700} data-testid={'ViewConditionDetails-component'}>
                {components[data.component]}
              </Typography>
            </Grid>
          </ConditionColumn>
        </Grid>
        <Grid item md={6} sm={12}>
          <ConditionColumn>
            <Grid xs={12}>
              <InputLabel>{t('condition.view.labels.type')}</InputLabel>
              <Typography fontWeight={700} data-testid={'ViewConditionDetails-conditionType'}>
                {conditionTypes?.[data.conditionType] ?? ''}
              </Typography>
              <Typography data-testid={'ViewConditionDetails-conditionDamageType'}>
                {data.isClaimable ? t('damage') : t('wearAndTear')}
              </Typography>
            </Grid>
          </ConditionColumn>
        </Grid>
        <Grid item xs={12}>
          <ConditionColumn>
            <InputLabel>{t('condition.view.additionalNotes')}</InputLabel>
            <Typography data-testid={'ViewConditionDetails-comment'}>{data.comment}</Typography>
          </ConditionColumn>
        </Grid>
        {data.images.length > 0 && (
          <>
            <Grid item xs={12}>
              <ConditionColumn>
                <Divider />
              </ConditionColumn>
            </Grid>
            <Grid item xs={12}>
              <ConditionColumn>
                <Typography className='select-image-instructions'>
                  {t('condition.view.selectImageInstructions')}
                </Typography>
              </ConditionColumn>
            </Grid>
            <Grid container item columnSpacing={1} xs={12}>
              {displayThumbnails()}
            </Grid>
          </>
        )}
      </Grid>
      <Dialog open={showCarousel} onClose={() => setShowCarousel(false)}>
        <ImageCarousel
          images={data.images.map((img) => ({
            src: img.formattedImages[VIS_FULL_RESOLUTION].url,
            date: visTimestampToEpoch(img),
          }))}
          initialIndex={initialImageSelection}
          header={t('viewEmployeePhotos')}
        />
        <Divider />
        <Grid container justifyContent='right'>
          <Grid item>
            <Button size='small' variant='contained' onClick={() => setShowCarousel(false)}>
              {t('close')}
            </Button>
          </Grid>
        </Grid>
      </Dialog>
    </AddConditionDetailsBox>
  );
};
export default ViewConditionDetails;
