import axios from 'axios';
import { getConfig } from '../utils/config';
import { showNetworkErrorSnackbar } from '../utils/snackBar/snackBarAlert';

export const edgePostFetcher = (url: string, jwt: string | null, data: unknown = {}, alertMessage: string) =>
  axios
    .post(`${getConfig().superedge}${url}`, data, {
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${jwt}`,
        Accept: 'application/json',
      },
    })
    .then(({ data }) => data)
    .catch(({ error }) => {
      console.error(error);
      showNetworkErrorSnackbar(alertMessage);
    });

export const edgeGetFetcher = (
  url: string,
  jwt: string | null,
  alertMessage: string,
  optionalHeaders?: Record<string, unknown>
) =>
  axios
    .get(`${getConfig().superedge}${url}`, {
      headers: {
        Authorization: `Bearer ${jwt}`,
        Accept: 'application/json',
        ...optionalHeaders,
      },
    })
    .then(({ data }) => data)
    .catch(({ error }) => {
      console.error(error);
      showNetworkErrorSnackbar(alertMessage);
    });
