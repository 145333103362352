import React from 'react';
import { FormControl, FormHelperText, InputLabel, MenuItem, Select } from '@mui/material';
import { useTranslation } from 'react-i18next';

import { StyledMenuItem } from '../../../styles/screens/StyledVehicleInfoGrid';

interface StandardFuelSelectorProps {
  onChange: (val: number) => any;
  value: number | null | undefined;
  disabled: boolean;
  isOptional: boolean;
}

const StandardFuelSelector = ({ value, onChange, disabled, isOptional }: StandardFuelSelectorProps) => {
  const { t } = useTranslation();

  const eighths = value != null && value >= 0 ? Math.round(value * 8) / 8 : null;

  const fuelLabel = isOptional && eighths == null ? t('fuelLevelOptional') : t('conditionCapScr.fuelLevel');

  return (
    <FormControl fullWidth>
      <InputLabel id='fuel-level'>{fuelLabel}</InputLabel>
      <Select
        disabled={disabled}
        labelId='fuel-level'
        value={eighths != null ? eighths : isOptional ? '' : -1}
        label={fuelLabel}
        onChange={(e) => {
          onChange(Number(e.target.value));
        }}
        className='fuel'>
        {eighths || isOptional ? null : (
          <StyledMenuItem value={-1}>{t('condition.capture.fuelTypes.noRecord')}</StyledMenuItem>
        )}

        <MenuItem value={'0'}>{t('condition.capture.fuelTypes.empty')}</MenuItem>
        <StyledMenuItem value={'0.125'}>{'1/8'}</StyledMenuItem>
        <StyledMenuItem value={'0.25'}>{'1/4'}</StyledMenuItem>
        <StyledMenuItem value={'0.375'}>{'3/8'}</StyledMenuItem>
        <StyledMenuItem value={'0.5'}>{'1/2'}</StyledMenuItem>
        <StyledMenuItem value={'0.625'}>{'5/8'}</StyledMenuItem>
        <StyledMenuItem value={'0.75'}>{'3/4'}</StyledMenuItem>
        <StyledMenuItem value={'0.875'}>{'7/8'}</StyledMenuItem>
        <StyledMenuItem value={'1'}>{t('condition.capture.fuelTypes.full')}</StyledMenuItem>
      </Select>
      <FormHelperText>{''}</FormHelperText>
    </FormControl>
  );
};

export default StandardFuelSelector;
