import useSWRImmutable from 'swr/immutable';
import { getConfig } from '../../utils/config';
import { edgeGetFetcher } from '../index';
import { useAppSelector } from '../../redux-tool-kit/hooks';
import { selectJwt } from '../../redux-tool-kit/userSlice';
import useAppParams from '../../hooks/useAppParams';

const fetcher = ([url, jwt]: [string, string | null]) => edgeGetFetcher(url, jwt, 'errors.vehicleRetrieveError');

export const useVehicleRetrieve = (vehicleUnitNumber: string | null) => {
  const jwt = useAppSelector(selectJwt);
  return useSWRImmutable([`${getConfig().retrieveVehicle}&unit=${vehicleUnitNumber?.toUpperCase()}`, jwt], fetcher);
};

export type FuelType = 'electric' | 'standard';

export const useFuelType = (): FuelType | null => {
  const { vid } = useAppParams();
  const { data, error, isLoading } = useVehicleRetrieve(vid);
  if (!isLoading && !error) {
    const fuelCategory = data?.fleetVehicle?.vehicleAsset.specification.fuelCategory;
    const fuelCategoryType = fuelCategory?.split(':')[6];
    if (fuelCategoryType === 'ELECTRIC') {
      return 'electric';
    } else {
      return 'standard';
    }
  } else {
    return null;
  }
};

export type MileageStandard = 'MILES' | 'KILOMETERS';

export const useMileageStandard = (): MileageStandard | null => {
  const { vid } = useAppParams();
  const { data, error, isLoading } = useVehicleRetrieve(vid);
  if (!isLoading && !error) {
    return data?.fleetVehicle?.vehicleAsset.odometer.unit;
  } else {
    return null;
  }
};
