import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { RootState } from './store';
import { conditionInitialState } from '../types/conditionTypes/Condition';

export const activeConditionSlice = createSlice({
  name: 'activeCondition',
  initialState: conditionInitialState,
  reducers: {
    setActiveCondition: (state, action: PayloadAction<object>) => {
      const newState = {
        ...state,
        ...action.payload,
      };
      return newState;
    },
  },
});

export const { setActiveCondition } = activeConditionSlice.actions;

export const activeConditionData = (state: RootState) => state.activeCondition;

export default activeConditionSlice.reducer;
