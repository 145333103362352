import {
  Button,
  Checkbox,
  Collapse,
  FormControl,
  FormControlLabel,
  FormGroup,
  Grid,
  InputLabel,
  MenuItem,
  Select,
  SelectChangeEvent,
  TextField,
  Typography,
} from '@mui/material';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { claimData, setClaim, setRenterInformation } from '../../../redux-tool-kit/claimSlice';
import { lossInfoData } from '../../../redux-tool-kit/lossInfoSlice';
import { useAppDispatch, useAppSelector } from '../../../redux-tool-kit/hooks';
import { store } from '../../../redux-tool-kit/store';
import { sendEmail } from '../../../services/email/EmailService';
import {
  getPrintablePDF,
  printDocument,
  PrintQueueItem,
  useAvailablePrinters,
} from '../../../services/print/PrinterServices';
import { ClaimPrintEmailDivider } from './ClaimPrintEmailDivider';
import useCountries from '../../../hooks/useCountries';
import { loadEhiLocationCookie } from '@ehi/location';
import useUnitLocation from '../../../hooks/useUnitLocation';
import useMultiRetrieve from '../../../hooks/useMultiRetrieve';

export interface Checkboxes {
  mobile: boolean;
  counter: boolean;
  email: boolean;
  none: boolean;
}

const checkboxesInitialState: Checkboxes = { mobile: false, counter: false, email: false, none: false };

interface ClaimPrintEmailProps {
  callback?: Function;
}

export const ClaimPrintEmail: React.FunctionComponent<ClaimPrintEmailProps> = ({
  callback,
}: ClaimPrintEmailProps): JSX.Element => {
  const { t } = useTranslation();
  const claim = useAppSelector(claimData);
  const dispatch = useAppDispatch();
  const { data: printerOptions = [] } = getAvailablePrinterResponse();

  const [checkboxes, setCheckboxes] = useState(checkboxesInitialState);
  const [selectedPrinterOption, setSelectedPrinterOption] = useState('');
  const lossData = useAppSelector(lossInfoData);
  const { data: countriesData } = useCountries();
  const { data: multiRetrieveData, isLoading: isMultiRetrieveLoading } = useMultiRetrieve(
    claim?.rentalContract?.rentalContractNumber
  );

  useEffect(() => {
    if (multiRetrieveData?.driver?.primaryDriver.emailAddress) {
      const renter = claim.firstPartyVehicleInformation?.renter?.partyInformation ?? {};
      dispatch(
        setRenterInformation({
          ...renter,
          email: {
            primaryEmail: multiRetrieveData.driver.primaryDriver.emailAddress,
          },
        })
      );
    }
  }, [isMultiRetrieveLoading]);

  const foundCountry = countriesData?.find((country) => country.countryIso3Code === lossData.lossCountry);

  const unitLocation = useUnitLocation();

  function getAvailablePrinterResponse() {
    const ehiLocationCookie = loadEhiLocationCookie();
    const user = store.getState().user;
    if (ehiLocationCookie?.groupBranch) {
      return useAvailablePrinters(ehiLocationCookie?.groupBranch);
    } else if (user?.groupBranch) {
      return useAvailablePrinters(user?.groupBranch);
    } else {
      return { data: [] };
    }
  }

  async function counterPrint() {
    const ehiLocationCookie = loadEhiLocationCookie();
    const user = store.getState().user;
    const groupBranch = ehiLocationCookie?.groupBranch || user?.groupBranch;
    if (claim.claimLevelInformation.claimId && groupBranch) {
      getPrintablePDF(claim.claimLevelInformation.claimId, unitLocation).then((res) => {
        if (selectedPrinterOption && res.data) {
          printDocument(selectedPrinterOption, res.data, groupBranch).finally(() => {
            callback && callback();
          });
        }
      });
    }
  }

  return (
    <>
      <Grid
        container
        item
        direction='row'
        data-testid='claim-printEmail'
        paddingLeft={2}
        rowSpacing={1}
        className='header'
        sx={{ pb: '8px' }}>
        <Grid item xs={12} className='header-title-container' data-testid='claim-printEmail-title'>
          <Typography variant='h6' fontWeight='600'>
            {t('claims.summary.sections.printEmail')}
          </Typography>
        </Grid>
        <Grid item xs={12} className='bold' data-testid='claim-printEmail-claimNumber'>
          <Typography>
            {t('claims.summary.labels.claimNumber')}
            {claim.claimLevelInformation.claimNumber}
          </Typography>
        </Grid>
      </Grid>
      <Grid container item direction='row' paddingLeft={2}>
        <Grid item xs={12} data-testid='claim-printEmail-email'>
          <FormGroup>
            <FormControlLabel
              control={
                <Checkbox
                  size='medium'
                  checked={checkboxes.email}
                  onChange={(): void => {
                    setCheckboxes((current) => ({ ...current, email: !current.email, none: false }));
                  }}
                />
              }
              label={t<string>('claims.summary.labels.email')}
            />
          </FormGroup>
        </Grid>
        <Collapse in={checkboxes.email} className='collapse'>
          <Grid container item xs={12} paddingBottom={2} paddingRight={2} data-testid='email-input'>
            <TextField
              fullWidth
              label={t('claims.summary.labels.email')}
              InputProps={{ 'aria-label': t('claims.summary.labels.email') }}
              variant='outlined'
              value={claim.firstPartyVehicleInformation.renter?.partyInformation?.email?.primaryEmail ?? ''}
              onChange={(e: React.ChangeEvent<HTMLInputElement>): void => {
                dispatch(
                  setClaim({
                    ...claim,
                    firstPartyVehicleInformation: {
                      ...claim.firstPartyVehicleInformation,
                      renter: {
                        ...claim.firstPartyVehicleInformation.renter,
                        partyInformation: {
                          ...claim.firstPartyVehicleInformation.renter?.partyInformation,
                          email: {
                            ...claim.firstPartyVehicleInformation.renter?.partyInformation?.email,
                            primaryEmail: e.target.value,
                          },
                        },
                      },
                    },
                  })
                );
              }}
            />
          </Grid>
        </Collapse>
      </Grid>
      <ClaimPrintEmailDivider className='divider' />
      <Grid container item direction='row' paddingLeft={2}>
        <Grid item xs={12} data-testid='claim-printEmail-counter'>
          <FormGroup>
            <FormControlLabel
              control={
                <Checkbox
                  checked={checkboxes.counter}
                  size='medium'
                  onChange={(): void => {
                    setCheckboxes((current) => ({ ...current, counter: !current.counter, none: false }));
                  }}
                />
              }
              label={t<string>('claims.summary.labels.counterPrint')}
            />
          </FormGroup>
        </Grid>
        <Collapse in={checkboxes.counter} className='collapse'>
          <Grid container item xs={12} paddingBottom={2} paddingRight={2} data-testid='counterPrinter-selection'>
            <FormControl variant='outlined' fullWidth>
              <InputLabel id='counterPrint-label'>{t('claims.summary.labels.printer')}</InputLabel>
              <Select
                labelId='counterPrint-label'
                label={t('claims.summary.labels.printer')}
                value={selectedPrinterOption}
                disabled={printerOptions.length === 0}
                onChange={(e: SelectChangeEvent): void => {
                  setSelectedPrinterOption(e.target.value);
                }}>
                {printerOptions.map((option: PrintQueueItem) => (
                  <MenuItem key={option.id} value={option.id}>
                    {option.printQueueDescription || option.id}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
            {printerOptions.length === 0 && (
              <Typography style={{ color: '#d32f2f', paddingTop: '2px' }}>{t('claims.noPrintersFound')}</Typography>
            )}
          </Grid>
        </Collapse>
      </Grid>
      <ClaimPrintEmailDivider className='divider' />
      <Grid container item direction='row' paddingLeft={2}>
        <Grid item xs={12} data-testid='claim-printEmail-none'>
          <FormGroup>
            <FormControlLabel
              control={
                <Checkbox
                  checked={checkboxes.none}
                  size='medium'
                  onChange={(): void => {
                    setCheckboxes((current) => ({ email: false, mobile: false, counter: false, none: !current.none }));
                  }}
                />
              }
              label={t<string>('none')}
            />
          </FormGroup>
        </Grid>
      </Grid>
      <Grid container item direction='column' alignItems='flex-end' padding={2} className='footer'>
        <Grid item xs={12} data-testid='claim-printEmail-ok'>
          <Button
            variant='text'
            disabled={
              !(
                checkboxes.mobile ||
                checkboxes.none ||
                (checkboxes.counter && selectedPrinterOption !== '') ||
                (checkboxes.email &&
                  (claim.firstPartyVehicleInformation.renter?.partyInformation?.email?.primaryEmail ?? '') !== '')
              )
            }
            onClick={async () => {
              const callbackMethod = callback ?? (() => {});
              if (checkboxes.mobile) {
                // DO SOMETHING WITH MOBILE PRINT
              }
              if (checkboxes.email) {
                const response = await sendEmail(
                  claim.firstPartyVehicleInformation.renter?.partyInformation?.email?.primaryEmail!,
                  foundCountry?.currency.code || '',
                  unitLocation
                );
                if (!response.success) {
                  console.error(response.error);
                }
                callbackMethod();
              }
              if (checkboxes.counter) {
                counterPrint();
              } else {
                callbackMethod();
              }
            }}
            data-testid='ok-button'>
            <Typography variant='button' fontWeight='600'>
              {t('done')}
            </Typography>
          </Button>
        </Grid>
      </Grid>
    </>
  );
};
