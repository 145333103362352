import { configureStore, isPlain } from '@reduxjs/toolkit';
import userReducer from './userSlice';

import claimReducer from './claimSlice';
import lossInfoReducer from './lossInfoSlice';
import conditionReducer from './conditionSlice';
import activeComponentReducer from './activeComponentSlice';
import activeConditionReducer from './activeConditionSlice';
import globalErrorModalReducer from './globalErrorModalSlice';
import snackBarReducer from './snackBarSlice';
import documentsReducer from './documentsSlice';
import networkErrorAlertReducer from './networkErrorAlertSlice';
import networkSuccessReducer from './networkSuccessSlice';
import completeOrPendClickedReducer from './completeOrPendClickedSlice';
import { isDate } from 'lodash';

// Dates are serializable as ISO 8601 timestamps
// https://github.com/reduxjs/redux-toolkit/issues/456 (Ignore the guy shilling his library)
const dateAllowedSerializibilityCheckOptions = {
  isSerializable: (value: any) => isPlain(value) || isDate(value),
};

export const store = configureStore({
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({ serializableCheck: dateAllowedSerializibilityCheckOptions }),
  reducer: {
    user: userReducer,

    claim: claimReducer,
    lossInfo: lossInfoReducer,
    condition: conditionReducer,
    activeComponent: activeComponentReducer,
    activeCondition: activeConditionReducer,
    globalErrorModal: globalErrorModalReducer,
    snackBar: snackBarReducer,
    networkErrorAlert: networkErrorAlertReducer,
    networkSuccess: networkSuccessReducer,
    documents: documentsReducer,
    completeOrPendClicked: completeOrPendClickedReducer,
  },
});

// Infer the `RootState` and `AppDispatch` types from the store itself
export type RootState = ReturnType<typeof store.getState>;
// Inferred type: {posts: PostsState, comments: CommentsState, users: UsersState}
export type AppDispatch = typeof store.dispatch;
