import React, { Component, ErrorInfo } from 'react';
import { WithTranslation, withTranslation } from 'react-i18next';
import { ErrorMessage } from './ErrorMessage';

interface State {
  hasError: boolean;
}

class ErrorBoundary extends Component<WithTranslation & { children?: React.ReactNode }> {
  public state: State = {
    hasError: false,
  };

  componentDidCatch(error: Error, errorInfo: ErrorInfo): void {
    this.setState({ hasError: true });
    console.error('An error has occurred: ', error, errorInfo);
  }

  render() {
    return this.state.hasError ? <ErrorMessage></ErrorMessage> : this.props.children;
  }
}

export default withTranslation()(ErrorBoundary);
