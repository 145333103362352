import { mostSignificant } from './urn';

export const cancelConditionCapture = () => {
  if (window === window.top) {
    window.location.assign('/');
  } else {
    window.parent.postMessage({ action: 'Exit', success: true }, '*');
  }
};

export const completeConditionCapture = (
  conditionReportIdUrn: string,
  claimNumber: number | undefined,
  fuelPercent: number | null | undefined,
  mileage: number | null | undefined,
  askToShop: boolean
) => {
  const conditionReportId = mostSignificant(conditionReportIdUrn);
  if (window === window.top) {
    window.location.assign('/');
  } else {
    window.parent.postMessage(
      {
        action: 'Complete',
        success: true,
        fuelPercent,
        mileage,
        claimNumber,
        conditionReportId,
        askToShop,
      },
      '*'
    );
  }
};

export const missingConnectedCarParameters = () => {
  if (window === window.top) {
    window.location.assign('/');
  } else {
    window.parent.postMessage({ action: 'Failure', success: false, reason: 'Missing Parameters' });
  }
};

export const failConditionCapture = () => {
  if (window === window.top) {
    window.location.assign('/');
  } else {
    window.parent.postMessage({ action: 'Exit', success: false, failureMessage: 'Fatal Error' }, '*');
  }
};

export const passAPICall = () => {
  window.parent.postMessage({ action: 'Ready', success: true }, '*');
};
