import { useMsal } from '@azure/msal-react';
import { useLogout } from '@ehi/auth';
import useLogoutParams from '../hooks/useLogoutParams';

export type ClearCache = {
  clearCache: () => void;
  clearCacheAndLogout: () => void;
};
export const useClearCache = (): ClearCache => {
  const { accounts } = useMsal();
  const loggedInUser = accounts.length > 0 ? accounts[0] : undefined;
  const logoutParams = useLogoutParams();
  const logout = useLogout(logoutParams);

  const clearLocalStorage = () => {
    Object.keys(localStorage).forEach((key) => {
      if ((loggedInUser?.homeAccountId && !key.includes(loggedInUser?.homeAccountId)) || !loggedInUser?.homeAccountId) {
        localStorage.removeItem(key);
      }
    });
  };

  const clearCache = () => {
    clearLocalStorage();
  };

  const clearCacheAndLogout = () => {
    clearCache();
    logout();
  };

  return { clearCache, clearCacheAndLogout };
};
