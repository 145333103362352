import React, { useState, useEffect } from 'react';
import { Grid, TextField } from '@mui/material';
import { useTranslation } from 'react-i18next';

import { useAppDispatch, useAppSelector } from '../../redux-tool-kit/hooks';
import { ConditionPaper, ConditionCardHeader } from '../../styles/common';
import {
  selectFuelPercentage,
  selectOdometer,
  setFuelPercentage,
  setOdometerUnit,
  setOdometerValue,
} from '../../redux-tool-kit/conditionSlice';
import { useFuelType, useVehicleRetrieve } from '../../services/vehicleService/useVehicleRetrieve';
import StandardFuelSelector from './fuel/StandardFuelSelector';
import ElectricFuelSelector from './fuel/ElectricFuelSelector';
import { Skeleton } from '@mui/material';
import { SkeletonBox } from './VehicleConditionSkeleton';
import { useSearchParams } from '../../utils/useURLs';
import { missingConnectedCarParameters } from '../../utils/navigation';
import useAppParams from '../../hooks/useAppParams';
import useVehicle from '../../hooks/useVehicle';

interface VehicleInfoProps {
  setSoftErrors: (softErrors: string[]) => void;
  setIsValid: (valid: boolean) => void;
}

interface Validation {
  isSet: boolean;
  error?: string;
}

const IS_NUMERIC = /^\d*$/;

const VehicleInfo = ({ setSoftErrors, setIsValid }: VehicleInfoProps) => {
  const { t } = useTranslation();
  const { vid } = useAppParams();
  const { data: vehicleRetrieveData, isLoading: isVehicleRetrieveLoading } = useVehicleRetrieve(vid);
  const { data: vehicle, isLoading: isVehicleLoading } = useVehicle();

  const searchObject = useSearchParams();
  const currentFuelPercent = searchObject.get('currentFuelPercent');
  const currentOdometer = searchObject.get('currentOdometer');
  const currentOdometerType = searchObject.get('currentOdometerType')?.toUpperCase();
  const currentFuelType = searchObject.get('currentFuelType');
  const currentFuelValue = searchObject.get('currentFuelValue');
  const showBatteryLevel = searchObject.get('showBatteryLevel') === 'true';

  const isConnectedCar = searchObject.get('useConnectedCar') === 'true';
  const isMileageRequired = searchObject.get('requireMileage') === 'true';
  const isFuelRequired = searchObject.get('requireFuel') === 'true';
  const odometer = useAppSelector(selectOdometer);

  const [odometerValidation, setOdometerValidation] = useState<Validation>({ isSet: false });
  const [fuelValidation, setFuelValidation] = useState<Validation>({ isSet: false });

  const dispatch = useAppDispatch();

  useEffect(() => {
    if (isConnectedCar && !(currentFuelPercent && currentOdometer)) {
      missingConnectedCarParameters();
    }
  }, []);

  useEffect(() => {
    if (isFuelRequired) {
      dispatch(setFuelPercentage(null));
    } else if (currentFuelPercent) {
      dispatch(setFuelPercentage(parseFloat(currentFuelPercent)));
      setFuelValidation({ isSet: true });
    } else if (!isVehicleLoading) {
      dispatch(setFuelPercentage(null));
      setFuelValidation({ isSet: true });
    }

    if (currentOdometerType) {
      dispatch(setOdometerUnit(currentOdometerType));
    } else if (vehicle?.vehicleAsset.odometer?.unit && !isVehicleLoading) {
      dispatch(setOdometerUnit(vehicle.vehicleAsset.odometer.unit));
    } else if (vehicleRetrieveData && !isVehicleRetrieveLoading) {
      dispatch(setOdometerUnit(vehicleRetrieveData.fleetVehicle.vehicleAsset.odometer.unit));
    } else {
      dispatch(setOdometerUnit('MILE'));
    }

    if (isMileageRequired) {
      dispatch(setOdometerValue(null));
    } else if (currentOdometer) {
      dispatch(setOdometerValue(Number(currentOdometer)));
      setOdometerValidation({ isSet: true });
    } else if (!isVehicleLoading && vehicle?.vehicleAsset.odometer) {
      dispatch(setOdometerValue(vehicle.vehicleAsset.odometer.lastKnownValue));
      setOdometerValidation({ isSet: true });
    }
  }, [isVehicleLoading, isVehicleRetrieveLoading]);

  useEffect(() => {
    setIsValid(fuelValidation.isSet && !fuelValidation.error && odometerValidation.isSet && !odometerValidation.error);
  }, [fuelValidation, odometerValidation]);

  const shouldDisplayFuelValue = Boolean(currentFuelType && currentFuelValue);
  const fuelPercentage = useAppSelector(selectFuelPercentage);

  const handleOdometerChange = (e: React.ChangeEvent<HTMLInputElement>): void => {
    const odometerString = e.target.value;
    if (odometerString === '' || Number(odometerString) <= 0) {
      setOdometerValidation({ isSet: true, error: 'errors.errorEmptyOdometer' });
      dispatch(setOdometerValue(null));
    } else if (!IS_NUMERIC.test(odometerString)) {
      setOdometerValidation({ isSet: true, error: 'errors.errorOnlyNumbers' });
      dispatch(setOdometerValue(null));
    } else if (odometerString.length > 6) {
      setOdometerValidation({ isSet: true, error: 'errors.errorLengthSix' });
      dispatch(setOdometerValue(null));
    } else {
      const newOdometerValue = Number(e.target.value);
      setOdometerValidation({ isSet: true });
      dispatch(setOdometerValue(newOdometerValue));

      const previousOdometerValue = vehicle?.vehicleAsset.odometer?.lastKnownValue ?? 0;
      const softErrors = [];
      if (newOdometerValue < previousOdometerValue) {
        softErrors.push(t('softErrors.mileage', { mileage: newOdometerValue, previousMileage: previousOdometerValue }));
      }
      if (newOdometerValue > previousOdometerValue + 1500) {
        softErrors.push(t('softErrors.mileage', { mileage: newOdometerValue, previousMileage: previousOdometerValue }));
      }
      setSoftErrors(softErrors);
    }
  };

  const handleFuelChange = (newFuel: number) => {
    dispatch(setFuelPercentage(newFuel));
    setFuelValidation({ isSet: true });
  };

  const fuelType = useFuelType();
  if (!fuelType) {
    return (
      <SkeletonBox>
        <Skeleton width={'97.5%'} height={180} />
      </SkeletonBox>
    );
  }
  return (
    <ConditionPaper elevation={15}>
      <Grid spacing={3.5} container>
        <Grid item xs={12} data-testid='odometer-fuel-header'>
          <ConditionCardHeader>{t('condition.capture.enterOdAndFuel')}</ConditionCardHeader>
        </Grid>
        <Grid item xs={shouldDisplayFuelValue ? 4 : 6}>
          <TextField
            data-testid='odometer-input'
            error={!!odometerValidation.error}
            helperText={odometerValidation.error ? t(odometerValidation.error) : null}
            label={
              odometer.value == null
                ? t('condition.capture.mileageLabel.enter')
                : t('condition.capture.mileageLabel.entered')
            }
            fullWidth
            InputProps={{
              'aria-label': t('condition.capture.mileage'),
              inputMode: 'numeric',
            }}
            type='number'
            variant='outlined'
            disabled={isConnectedCar}
            onChange={handleOdometerChange}
            defaultValue={odometer.value}
          />
        </Grid>
        <Grid item xs={shouldDisplayFuelValue ? 4 : 6}>
          {(fuelType === 'standard' || !showBatteryLevel) && (
            <StandardFuelSelector
              disabled={isConnectedCar}
              value={fuelPercentage}
              onChange={handleFuelChange}
              isOptional={!isFuelRequired}
            />
          )}
          {fuelType === 'electric' && showBatteryLevel && (
            <ElectricFuelSelector value={fuelPercentage} onChange={handleFuelChange} isOptional={!isFuelRequired} />
          )}
        </Grid>
        {shouldDisplayFuelValue && (
          <Grid item xs={4}>
            <TextField
              label={t('condition.capture.currentFuel')}
              fullWidth
              InputProps={{
                'aria-label': t('condition.capture.currentFuel'),
              }}
              variant='outlined'
              disabled={isConnectedCar}
              value={`${currentFuelValue} ${currentFuelType}`}
            />
          </Grid>
        )}
      </Grid>
    </ConditionPaper>
  );
};

export default VehicleInfo;
