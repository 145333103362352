import axios from 'axios';
import useSWRImmutable from 'swr/immutable';
import { addSuccessCall } from '../../redux-tool-kit/networkSuccessSlice';
import { store } from '../../redux-tool-kit/store';
import { getConfig } from '../../utils/config';
import { failConditionCapture } from '../../utils/navigation';

export interface Config {
  readonly audience: string;
  readonly clientId: string;
  readonly locationKey?: string;
  readonly localyticsApiKey: string;
  readonly defaultServiceEndpoints: string;
  readonly webEnvironment: string;
}

const fetcher = (url: string) =>
  axios
    .get(url)
    .then((response) => {
      store.dispatch(addSuccessCall('configService'));
      return response.data;
    })
    .catch(() => failConditionCapture());

export const useConfig = () => {
  const config = useSWRImmutable<Config>(getConfig().configPath, fetcher);
  if (config.error !== undefined) {
    console.info(`Error reading config file ${getConfig().configPath}`);
    throw new Error(`Error reading config file ${getConfig().configPath}`);
  }
  return config;
};
