import { Dialog, Grid, Paper, Typography } from '@mui/material';
import React, { useState, useRef, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { throttle } from 'lodash';
import { claimData as claimState, selectInsuranceInfo, setClaim } from '../../../redux-tool-kit/claimSlice';
import { selectFuelPercentage, selectNewConditions, selectOdometer } from '../../../redux-tool-kit/conditionSlice';
import { useAppDispatch, useAppSelector } from '../../../redux-tool-kit/hooks';
import { lossInfoData } from '../../../redux-tool-kit/lossInfoSlice';
import { DateFormatMappings, SupportedDateFormats } from '../../../shared/DatePicker/SupportedDateFormats';
import { StyledClaimPrintEmail, StyledClaimSummary } from '../../../styles/screens';
import {
  dxIncidentTypes,
  getOxIncidentTypes,
  getQuestionIntegerAnswer,
  getQuestionYesNoAnswer,
} from '../../../utils/claims/ClaimHelpers';
import { SummarySignature } from './SummarySignature';
import { Option, SubmissionState } from '../../../types';
import * as ClaimService from '../../../services/claims/ClaimService';
import { ClaimPrintEmail } from './ClaimPrintEmail';
import { ClaimWorkflowStageTypeId, QuestionType } from '../../../types/enums';
import { useGetConditionTypeName, useGetPayloadReadyConditionReport } from '../../../utils/condition/ConditionHelper';
import { useAppNavigation } from '../../../utils/useURLs';
import { NavButtons } from '../shared/NavButtons';
import { NewCondition } from '../../../types/conditionTypes/Condition';
import { selectDocuments } from '../../../redux-tool-kit/documentsSlice';
import { persistDocument } from '../../../services/claims/ClaimService';
import { completeConditionCapture } from '../../../utils/navigation';
import { toggleCompleteTrue, togglePendTrue } from '../../../redux-tool-kit/completeOrPendClickedSlice';
import { store } from '../../../redux-tool-kit/store';
import useVehicle from '../../../hooks/useVehicle';
import useAppParams from '../../../hooks/useAppParams';
import { useComponentMap } from '../../../hooks/useComponent';
import useIncidentType from '../../../hooks/useIncidentType';
import useClaimType from '../../../hooks/useClaimType';
import { mostSignificant } from '../../../utils/urn';
import { submitConditionReport } from '../../../services/condition/SubmitConditionService';
import { Employee } from '../../../types/claim/person/Employee';
import useUnitLocation from '../../../hooks/useUnitLocation';
import { showNetworkErrorSnackbar } from '../../../utils/snackBar/snackBarAlert';

interface ClaimSummaryProps {
  employeeData?: Employee | {};
}

export const ClaimSummary: React.FunctionComponent<ClaimSummaryProps> = ({ employeeData }: ClaimSummaryProps) => {
  const { t, i18n } = useTranslation();
  const claimData = useAppSelector(claimState);
  const lossInfo = useAppSelector(lossInfoData);
  const newConditions = useAppSelector(selectNewConditions);
  const componentMap = useComponentMap();
  const dispatch = useAppDispatch();
  const navigation = useAppNavigation();
  const getConditionTypeName = useGetConditionTypeName();
  const documents = useAppSelector(selectDocuments);
  const getPayloadReadyConditionReport = useGetPayloadReadyConditionReport();
  const { data: vehicle } = useVehicle();
  const { data: incidentData } = useIncidentType();
  const { data: claimTypeData } = useClaimType();
  const { vid } = useAppParams();

  const componentRef = useRef(null);

  const [isSignatureComplete, setIsSignatureComplete] = useState(false);
  const [showPrintOptionsModal, setShowPrintOptionsModal] = useState(false);
  const [signature, setSignature] = useState('');
  const [conditionReportId, setConditionReportId] = useState('');
  const [claimResponseInProgress, setClaimResponseInProgress] = useState<SubmissionState>('none');
  const setSignatureThrottled = throttle(setSignature, 100);
  const fuel = useAppSelector(selectFuelPercentage);
  const mileage = useAppSelector(selectOdometer).value;
  const insuranceInfo = useAppSelector(selectInsuranceInfo);
  const unitLocation = useUnitLocation();

  useEffect(() => window.scrollTo(0, 0), []);

  const submitOrPendClaim = async (pend: boolean) => {
    setClaimResponseInProgress(pend ? 'pend' : 'claim');

    const condRprtId = await submitConditionReport(getPayloadReadyConditionReport(), vid);
    setConditionReportId(condRprtId);

    const claim = pend ? createPendClaim(condRprtId) : createSubmitClaim(condRprtId);

    const createResult = await ClaimService.createClaim(
      claim,
      lossInfo,
      vehicle!,
      vid,
      employeeData || {},
      unitLocation
    );

    if (createResult.success) {
      dispatch(
        setClaim({
          ...claim,
          claimLevelInformation: {
            ...claim.claimLevelInformation,
            claimNumber: createResult.claim.claimNumber,
            claimId: createResult.claim.claimId,
          },
        })
      );
      const documentsToPersist = [...documents];
      if (signature) {
        documentsToPersist.push({ name: 'signature.png', bytes: signature.split(',')[1] });
      }
      await persistDocument(createResult.claim.claimId, documentsToPersist, vehicle!.operatingCountry);

      setShowPrintOptionsModal(true);
    } else {
      console.error(createResult.error);
      showNetworkErrorSnackbar(t('claims.errorCreatingClaim'));
      setClaimResponseInProgress('none');
      setShowPrintOptionsModal(false);
    }
  };

  const createSubmitClaim = (conditionReportIdUrn: string) => {
    const today = new Date();
    return {
      ...claimData,
      claimLevelInformation: {
        ...claimData.claimLevelInformation,
        accidentReportedDate: today,
        accidentReportCompletedDate: today,
      },
      firstPartyVehicleInformation: {
        ...claimData.firstPartyVehicleInformation,
        vehicle: {
          ...claimData.firstPartyVehicleInformation.vehicle,
          vehicleConditionIds: [{ vehicleConditionId: Number(mostSignificant(conditionReportIdUrn)) }],
        },
      },
    };
  };

  const createPendClaim = (conditionReportIdUrn: string) => {
    const today = new Date();
    return {
      ...claimData,
      claimLevelInformation: {
        ...claimData.claimLevelInformation,
        accidentReportedDate: today,
        claimWorkFlowStageTypeId: ClaimWorkflowStageTypeId.PENDED,
      },
      firstPartyVehicleInformation: {
        ...claimData.firstPartyVehicleInformation,
        vehicle: {
          ...claimData.firstPartyVehicleInformation.vehicle,
          vehicleConditionIds: [{ vehicleConditionId: Number(mostSignificant(conditionReportIdUrn)) }],
        },
      },
    };
  };

  const handleCompleteClick = () => {
    store.dispatch(toggleCompleteTrue());
    submitOrPendClaim(false);
  };

  const handlePendClick = () => {
    store.dispatch(togglePendTrue());
    submitOrPendClaim(true);
  };

  const getIncidentType = (
    claimType: number | null,
    onRent: boolean | undefined,
    incidentType: number | null
  ): string | undefined => {
    if (claimType === null || onRent === undefined || incidentType === null) {
      return '';
    }
    if (incidentData) {
      const incidentTypes = claimData.onRent
        ? dxIncidentTypes(incidentData)
        : getOxIncidentTypes(claimData.claimLevelInformation.claimTypeId, incidentData);

      const matchingType = incidentTypes.find((type: Option) => type.value === incidentType);

      return matchingType ? t(matchingType.key) : '';
    }
  };

  return (
    <StyledClaimSummary ref={componentRef}>
      <Paper square className='paper-section'>
        <Typography className='header-text' variant='h5'>
          {t('claims.vehicleDetails')}
        </Typography>
        <Typography className='subheader-text' fontWeight='600' data-testid='vehicle-name'>
          {`${vehicle?.vehicleAsset.specification.year ?? ''} 
            ${vehicle?.vehicleAsset.specification.make.code ?? ''} 
            ${vehicle?.vehicleAsset.specification.model.code ?? ''} -  
            ${vehicle?.vehicleAsset.specification.seriesCode ?? ''}`}
        </Typography>
        <Grid container rowSpacing={1} columnSpacing={1}>
          <SummaryItem
            label={t('claims.summary.labels.color')}
            data={
              vehicle?.vehicleAsset.color.exteriorBaseColor &&
              vehicle?.vehicleAsset.color.exteriorBaseColor.charAt(0).toUpperCase() +
                vehicle?.vehicleAsset.color.exteriorBaseColor.slice(1).toLowerCase()
            }
            testId='vehicle-color'
          />
          <SummaryItem
            label={t('claims.summary.labels.vin')}
            data={vehicle?.vehicleAsset.vin.slice(-8)}
            testId='vehicle-vin'
          />
          <SummaryItem label={t('claims.summary.labels.unit')} data={vid} testId='vehicle-unit-number' />
          <SummaryItem
            label={t('claims.summary.labels.licensePlate')}
            data={vehicle?.vehicleAsset.registrationPlate.number}
            testId='vehicle-licensePlate'
          />
          <SummaryItem
            label={t('claims.summary.labels.state')}
            data={vehicle?.vehicleAsset.registrationPlate.jurisdiction.countrySubdivision}
            testId='vehicle-state'
          />
          <SummaryItem
            label={t('claims.summary.labels.odometer')}
            data={(mileage ?? 1).toLocaleString()}
            testId='vehicle-odometer'
          />
        </Grid>
      </Paper>
      <Paper square className='paper-section'>
        <Typography className='header-text' variant='h5'>
          {t('claim')}
        </Typography>
        {claimTypeData && (
          <Typography className='subheader-text' fontWeight='600' data-testid='claimType'>
            {claimTypeData.find(({ id }) => id === claimData.claimLevelInformation.claimTypeId)?.description}
          </Typography>
        )}

        <Grid container rowSpacing={1} columnSpacing={1}>
          <SummaryItem
            label={t('claims.labels.incidentType')}
            data={getIncidentType(
              claimData.claimLevelInformation.claimTypeId,
              claimData.onRent,
              claimData.claimLevelInformation.incidentTypeId
            )}
            testId='incidentType'
          />
          <SummaryItem
            label={t('claims.driver')}
            data={
              (claimData.firstPartyVehicleInformation.driver?.personalInformation?.firstName ||
                claimData.firstPartyVehicleInformation.driver?.personalInformation?.lastName) &&
              `${
                claimData.firstPartyVehicleInformation.driver?.personalInformation?.firstName
                  ? claimData.firstPartyVehicleInformation.driver?.personalInformation?.firstName
                  : ''
              } 
            ${
              claimData.firstPartyVehicleInformation.driver?.personalInformation?.lastName
                ? claimData.firstPartyVehicleInformation.driver?.personalInformation?.lastName
                : ''
            }`
            }
          />
          <SummaryItem
            label={t('claims.rentalVehiclePassengers')}
            data={getQuestionIntegerAnswer(
              claimData.questionsInformation,
              QuestionType.PASSENGERS_IN_OUR_VEHICLE
            )?.toLocaleString()}
          />
          <SummaryItem label={t('claims.firstInsuranceLabel')} data={insuranceInfo?.companyName} />
          <SummaryItem label={t('claims.claimPolicyNumberSign')} data={insuranceInfo?.policyNumber} />
          <SummaryItem
            label={t('claims.otherVehiclePassengers')}
            data={getQuestionIntegerAnswer(
              claimData.questionsInformation,
              QuestionType.PASSENGERS_IN_OTHER_VEHICLE
            )?.toLocaleString()}
          />
          <SummaryItem
            label={t('claims.thirdInsuranceLabel')}
            data={claimData.thirdPartyVehiclesInformation?.[0]?.insurance?.companyName}
          />
          <SummaryItem
            label={t('claims.claimPolicyNumberSign')}
            data={claimData.thirdPartyVehiclesInformation?.[0]?.insurance?.policyNumber}
          />
          <Grid item xs={4}>
            <Typography variant='caption'>{t('claims.summary.labels.otherParties')}</Typography>
            <Typography data-testid={'otherParties'}>
              {t(getQuestionYesNoAnswer(claimData.questionsInformation, QuestionType.OTHER_VEHICLE_INVOLVED))}
            </Typography>
          </Grid>
          <SummaryItem
            label={t('claims.policeReportNumberSign')}
            data={claimData.policeDepartments?.[0]?.reportNumber}
          />

          <SummaryItem label={t('claims.policeStation')} data={claimData.policeDepartments?.[0]?.departmentName} />
          <SummaryItem
            label={t('claims.summary.labels.injuries')}
            data={t(getQuestionYesNoAnswer(claimData.questionsInformation, QuestionType.INJURIES))}
            testId='injuries'
          />
        </Grid>
      </Paper>
      <Paper square className='paper-section'>
        <Typography className='header-text' variant='h5'>
          {t('claims.summary.sections.details')}
        </Typography>
        <Grid container rowSpacing={1} columnSpacing={1}>
          <SummaryItem
            label={t('claims.summary.labels.timeOfLoss')}
            data={
              lossInfo.timeOfLoss &&
              new Date(lossInfo.timeOfLoss).toLocaleString(
                SupportedDateFormats[i18n.language.replace('-', '') as keyof DateFormatMappings],
                {
                  hour12: false,
                  dateStyle: 'medium',
                  timeStyle: 'short',
                }
              )
            }
            testId={'details-date-of-loss'}
          />
          <SummaryItem
            label={t('claims.summary.labels.lossLocation')}
            data={
              lossInfo.lossState?.name && lossInfo.lossCountry && `${lossInfo.lossState.name}, ${lossInfo.lossCountry}`
            }
            testId={'details-lossLocation'}
          />
          <SummaryItem
            label={t('claims.summary.labels.factsOfLoss')}
            data={lossInfo.factsOfLoss}
            testId={'details-factsOfLoss'}
            sizeOverride={12}
          />
        </Grid>
      </Paper>
      <Paper square className='paper-section'>
        <Typography className='header-text' variant='h5'>
          {t('claims.summary.sections.damages')}
        </Typography>
        <Typography variant='caption'>{t('location')}</Typography>
        <Grid container item rowSpacing={1} columnSpacing={1}>
          {newConditions.length ? (
            <>
              {Object.values(newConditions).map((condition: NewCondition, index: number) =>
                condition.isClaimable ? (
                  <Grid item xs={12} key={index} data-testid='damages'>
                    <Typography>
                      {componentMap[condition.component]}, {getConditionTypeName(condition.conditionType)}
                    </Typography>
                  </Grid>
                ) : null
              )}
            </>
          ) : (
            <Grid container item xs={12}>
              <span>{t('none')}</span>
            </Grid>
          )}
        </Grid>
      </Paper>
      <Paper square className='paper-section'>
        <Typography className='header-text' variant='h5'>
          {t('claims.summary.sections.signature')}
        </Typography>
        <SummarySignature setComplete={setIsSignatureComplete} onChange={setSignatureThrottled} />
      </Paper>
      <Paper className='action-buttons'>
        <NavButtons
          activeStep='summary'
          previousButtonClick={() => {
            navigation.push('/claim/loss-information');
          }}
          responseInProgress={claimResponseInProgress}
          allRequiredFieldsFilled={isSignatureComplete}
          completeButtonClick={() => handleCompleteClick()}
          pendClaimButtonClick={() => handlePendClick()}
        />
      </Paper>
      <Dialog
        open={showPrintOptionsModal}
        onClose={(event, reason) => {
          if (reason === 'backdropClick') {
            return;
          } else {
            setShowPrintOptionsModal(false);
          }
        }}>
        <StyledClaimPrintEmail>
          <ClaimPrintEmail
            callback={() => {
              setShowPrintOptionsModal(false);
              completeConditionCapture(
                conditionReportId,
                claimData.claimLevelInformation.claimNumber,
                fuel,
                mileage,
                true
              );
            }}
          />
        </StyledClaimPrintEmail>
      </Dialog>
    </StyledClaimSummary>
  );
};

interface SummaryItemProps {
  label: string;
  data: string | undefined | null;
  testId?: string;
  sizeOverride?: number;
}

const SummaryItem = ({ label, data, testId, sizeOverride }: SummaryItemProps) => {
  if (data) {
    return (
      <Grid item xs={sizeOverride || 4}>
        <Typography variant='caption'>{label}</Typography>
        {testId ? <Typography data-testid={testId}>{data}</Typography> : <Typography>{data}</Typography>}
      </Grid>
    );
  } else {
    return null;
  }
};
