import axios from 'axios';
import { v4 as generateUUID } from 'uuid';
import { LossInformationState } from '../../redux-tool-kit/lossInfoSlice';
import { ClaimPayload, UserState, VehicleSliceData } from '../../types';
import { store } from '../../redux-tool-kit/store';
import type { Document } from '../../redux-tool-kit/documentsSlice';
import { getConfig } from '../../utils/config';
import { showNetworkErrorSnackbar } from '../../utils/snackBar/snackBarAlert';
import { setClaim } from '../../redux-tool-kit/claimSlice';
import { Employee } from '../../types/claim/person/Employee';
import { localTimeIsoTransformer, walk } from '../../utils/transformers';
import { ClaimWorkflowStageTypeId } from '../../types/claim/claimInformation/enums/ClaimWorkflowStageType';

export interface CreateClaimResponse {
  errors: string[];
  warns: string[];
  claimNumber: string;
  claimId: number;
}

export interface ClaimResponse {
  success: boolean;
  claim: CreateClaimResponse;
  error?: any;
}

const addLossInformation = (claim: ClaimPayload, lossInfo: LossInformationState): ClaimPayload => {
  return {
    ...claim,
    claimLevelInformation: {
      ...claim.claimLevelInformation,
      factsOfLoss: lossInfo.factsOfLoss,
      lossDate: lossInfo.timeOfLoss,
      lossCountryIsoCode: lossInfo.lossCountry,
      lossCity: lossInfo.lossCity,
      lossLocation: lossInfo.lossLocation,
      lossState: lossInfo.lossState?.abbreviation ? lossInfo.lossState.abbreviation : lossInfo.lossState?.name || '',
    },
  };
};

const addReportedByUserInformation = (claim: ClaimPayload, user: UserState): ClaimPayload => {
  return {
    ...claim,
    claimLevelInformation: {
      ...claim.claimLevelInformation,
      accidentReportedByUserFirstName: user.firstName!,
      accidentReportedByUserLastName: user.lastName!,
      accidentReportedByUserId: user.eid!,
      accidentReportedApplication: 'Condition Capture',
      accidentReportedByGroupBranchLegacyNumber: user.groupBranch!,
    },
  } as ClaimPayload;
};

const addCompletedByUserInformation = (claim: ClaimPayload, user: UserState): ClaimPayload => {
  return {
    ...claim,
    claimLevelInformation: {
      ...claim.claimLevelInformation,
      accidentReportCompletedApplication: 'Condition Capture',
      accidentReportCompletedByGroupBranchLegacyNumber: user.groupBranch,
      accidentReportCompletedByUserFirstName: user.firstName,
      accidentReportCompletedByUserId: user.eid,
      accidentReportCompletedByUserLastName: user.lastName,
    },
  } as ClaimPayload;
};

const addFirstPartyVehicleInformation = (
  claim: ClaimPayload,
  vehicle: VehicleSliceData,
  vid: string,
  empData: Employee
): ClaimPayload => {
  const vehicleData = vehicle;
  const baselineFirstPartyData = {
    ...claim,
    firstPartyVehicleInformation: {
      ...claim.firstPartyVehicleInformation,
      vehicle: {
        ...claim.firstPartyVehicleInformation.vehicle,
        companyVehicleIdentifier: vid,
        currentLocation: vehicleData?.physicalLocation.legacyLocation,
        vin: vehicleData?.vehicleAsset.vin,
        licensePlateNumber: vehicleData?.vehicleAsset.registrationPlate.number,
        licensePlateCountry: vehicleData?.vehicleAsset.registrationPlate.jurisdiction.country,
        licensePlateState: vehicleData?.vehicleAsset.registrationPlate.jurisdiction.countrySubdivision,
        licensePlateDate: vehicleData?.vehicleAsset.registrationPlate.expirationDate,
        color: vehicleData?.vehicleAsset.color.exteriorBaseColor,
        year: Number(vehicleData?.vehicleAsset.specification.year),
        make: vehicleData?.vehicleAsset.specification.make.code,
        model: vehicleData?.vehicleAsset.specification.model.code,
        series: vehicleData?.vehicleAsset.specification.seriesCode,
        universalCarClassCode: vehicleData?.vehicleAsset.specification.sippCode,
      },
    },
  };
  return !claim.onRent && empData.employeeID
    ? ({
        ...baselineFirstPartyData,
        firstPartyVehicleInformation: {
          ...baselineFirstPartyData.firstPartyVehicleInformation,
          driver: {
            ...baselineFirstPartyData.firstPartyVehicleInformation.driver,
            employeeInformation: {
              employeeBranch: empData ? `${empData.group}${empData.branch}` : '',
              employeeGroup: empData ? `${empData.group}${empData.branch}` : '',
              employeeNumber: empData ? empData.employeeID : '',
              employeeStartDate: empData ? empData.hireDate : '',
            },
            employeeNumber: empData ? empData.employeeID : '',
          },
        },
      } as ClaimPayload)
    : baselineFirstPartyData;
};

const addOxData = (claimData: ClaimPayload | undefined, emp?: Employee | undefined): ClaimPayload => {
  const claim = claimData ? claimData : store.getState().claim;
  const owningGroupBranch =
    emp && emp.group ? `${emp.group}${emp.branch}` : claim.claimLevelInformation.branchLegacyIorgId;
  const rentalContract = !!owningGroupBranch ? { damageWaiverType: 1, owningGroupBranch } : { damageWaiverType: 1 };
  return claim.onRent ? claim : ({ ...claim, rentalContract, firstPartyMasked: true } as ClaimPayload);
};

export const createClaim = async (
  claim: ClaimPayload,
  lossInfo: LossInformationState,
  vehicle: VehicleSliceData,
  vid: string,
  emp: Employee | {},
  unitLocation: string | null
): Promise<ClaimResponse> => {
  store.dispatch(setClaim(claim));
  const user = store.getState().user;
  const isPend = claim.claimLevelInformation.claimWorkFlowStageTypeId == ClaimWorkflowStageTypeId.PENDED;
  let payload: ClaimPayload = addLossInformation(claim, lossInfo);
  payload = addReportedByUserInformation(payload, user);
  if (!isPend) {
    payload = addCompletedByUserInformation(payload, user);
  }
  payload = addFirstPartyVehicleInformation(payload, vehicle, vid, emp);
  payload = addOxData(payload, emp);

  try {
    const response = await axios.post(`${getConfig().superedge}${getConfig().createClaimTarget}`, payload, {
      transformRequest: (data) => {
        return JSON.stringify(walk(data, [[Date, localTimeIsoTransformer]]));
      },
      headers: {
        'Content-Type': 'application/json',
        Accept: 'application/json',
        Authorization: `Bearer ${user.jwt}`,
        'Ehi-request-id': `${user.eid}_${generateUUID()}`,
        'condition-unit-location': unitLocation,
      },
    });
    return { success: true, claim: response.data };
  } catch (error) {
    return { success: false, claim: {} as CreateClaimResponse, error: error };
  }
};
export const searchForPendedClaim = async (claimNumber: string): Promise<ClaimResponse> => {
  const user = store.getState().user;

  try {
    const response = await axios.get(
      `${getConfig().superedge}${getConfig().searchClaimTarget}?claimNumber=${claimNumber}`,
      {
        headers: {
          'Content-Type': 'application/json',
          Accept: 'application/json',
          Authorization: `Bearer ${user.jwt}`,
          'Ehi-request-id': `${user.eid}_${generateUUID()}`,
        },
      }
    );
    return { success: true, claim: response.data };
  } catch (error) {
    return { success: false, claim: {} as CreateClaimResponse, error: error };
  }
};

enum ClaimsDocumentTags {
  Dru = 1,
  EhiAccidentReports = 12,
}

export const persistDocument = (claimId: number, documents: Document[], unitLocation: string) => {
  const user = store.getState().user;
  const url = `${getConfig().superedge}${getConfig().claimDocumentPersist}`;
  const headers = {
    'Content-Type': 'application/json',
    Accept: 'application/json',
    Authorization: `Bearer ${user.jwt}`,
    'Ehi-Locale': window.navigator.languages[0] ?? window.navigator.language, // TODO read locale from cookie
    'condition-unit-location': unitLocation,
  };
  const data = {
    claimId,
    legacyGroupBranchNumber: user.groupBranch,
    createdByUserId: user.eid,
    noteText: '',
    documentResources: documents.map((document) => ({ documentName: document.name, documentBytes: document.bytes })),
    tagIds: [ClaimsDocumentTags.Dru, ClaimsDocumentTags.EhiAccidentReports],
  };
  return axios.post<{ documentIds: number[] }>(url, data, { headers }).catch(({ error }) => {
    console.error(error);
    showNetworkErrorSnackbar('errors.persistClaimError');
  });
};
