import React, { useEffect } from 'react';
import { Grid, TextField } from '@mui/material';
import { useTranslation } from 'react-i18next';

import {
  addClaimQuestion,
  addClaimQuestionDefault,
  claimData as claimState,
  setClaim,
} from '../../../../redux-tool-kit/claimSlice';
import { useAppDispatch, useAppSelector } from '../../../../redux-tool-kit/hooks';
import { QuestionInformation } from '../../../../types';
import { QuestionType } from '../../../../types/enums';
import { getQuestionIntegerAnswer, getQuestionYesNoAnswer } from '../../../../utils/claims/ClaimHelpers';
import { QuestionsHeader } from '../../shared/QuestionsHeaders';
import { QuantityResponse } from '../QuantityResponse';
import { Question } from '../Question';
import { YesNoResponse } from '../YesNoResponse';
import DocumentUploader from '../DocumentUploader';
import { YesNo } from '../../../../types/util/UtilTypes';

export const OxEmployeeLossQuestions = () => {
  const claimData = useAppSelector(claimState);

  const { t } = useTranslation();
  const dispatch = useAppDispatch();

  useEffect(() => {
    dispatch(
      addClaimQuestionDefault({
        questionTypeId: QuestionType.PASSENGERS_IN_OUR_VEHICLE,
        answerInteger: 0,
      })
    );
  }, []);

  return (
    <Grid container item data-testid='emp-loss-questions' sx={{ pt: '0px !important' }}>
      <QuestionsHeader />
      <Grid container sx={{ marginBottom: '3%' }}>
        <Question>{t('claims.offRentPassenger')}</Question>
        <QuantityResponse
          onChange={(e): void => {
            const answerInteger = parseInt(e.target.value);
            if (!Number.isNaN(answerInteger)) {
              dispatch(
                addClaimQuestion({
                  questionTypeId: QuestionType.PASSENGERS_IN_OUR_VEHICLE,
                  answerInteger: parseInt(e.target.value),
                })
              );
            }
          }}
          value={getQuestionIntegerAnswer(claimData.questionsInformation, QuestionType.PASSENGERS_IN_OUR_VEHICLE)}
        />
      </Grid>

      <Grid container sx={{ marginBottom: '3%' }}>
        <Question>{`${t('claims.otherVehicleInvolved')}*`}</Question>
        <YesNoResponse
          change={(e: React.MouseEvent<HTMLElement, MouseEvent>, value: YesNo): void => {
            const downstreamQuestionTypes = [
              QuestionType.THIRD_PARTY_HAS_INSURANCE,
              QuestionType.PASSENGERS_IN_OTHER_VEHICLE,
              QuestionType.OTHER_VEHICLE_INVOLVED,
              QuestionType.INJURIES,
              QuestionType.POLICE_REPORT,
              QuestionType.WITNESS_PEDESTRIAN_INVOLVED,
            ];
            dispatch(
              setClaim({
                questionsInformation: claimData.questionsInformation.filter(
                  (q: QuestionInformation) => !downstreamQuestionTypes.includes(q.questionTypeId)
                ),
                witnesses: [],
                pedestrians: [],
                thirdPartyVehiclesInformation: [
                  {
                    insurance: {
                      companyName: '',
                      policyNumber: '',
                    },
                  },
                ],
                policeDepartments: [],
              })
            );
            dispatch(
              addClaimQuestion({
                questionTypeId: QuestionType.OTHER_VEHICLE_INVOLVED,
                answerIndicator: value === 'yes',
              })
            );
            dispatch(
              addClaimQuestionDefault({
                questionTypeId: QuestionType.PASSENGERS_IN_OTHER_VEHICLE,
                answerInteger: 0,
              })
            );
          }}
          value={getQuestionYesNoAnswer(claimData.questionsInformation, QuestionType.OTHER_VEHICLE_INVOLVED)}
        />
      </Grid>
      {getQuestionYesNoAnswer(claimData.questionsInformation, QuestionType.OTHER_VEHICLE_INVOLVED) === 'no' && (
        <Grid container sx={{ marginBottom: '3%' }}>
          <Question>{`${t('claims.otherPeopleInvolved')}*`}</Question>
          <YesNoResponse
            change={(e: React.MouseEvent<HTMLElement, MouseEvent>, value: YesNo): void => {
              dispatch(setClaim({ witnesses: [], pedestrians: [] }));
              dispatch(
                addClaimQuestion({
                  questionTypeId: QuestionType.WITNESS_PEDESTRIAN_INVOLVED,
                  answerIndicator: value === 'yes',
                })
              );
            }}
            value={getQuestionYesNoAnswer(claimData.questionsInformation, QuestionType.WITNESS_PEDESTRIAN_INVOLVED)}
          />
        </Grid>
      )}
      {getQuestionYesNoAnswer(claimData.questionsInformation, QuestionType.OTHER_VEHICLE_INVOLVED) === 'yes' && (
        <Grid container sx={{ marginBottom: '3%' }}>
          <Question>{`${t('claims.otherInsurance')}*`}</Question>
          <YesNoResponse
            change={(e: React.MouseEvent<HTMLElement, MouseEvent>, value: YesNo): void => {
              dispatch(
                addClaimQuestion({
                  questionTypeId: QuestionType.THIRD_PARTY_HAS_INSURANCE,
                  answerIndicator: value === 'yes',
                })
              );
              dispatch(
                setClaim({
                  thirdPartyVehiclesInformation: [
                    {
                      insurance: {
                        companyName: '',
                        policyNumber: '',
                      },
                    },
                  ],
                })
              );
            }}
            value={getQuestionYesNoAnswer(claimData.questionsInformation, QuestionType.THIRD_PARTY_HAS_INSURANCE)}
          />
        </Grid>
      )}
      {getQuestionYesNoAnswer(claimData.questionsInformation, QuestionType.THIRD_PARTY_HAS_INSURANCE) === 'yes' && (
        <Grid item container sx={{ p: 0, marginBottom: '3%' }} xs={12} justifyContent='center'>
          <Grid
            data-testid='3rdPartyForm'
            container
            sx={{ backgroundColor: 'rgba(245,245,245,1)', marginBottom: '3%', paddingLeft: '2rem' }}
            justifyContent='center'>
            <Grid container sx={{ width: '90%' }}>
              <Grid item xs={12} sx={{ marginBottom: '3%' }}>
                <p>
                  <strong style={{ color: 'rgba(0,0,0,0.87)' }}>{`${t('claims.thirdInsuranceLabel')}`}</strong>
                </p>
              </Grid>
              <Grid item xs={6}>
                <TextField
                  inputProps={{ 'data-testid': 'companyNameTextField' }}
                  sx={{ width: '90%' }}
                  value={claimData.thirdPartyVehiclesInformation![0]?.insurance?.companyName}
                  onChange={(e: React.ChangeEvent<HTMLInputElement>): void => {
                    dispatch(
                      setClaim({
                        thirdPartyVehiclesInformation: [
                          {
                            insurance: {
                              ...claimData.thirdPartyVehiclesInformation![0]?.insurance,
                              companyName: e.target.value,
                            },
                          },
                        ],
                      })
                    );
                  }}
                  label={t('claims.labels.companyName')}
                  variant='outlined'
                />
              </Grid>
              <Grid item xs={6}>
                <TextField
                  inputProps={{ 'data-testid': 'policyNumberTextField' }}
                  sx={{ width: '90%' }}
                  value={claimData.thirdPartyVehiclesInformation![0]?.insurance?.policyNumber}
                  onChange={(e: React.ChangeEvent<HTMLInputElement>): void => {
                    dispatch(
                      setClaim({
                        thirdPartyVehiclesInformation: [
                          {
                            insurance: {
                              ...claimData.thirdPartyVehiclesInformation![0]?.insurance,
                              policyNumber: e.target.value,
                            },
                          },
                        ],
                      })
                    );
                  }}
                  label={t('claims.labels.policyNumber')}
                  variant='outlined'
                />
              </Grid>
              <DocumentUploader carouselHeader={t('claims.claimInformationDocuments')} />
            </Grid>
          </Grid>
        </Grid>
      )}
      {getQuestionYesNoAnswer(claimData.questionsInformation, QuestionType.THIRD_PARTY_HAS_INSURANCE) !== '' && (
        <Grid container sx={{ marginBottom: '3%' }}>
          <Question>{t('claims.passengersOtherVehicle')}</Question>
          <QuantityResponse
            onChange={(e): void => {
              const answerInteger = parseInt(e.target.value);
              if (!Number.isNaN(answerInteger)) {
                dispatch(
                  addClaimQuestion({
                    questionTypeId: QuestionType.PASSENGERS_IN_OTHER_VEHICLE,
                    answerInteger,
                  })
                );
              }
            }}
            value={getQuestionIntegerAnswer(claimData.questionsInformation, QuestionType.PASSENGERS_IN_OTHER_VEHICLE)}
          />
        </Grid>
      )}
      {getQuestionYesNoAnswer(claimData.questionsInformation, QuestionType.THIRD_PARTY_HAS_INSURANCE) !== '' && (
        <Grid container sx={{ marginBottom: '3%' }}>
          <Question>{`${t('claims.otherPeopleInvolved')}*`}</Question>
          <YesNoResponse
            change={(e: React.MouseEvent<HTMLElement, MouseEvent>, value: YesNo): void => {
              dispatch(setClaim({ witnesses: [], pedestrians: [] }));
              dispatch(
                addClaimQuestion({
                  questionTypeId: QuestionType.WITNESS_PEDESTRIAN_INVOLVED,
                  answerIndicator: value === 'yes',
                })
              );
            }}
            value={getQuestionYesNoAnswer(claimData.questionsInformation, QuestionType.WITNESS_PEDESTRIAN_INVOLVED)}
          />
        </Grid>
      )}

      {getQuestionYesNoAnswer(claimData.questionsInformation, QuestionType.WITNESS_PEDESTRIAN_INVOLVED) !== '' && (
        <Grid container sx={{ marginBottom: '3%' }}>
          <Question>{t('claims.anyInjuries')}</Question>
          <YesNoResponse
            change={(e: React.MouseEvent<HTMLElement, MouseEvent>, value: YesNo): void => {
              dispatch(addClaimQuestion({ questionTypeId: QuestionType.INJURIES, answerIndicator: value === 'yes' }));
            }}
            value={getQuestionYesNoAnswer(claimData.questionsInformation, QuestionType.INJURIES)}
          />
        </Grid>
      )}
    </Grid>
  );
};
