import useSWRImmutable from 'swr/immutable';
import { useAppSelector } from '../redux-tool-kit/hooks';
import { selectJwt } from '../redux-tool-kit/userSlice';
import { edgeGetFetcher } from '../services';
import type { ConditionType } from '../types';
import { getConfig } from '../utils/config';

const fetcher = ([url, jwt]: [string, string | null]) =>
  edgeGetFetcher(url, jwt, 'errors.getConditionTypesError').then(({ conditionTypes }) =>
    conditionTypes.reduce(
      (table: Record<string, string>, { conditionType, name }: ConditionType) => ({ ...table, [conditionType]: name }),
      {}
    )
  );

const useConditionType = () => {
  const jwt = useAppSelector(selectJwt);
  return useSWRImmutable<Record<string, string>>(jwt ? [`${getConfig().getConditionTypes}`, jwt] : null, fetcher);
};

export default useConditionType;
