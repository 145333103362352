import { Condition, NewCondition } from './Condition';
import { ConditionSource, conditionSourceInitialState } from './ConditionSource';
import { OdometerReading, odometerReadingInitialState } from './OdometerReading';

export interface ConditionReportPayload {
  hasImages: boolean;
  wasVehiclePresent: boolean;
  hasClaimableDamage: boolean;
  source: ConditionSource;
  hasNewDamage: boolean;
  urn: string;
  odometerReading: OdometerReading;
  captureDateTime: string;
  comment: string;
  overriddenConditions?: Condition[];
  voidedConditions?: Condition[];
  newConditions?: NewCondition[];
  closedConditions?: Condition[];
}

export const conditionReportPayloadInitialState: ConditionReportPayload = {
  hasImages: false,
  wasVehiclePresent: false,
  hasClaimableDamage: false,
  source: conditionSourceInitialState,
  hasNewDamage: false,
  urn: '',
  odometerReading: odometerReadingInitialState,
  captureDateTime: '',
  comment: '',
};
