import styled from 'styled-components';

export const StyledClaimSummary = styled.div`
  .action-buttons {
    border-top: 1px solid lightgray;
    margin-top: 0px;
    position: -webkit-sticky;
    position: sticky;
    bottom: 0;
    z-index: 2;
  }
  .subheader-text {
    padding-top: 5px;
    padding-bottom: 5px;
  }
  .paper-section {
    padding: 20px;
    margin-bottom: 2px;
  }
  .MuiTypography-caption {
    color: gray;
  }
`;
