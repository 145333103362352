import useSWRImmutable from 'swr/immutable';

import { useAppSelector } from '../redux-tool-kit/hooks';
import { selectJwt } from '../redux-tool-kit/userSlice';
import { getConfig } from '../utils/config';
import { edgeGetFetcher } from '../services';
import { ClaimTypeResponse } from '../types/apiTypes';
import useUnitLocation from './useUnitLocation';

const fetcher = ([url, jwt, unitLocation]: [string, string | null, string | null]) => {
  return edgeGetFetcher(url, jwt, 'errors.getClaimTypeError', { 'condition-unit-location': unitLocation });
};

const useClaimType = () => {
  const jwt = useAppSelector(selectJwt);
  const unitLocation = useUnitLocation();
  return useSWRImmutable<ClaimTypeResponse[]>(jwt ? [`${getConfig().getClaimType}`, jwt, unitLocation] : null, fetcher);
};

export default useClaimType;
