import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import type { RootState } from './store';
import { SnackBarState } from '../types/sliceTypes';

export const snackBarInitialState: SnackBarState = {
  show: false,
};

export const snackBar = createSlice({
  name: 'snackBar',
  initialState: snackBarInitialState,
  reducers: {
    showSnackBar: (state, action: PayloadAction<SnackBarState>) => {
      const newState: SnackBarState = {
        ...action.payload,
      };
      return newState;
    },
    hideSnackBar: () => {
      const newState: SnackBarState = {
        show: false,
      };
      return newState;
    },
  },
});

export const { showSnackBar, hideSnackBar } = snackBar.actions;

export const snackBarData = (state: RootState) => state.snackBar;

export default snackBar.reducer;
