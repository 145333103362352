import React, { useState, SetStateAction, Dispatch } from 'react';
import { Grid, Button, RadioGroup, Radio } from '@mui/material';
import { CircularProgress } from '@mui/material';
import { StyledRentalAgreementSelect } from '../../styles/modals/StyledRentalAgreementSelect';
import { useTranslation } from 'react-i18next';
import { useAppSelector, useAppDispatch } from '../../redux-tool-kit/hooks';
import {
  setClaim,
  claimData,
  setGroupBranch,
  setRenterInformation,
  setRentalAgreementNumber,
} from '../../redux-tool-kit/claimSlice';
import { PersonalInformation, RentalAgreement } from '../../types';
import { mostSignificant } from '../../utils/urn';
import useAppParams from '../../hooks/useAppParams';
import { useRentalAgreements } from '../../hooks/useRentalAgreements';
import { isEmpty } from 'lodash';

interface RentalAgreementProps {
  modalShouldBeOpen: Dispatch<SetStateAction<boolean>>;
  resetClaimType: Dispatch<SetStateAction<boolean | null>>;
}

const RentalAgreementSelection = ({ modalShouldBeOpen, resetClaimType }: RentalAgreementProps) => {
  const dispatch = useAppDispatch();
  const { t } = useTranslation();
  const { vid } = useAppParams();
  const claim = useAppSelector(claimData);
  const [isSelectionDisabled, setIsSelectionDisabled] = useState(true);
  const [radioVal, setRadioVal] = useState('');
  const [selectedValue, setSelectedValue] = useState<string | null>(null);
  const { data: rentalAgreements, error, isLoading, size, setSize } = useRentalAgreements(['OPEN']);

  const allAgreements = rentalAgreements?.flat() ?? [];

  const handleSelectedAgreement = () => {
    dispatch(setRentalAgreementNumber(selectedValue!));

    const ra = allAgreements.find(
      (result: RentalAgreement) =>
        result?.rentalAgreement?.rentalAgreement &&
        mostSignificant(result?.rentalAgreement?.rentalAgreement) === selectedValue
    );

    const rentalGroupBranch = ra?.rentalAgreement?.lengthOfRental.supplyingCounter.legacyId;
    dispatch(setGroupBranch(rentalGroupBranch!));

    const renter = ra?.rentalAgreement?.renter;
    const personalInformation: Partial<PersonalInformation> = {
      firstName: renter?.givenName,
      lastName: renter?.surname,
    };
    if (!isEmpty(personalInformation)) {
      dispatch(setRenterInformation({ personalInformation }));
    }

    modalShouldBeOpen(false);
  };

  const enterManually = () => {
    setRadioVal('');
    dispatch(setClaim({ ...claim, rentalContract: undefined }));
    modalShouldBeOpen(false);
  };

  const handleRadioselect = (e: React.ChangeEvent<HTMLInputElement>) => {
    setIsSelectionDisabled(false);
    setRadioVal(e.target.value);
    setSelectedValue(e.target.value);
  };

  const handleInfiniteScroll = (e: React.UIEvent<HTMLDivElement, UIEvent>) => {
    const bottom = e.currentTarget.clientHeight === e.currentTarget.scrollHeight - e.currentTarget.scrollTop;
    if (bottom) {
      setSize(size + 1);
    }
  };

  return (
    <StyledRentalAgreementSelect data-testid='report-submit-error'>
      <Grid container alignItems={'center'}>
        <Grid item xs={12}>
          <div id='rental-agreement-select-header' data-testid='rental-agreement-select-header'>
            {t('claims.selectRentalAgreement')}
          </div>
        </Grid>
      </Grid>
      {(!rentalAgreements || isLoading) && !error ? (
        <div id='rental-agreement-loading' data-testid='loading-spinner'>
          <CircularProgress id='spinner' />
        </div>
      ) : error ? (
        <div id='network-error'>
          <div id='msg'>{t('claims.anErrorOccured')}</div>
        </div>
      ) : rentalAgreements && allAgreements.length > 0 ? (
        <>
          <div id='ras-subhead'>{`${t('claims.openRentalAgreements')} ${vid}`}</div>
          <RadioGroup
            id='rental-agreement-radio-group'
            aria-labelledby='rental-agreement-radio-group'
            name='controlled-radio-buttons-group'
            value={radioVal}
            onChange={handleRadioselect}
            onScroll={handleInfiniteScroll}>
            <Grid id='table-selection' justifyContent='flex-start' container spacing={1}>
              <>
                <Grid id='table-headers' container item>
                  <Grid item xs={1} />
                  <Grid item xs={5}>
                    {t('claims.driver')}
                  </Grid>
                  <Grid item xs={3}>
                    {t('claims.rentalAgreements')}
                  </Grid>
                  <Grid item xs={3}>
                    {t('claims.status')}
                  </Grid>
                </Grid>
                {rentalAgreements &&
                  allAgreements.map((ra: RentalAgreement) => (
                    <Grid
                      key={ra.rentalAgreement.rentalAgreement}
                      className='divider'
                      container
                      item
                      alignItems={'center'}>
                      <Grid item xs={1}>
                        <Radio
                          className='rental-agreement-radios'
                          value={mostSignificant(ra.rentalAgreement.rentalAgreement)}
                        />
                      </Grid>
                      <Grid item xs={5}>
                        {ra.rentalAgreement.renter.givenName} {ra.rentalAgreement.renter.surname}
                      </Grid>
                      <Grid item xs={3}>
                        {mostSignificant(ra.rentalAgreement.rentalAgreement)}
                      </Grid>
                      <Grid item xs={3}>
                        {mostSignificant(ra.rentalAgreement.status)}
                      </Grid>
                    </Grid>
                  ))}
              </>
            </Grid>
          </RadioGroup>
        </>
      ) : (
        <div id='none-found'>
          <div id='msg'>{t('claims.noRentalAgreements')}</div>
        </div>
      )}

      <Grid container direction='row' justifyContent='flex-end' id='close-button'>
        {isLoading || error || (rentalAgreements && allAgreements.length === 0) ? (
          <Grid item>
            <Button
              data-testid='exit'
              onClick={() => {
                modalShouldBeOpen(false);
                resetClaimType(null);
              }}>
              {t('claims.cancel')}
            </Button>
          </Grid>
        ) : (
          <>
            <Grid item>
              <Button data-testid='confirm-selection' onClick={enterManually}>
                {t('claims.useAnother')}
              </Button>
            </Grid>
            <Grid item>
              <Button
                data-testid='confirm-selection'
                variant='contained'
                disabled={isSelectionDisabled}
                onClick={handleSelectedAgreement}>
                {t('claims.useSelected')}
              </Button>
            </Grid>
          </>
        )}
      </Grid>
    </StyledRentalAgreementSelect>
  );
};

export default RentalAgreementSelection;
