import styled from 'styled-components';
import { Box } from '@mui/material';

export const StyledConditionCapture = styled.article`
  display: flex;
  flex-direction: column;
  font-family: Helvetica, Arial, sans-serif;

  > div.details-display {
    width: 100vw;
    text-align: left;
    padding: 1rem;

    h1 {
      font-size: 1.3rem;
      margin: 1rem 0 1.5rem 0;
    }

    section.vehicle-details {
      display: flex;
      margin: 0 0 2rem 0;

      dl {
        display: flex;
        flex-direction: column;
        margin: 0;

        > div {
          display: flex;
          flex-direction: row;

          dt,
          dd {
            width: 12vw;
            text-align: left;
          }

          dt {
            color: grey;
            font-size: 0.7rem;
          }

          dd {
            color: dimgrey;
            font-weight: 600;
            font-size: 0.85rem;
          }
        }
      }
    }

    section.vehicle-details dl:first-of-type {
      margin-right: 0.75rem;
    }

    section.toggles-and-fish {
      display: flex;
      flex-direction: row;

      div.toggles {
        border: 1px solid lightgrey;
        border-radius: 4px;
        padding: 0.85rem;
        margin: 0 1rem 0 0;
        width: 20vw;

        h3 {
          font-size: 0.75rem;
          padding: 0;
          margin: 0 0 1rem 0;
        }
      }

      div.fish {
        width: 40vw;
        height: calc(1.25 * 40vw);
        background: grey;
      }
    }

    h2 {
      font-size: 1rem;

      span {
        color: grey;
        font-size: 0.7rem;
      }
    }
  }
`;

export const EquipmentHeader = styled(Box)`
  font-size: 14pt;
  color: #757575;
  margin-bottom: 1px;
  margin-top: 1px;
  font-weight: bold;
`;
