import styled from 'styled-components';

export const StyledSubHeading = styled.div`
  color: rgba(0, 0, 0, 0.54);
  font-size: 12px;
  line-height: 16px;
  letter-spacing: 0.4px;
  text-align: center;
  padding-top: 5px;
`;

export const StyledDate = styled.div`
  color: rgba(0, 0, 0, 0.87);
  font-size: 14px;
  line-height: 24px;
  letter-spacing: 0.15px;
  text-align: center;
`;

export const StyledImagePreviewContainer = styled.div`
  padding-top: 20px;
  padding-left: 3px;
  padding-bottom: 15px;
`;
