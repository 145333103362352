import useSWRImmutable from 'swr/immutable';
import { useAppSelector } from '../redux-tool-kit/hooks';
import { selectJwt } from '../redux-tool-kit/userSlice';
import { CountriesListResponse } from '../types/apiTypes';

import { getConfig } from '../utils/config';
import { edgeGetFetcher } from '../services';

const fetcher = ([url, jwt]: [string, string | null]) =>
  edgeGetFetcher(url, jwt, 'errors.getCountriesError').then((countries) => countries);

const useCountries = () => {
  const jwt = useAppSelector(selectJwt);

  return useSWRImmutable<CountriesListResponse[]>(jwt ? [`${getConfig().getCountries}`, jwt] : null, fetcher);
};

export default useCountries;
