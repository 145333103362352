export enum IncidentType {
  THEFT = 6,
  CONVERSION = 7,
  UNACCOUNTED_THEFT = 8,
  FRAUDULENT_RENTAL = 9,
  VANDALISM = 10,
  UNDOCUMENTED_DAMAGE = 12,
  DAMAGE = 13,
  WEATHER = 14,
  FIRE = 15,
  GLASS = 17,
  EMPLOYEE_LOSS = 18,
  TRANSPORT_NEW = 19,
  TRANSPORT_EMPLOYEE = 20,
  LOT_DAMAGE = 21,
  GENERAL_LIABILITY = 23,
}
