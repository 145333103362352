import React, { useState, useRef } from 'react';
import { Dialog } from '@mui/material';

import { StyledImagePreview } from '../../../styles/screens';
import { ImageCapturePreview } from '../ImageCapture/ImageCapturePreview';
import type { ImageUploadCallback } from '../../../types/conditionTypes/ConditionImage';

interface ImageUploadProps {
  renderIcon: JSX.Element;
  showPreview?: boolean;
  onUpload: ImageUploadCallback;
}

export const ImageUpload = ({ renderIcon, showPreview = false, onUpload }: ImageUploadProps) => {
  const [currentImage, setCurrentImage] = useState('');
  const [currentImageName, setCurrentImageName] = useState<string>();
  const fileUploadRef = useRef<HTMLInputElement>(null);

  const openImageUpload = () => {
    fileUploadRef.current?.click();
  };

  const handleFileChange = async (e: React.ChangeEvent<HTMLInputElement>) => {
    const fileReader: FileReader = new FileReader();
    let file: string = '';
    const targetFile = e.target.files![0];

    fileReader.addEventListener(
      'load',
      () => {
        file = fileReader.result as string;
        setCurrentImage(file);
        if (!showPreview) {
          clearImageUploadInput();
          upload();
        }
      },
      false
    );

    fileReader.readAsDataURL(targetFile);
    setCurrentImageName(e.target.files![0].name);
  };

  const upload = async () => {
    onUpload(currentImage, currentImageName);
    clear();
  };

  const clear = () => {
    clearImageUploadInput();
    setCurrentImage('');
  };

  const handleRetake = () => {
    clear();
    openImageUpload();
  };

  const clearImageUploadInput = () => {
    if (fileUploadRef.current) {
      fileUploadRef.current.value = '';
    }
  };

  return (
    <>
      <span onClick={openImageUpload} data-testid='image-upload'>
        {renderIcon}
        <div data-testid='image-uploading'>
          <input type='file' hidden ref={fileUploadRef} onChange={handleFileChange} accept='image/*' />
        </div>
      </span>
      <Dialog style={{ maxHeight: '95vh' }} open={currentImage !== '' && showPreview} onClose={clear} fullWidth>
        <StyledImagePreview>
          <ImageCapturePreview
            image={currentImage}
            handleAccept={upload}
            handleCancel={clear}
            handleRetake={handleRetake}
          />
        </StyledImagePreview>
      </Dialog>
    </>
  );
};
