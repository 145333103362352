import { useEffect } from 'react';
import moment from 'moment';
import useSWRImmutable from 'swr/immutable';
import { BusinessLine, DeviceType, EhiGlobalMetadata, PendoMetadata, updatePendoOptions } from '@ehi/analytics';
import { useAppSelector } from '../redux-tool-kit/hooks';
import { selectJwt } from '../redux-tool-kit/userSlice';
import { getConfig } from '../utils/config';
import { edgeGetFetcher } from '../services';
import { SetBranchHierarchy } from './SetBranchHierarchy';
import { AssignedLocationDetails } from './AssignedLocationDetails';

interface useBasePendoMetadataProps {
  eid: string;
  userGroupBranch: string;
  userRole: string;
  partialData?: Partial<PendoMetadata>;
  locale: string;
  logError?: (e: unknown) => void;
}

const fetcher = ([url, jwt]: [string, string | null]) =>
  edgeGetFetcher(url, jwt, 'errors.pendoNetworkError', { Accept: '*/*' }).then((data) => data);

const useAssignedLocationDetails = (userGroupBranch: string, jwt: string | null) => {
  const url = `${getConfig().getBranches}&userGroupBranch=${userGroupBranch}`;
  return useSWRImmutable<AssignedLocationDetails[]>(!!userGroupBranch && jwt ? [url, jwt] : null, fetcher);
};

const useSetBranchHierarchy = (userGroupBranch: string, jwt: string | null) => {
  const url = `${getConfig().getLegacyBranch}&groupBranch=${userGroupBranch}`;
  return useSWRImmutable<SetBranchHierarchy>(!!userGroupBranch && jwt ? [url, jwt] : null, fetcher);
};

export const useBasePendoAttributes = ({
  eid,
  userGroupBranch,
  userRole,
  locale,
  logError,
}: useBasePendoMetadataProps) => {
  const jwt = useAppSelector(selectJwt);

  const { data: assignedLocationDetailsData, isLoading: isAssignedLocationLoading } = useAssignedLocationDetails(
    userGroupBranch,
    jwt
  );
  const assignedLocationDetails = assignedLocationDetailsData?.[0];
  const { data: setBranchHierarchy, isLoading: isSetBranchHierarchyLoading } = useSetBranchHierarchy(
    userGroupBranch,
    jwt
  );

  useEffect(() => {
    try {
      let countryCode = 'USA';
      if (assignedLocationDetails?.addresses && assignedLocationDetails?.addresses[0]?.countryCode) {
        countryCode = assignedLocationDetails?.addresses[0]?.countryCode;
      }
      const globalMetadata = {
        assignedBranchDivision: assignedLocationDetails?.type || '',
        assignedBrand: assignedLocationDetails?.brand || '',
        assignedCountry: countryCode,
        assignedArea: setBranchHierarchy?.area.legacyId || '',
        assignedCity: setBranchHierarchy?.city?.id || '',
        assignedRegion: setBranchHierarchy?.region?.id || '',
        // @ts-ignore
        assignedBranchBusinessLine: setBranchHierarchy?.branch.airportCode
          ? BusinessLine.AIRPORT
          : BusinessLine.HOME_CITY,
        // @ts-ignore
        assignedSvpTeam: setBranchHierarchy?.responsibleParty || '',
        // @ts-ignore
        setLocationBranchDivision: setBranchHierarchy?.branch?.type || '',
        setLocationCountry: setBranchHierarchy?.branch?.countryCode || '',
        setLocationGroup: setBranchHierarchy?.group.operationalId || '',
        setLocationGroupBranch: setBranchHierarchy?.branch.operationalId || '',
        eid: eid,
        assignedGroup: userGroupBranch?.substring(0, 2) || '',
        assignedGroupBranch: userGroupBranch || '',
        jobTitle: userRole.toUpperCase() || '',
        locale: locale || '',
        deviceType: navigator.userAgent.includes('Android') ? DeviceType.TABLET : DeviceType.CORPORATE_DESKTOP,
        // eslint-disable-next-line new-cap
        timezone: Intl.DateTimeFormat().resolvedOptions().timeZone,
        dateTime: moment().format('L LT'),
      } as EhiGlobalMetadata;
      const customMetaData = {
        // @ts-ignore
        airport: setBranchHierarchy?.branch.airportCode || '',
        accountid: setBranchHierarchy?.group.operationalId || '',
      };
      updatePendoOptions({
        pendoMetadata: { ehiGlobalMetadata: globalMetadata, customApplicationMetadataAttributes: customMetaData },
      });
    } catch (e) {
      logError?.(e);
    }
  }, [
    assignedLocationDetails,
    eid,
    userGroupBranch,
    userRole,
    locale,
    setBranchHierarchy,
    isAssignedLocationLoading,
    isSetBranchHierarchyLoading,
  ]);
};
