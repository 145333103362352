import useSWRImmutable from 'swr/immutable';
import { edgeGetFetcher } from '../services';
import { useAppSelector } from '../redux-tool-kit/hooks';
import { selectJwt } from '../redux-tool-kit/userSlice';
import type { VehicleComponent } from '../types/claim/vehicle/VehicleComponents';
import { getConfig } from '../utils/config';
import useVehicle from './useVehicle';

const fetcher = ([url, jwt]: [string, string | null]) =>
  edgeGetFetcher(url, jwt, 'errors.getComponentError').then(({ components }) => components);

const useComponent = () => {
  const jwt = useAppSelector(selectJwt);
  const { data: vehicle } = useVehicle();
  return useSWRImmutable<VehicleComponent[]>(
    vehicle
      ? [
          `${getConfig().conditionComponentTarget}&country=${vehicle.operatingCountry}&vehicleClass=${
            vehicle.vehicleAsset.specification.sippCode
          }`,
          jwt,
        ]
      : null,
    fetcher
  );
};

export const useComponentMap = () => {
  const { data } = useComponent();
  if (!data) {
    return {};
  } else {
    return data.reduce(
      (table: Record<string, string>, { urn, name }: VehicleComponent) => ({ ...table, [urn]: name }),
      {}
    );
  }
};

export const useComponentFiltered = (filter: 'INTERIOR' | 'EXTERIOR') => {
  const { data } = useComponent();
  if (!data) {
    return [];
  } else {
    return data.filter((component) => component.location.toUpperCase().includes(filter));
  }
};

export default useComponent;
