export interface DateFormatMappings {
  engUSA: string;
  engCAN: string;
  engGBR: string;
  engIRL: string;
  fraFRA: string;
  fraCAN: string;
  spaESP: string;
  deuDEU: string;
}

export const SupportedDateFormats: DateFormatMappings = {
  engUSA: 'en-US',
  engCAN: 'en-CA',
  engGBR: 'en-GB',
  engIRL: 'en-IE',
  fraFRA: 'fr-FR',
  fraCAN: 'fr-CA',
  spaESP: 'es-ES',
  deuDEU: 'de-DE',
};
