import { Employee } from '../types/claim/person/Employee';
import useSWRImmutable from 'swr';
import { getConfig } from '../utils/config';
import { edgeGetFetcher } from '../services';
import { useAppSelector } from '../redux-tool-kit/hooks';
import { selectJwt } from '../redux-tool-kit/userSlice';

const fetcher = ([url, jwt]: [string, string | null]) =>
  edgeGetFetcher(url, jwt, 'errors.getEmployeeInfoError').then((data) => data);

export const useEmployeeInfo = (eid: string) => {
  const url = `${getConfig().employeeInfoTarget}&employeeId=${eid}`;
  const jwt = useAppSelector(selectJwt);

  return useSWRImmutable<Employee>(eid.length === 6 && jwt ? [url, jwt] : null, fetcher, { revalidateOnFocus: false });
};
