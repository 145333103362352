import { Divider, Grid } from '@mui/material';
import React from 'react';

interface ClaimPrintEmailDividerProps {
  className?: string;
}

export const ClaimPrintEmailDivider: React.FunctionComponent<ClaimPrintEmailDividerProps> = ({
  className,
}: ClaimPrintEmailDividerProps): JSX.Element => {
  return (
    <Grid container item direction='row' data-testid='divider'>
      <Grid item xs={12} className={className}>
        <Divider />
      </Grid>
    </Grid>
  );
};
