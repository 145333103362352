import React from 'react';

interface PendoTagProps {
  tag: string;
  children: JSX.Element;
}

// Injects a pendo tracking tag into the className of child element
const PendoTag: React.FunctionComponent<PendoTagProps> = ({ children, tag }: PendoTagProps): JSX.Element => {
  const mapPendoClassToChildren = () => {
    return React.Children.map(children, (child) => React.cloneElement(child, { className: tag }));
  };

  return <>{mapPendoClassToChildren()}</>;
};

export default PendoTag;
