export interface GetConditionsRequestPayload {
  includeVoidedDamage: boolean;
  includeFullHistory: boolean;
  includeCurrentDamage: boolean;
}

export const getConditionsRequestPayloadInitialState: GetConditionsRequestPayload = {
  includeCurrentDamage: true,
  includeFullHistory: false,
  includeVoidedDamage: false,
};
