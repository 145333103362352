import { Button, Card, CardActions, CardContent } from '@mui/material';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { StyledErrorMessage } from '../../styles/errorComponents/StyledErrorMessage';

const VID_MATCHER = /\/condition\/(?<vid>\w+)/;

export const ErrorMessage = () => {
  const { t } = useTranslation();

  const reload = () => {
    // Navigate back to "capture" page without losing search parameters
    // Note, cannot use `useParams` here as we are outside react router context
    const vid = VID_MATCHER.exec(window.location.toString())?.groups?.vid;
    const path = vid ? `/condition/${vid}/capture/` : '/';
    const url = new URL(path, window.origin);
    url.search = window.location.search;
    window.location.replace(url);
  };

  return (
    <StyledErrorMessage>
      <Card variant='outlined'>
        <CardContent className='content'>{t('errors.errorAdmin')}</CardContent>
        <CardActions className='bottom' disableSpacing>
          <Button className='button' onClick={reload}>
            {t('reload')}
          </Button>
        </CardActions>
      </Card>
    </StyledErrorMessage>
  );
};
