import styled from 'styled-components';

export const StyledRenterSummary = styled.span`
  .renterSummary {
    background-color: #f1f1f1;
  }
  .small {
    font-size: 12px;
  }
  .bold {
    font-weight: bold;
  }
`;
