import { createContext, useContext } from 'react';
import { initialState } from '../../redux-tool-kit/userSlice';
import ProvideAuth from './ProvideAuth';
import PrivateRoute from './PrivateRoute';

const authContext = createContext(initialState);

const useAuthContext = () => {
  return useContext(authContext);
};

export { ProvideAuth, PrivateRoute, authContext, useAuthContext };
