import React, { Suspense } from 'react';
import ReactDOM from 'react-dom';
import { Provider as ReduxProvider } from 'react-redux';
import { store } from './redux-tool-kit/store';
import { GlobalStyle } from './index.styles';
import './index.css';
import './i18n/i18n';
import App from './screens/app/App';
import ErrorBoundary from './shared/ErrorBoundary/ErrorBoundary';
import ConditionCaptureAuthProvider from './screens/auth/ConditionCaptureAuthProvider';
import { StyledCircularProgress } from './styles/shared/StyledCircularProgress';

ReactDOM.render(
  <React.StrictMode>
    <Suspense fallback={<StyledCircularProgress />}>
      <GlobalStyle />
      <ErrorBoundary>
        <ConditionCaptureAuthProvider>
          <ReduxProvider store={store}>
            <App />
          </ReduxProvider>
        </ConditionCaptureAuthProvider>
      </ErrorBoundary>
    </Suspense>
  </React.StrictMode>,
  document.getElementById(`root`)
);
