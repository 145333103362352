import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { RootState } from './store';
import { initialStateSingle } from '../types/claim/vehicle/VehicleComponents';

export const activeComponentSlice = createSlice({
  name: 'activeComponent',
  initialState: initialStateSingle,
  reducers: {
    setActiveComponent: (state, action: PayloadAction<object>) => {
      const newState = {
        ...state,
        ...action.payload,
      };
      return newState;
    },
  },
});

export const { setActiveComponent } = activeComponentSlice.actions;

export const activeComponentData = (state: RootState) => state.activeComponent;

export default activeComponentSlice.reducer;
