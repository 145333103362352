import { createSlice } from '@reduxjs/toolkit';

interface CompleteOrPendClickedState {
  wasPendClaimClicked?: boolean;
  wasCompleteClaimClicked?: boolean;
}

const completeOrPendClickedInitialState: CompleteOrPendClickedState = {
  wasPendClaimClicked: false,
  wasCompleteClaimClicked: false,
};

export const completeOrPendClicked = createSlice({
  name: 'completeOrPendClicked',
  initialState: completeOrPendClickedInitialState,
  reducers: {
    togglePendTrue: (state) => {
      const newState = {
        wasPendClaimClicked: !state.wasPendClaimClicked,
      };
      return newState;
    },
    toggleCompleteTrue: (state) => {
      const newState = {
        wasCompleteClaimClicked: !state.wasCompleteClaimClicked,
      };
      return newState;
    },
  },
});

export const { togglePendTrue, toggleCompleteTrue } = completeOrPendClicked.actions;

export default completeOrPendClicked.reducer;
