import useSWRImmutable from 'swr/immutable';
import { useAppSelector } from '../redux-tool-kit/hooks';
import { selectJwt } from '../redux-tool-kit/userSlice';
import { StatesListResponse } from '../types/apiTypes';

import { getConfig } from '../utils/config';

import { edgeGetFetcher } from '../services';

const fetcher = ([url, jwt]: [string, string | null]) =>
  edgeGetFetcher(url, jwt, 'errors.getStatesError').then((states) => states);

const useStates = (countryIso3Code: string | null) => {
  const jwt = useAppSelector(selectJwt);

  return useSWRImmutable<StatesListResponse>(
    jwt && countryIso3Code ? [`${getConfig().getStates}${countryIso3Code}`, jwt] : null,
    fetcher
  );
};

export default useStates;
