import styled from 'styled-components';

export const StyledVehicleConditionCapture = styled.div`
  li:first-child {
    border: 1px solid lightgray;
    border-top: 1px solid lightgray;
    border-bottom: none;
  }
  li {
    border: 1px solid lightgray;
    border-bottom: none;
  }
  li:last-child {
    border-top: none;
    border: 1px solid lightgray;
    border-bottom: 1px solid lightgray;
  }
  .interiorLabel {
    font-weight: 400;

    line-height: 16px;

    letter-spacing: 0.4px;
    margin-bottom: 20px;
    color: rgba(0, 0, 0, 0.54);
  }
`;
