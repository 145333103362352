import { getConfig } from '../../utils/config';

const makeComponentUrn = (component: string) =>
  `urn:com.ehi:${getConfig().environment}:reference:vehicle:condition:component:${component}`;
export const equipmentURNs = [
  // Note that keys are removed, as they are always present and need to be handled differently
  makeComponentUrn('spareTire'),
  makeComponentUrn('EVChargingCables'),
];

export const additionalEquipmentURNs = [
  makeComponentUrn('triangleKit'),
  makeComponentUrn('fireExtinguisher'),
  makeComponentUrn('towingSleeve'),
  makeComponentUrn('telematics'),
];

export const combinedEquipmentURNs = [...equipmentURNs, ...additionalEquipmentURNs];
