import React, { useEffect, useRef } from 'react';
import { useAuth } from '@ehi/auth';
import { EhiThemeProvider } from '@ehi/ui';
import { StyledEngineProvider } from '@mui/styled-engine-sc';
import { BrowserRouter, Link, Route, Switch } from 'react-router-dom';
import { loadLocaleCookie } from '@ehi/location';
import axios from 'axios';
import i18next, { changeLanguage } from 'i18next';
import { usePendoHandler } from '../../shared/usePendoHandler';
import { ProvideAuth, PrivateRoute } from '../../utils/RBAC';
import { jwtObString } from '../../utils/cookieHandling/cookieTranslation';
import { setUser, userData as userState } from '../../redux-tool-kit/userSlice';
import { useAppDispatch, useAppSelector } from '../../redux-tool-kit/hooks';
import Main from './Main';
import VehicleSearch from '../VehicleSearch/VehicleSearch';
import GlobalErrorModal from '../../shared/GlobalErrorModal/GlobalErrorModal';
import './App.css';
import { IconButton, Snackbar } from '@mui/material';
import { snackBarData } from '../../redux-tool-kit/snackBarSlice';
import CloseIcon from '@mui/icons-material/Close';
import { SnackBarState } from '../../types/sliceTypes';
import { hideSnackBarAlert } from '../../utils/snackBar/snackBarAlert';
import { LaunchDarklyProvider } from '../../shared/LaunchDarkly/LaunchDarklyProvider';
import { ClaimsRoute } from '../claims/claimsRouting';
import { ExitConditionButton } from './ExitConditionButton';
import { NetworkErrorAlert } from '../../shared/ErrorBoundary/NetworkErrorAlert';
import { browserLocaleToIso3 } from '../../utils/language';
import { useIsClaimableDamage } from '../../utils/condition/ConditionHelper';
import { v4 as uuid } from 'uuid';

const App = () => {
  const userData = useAppSelector(userState);
  const userSessionInfo = useAuth();
  const dispatch = useAppDispatch();
  const pendCompleteClaimRef = useRef(0);
  const jwt = userSessionInfo.jwt;
  const pendClaimClicked = useAppSelector((state) => state.completeOrPendClicked.wasPendClaimClicked);
  const completeClaimClicked = useAppSelector((state) => state.completeOrPendClicked.wasCompleteClaimClicked);

  useEffect(() => {
    if (!userData.loggedIn && jwt) {
      const jwtTranslation = jwtObString(jwt);
      const { sub: userEid } = JSON.parse(jwtTranslation);
      axios.defaults.headers['Ehi-Workflow-ID'] = `${userEid}_${uuid()}`;
      dispatch(setUser({ jwtData: jwtTranslation, ...userSessionInfo }));
    }
  }, [userData.loggedIn, dispatch]);

  axios.defaults.headers['Ehi-Calling-Application'] = 'client:com.ehi.vehicle.condition.CaptureSPA';
  // Set language
  if (loadLocaleCookie()) {
    changeLanguage(loadLocaleCookie()).catch((e) => console.error(e));
    axios.defaults.headers['Ehi-Locale'] = browserLocaleToIso3(loadLocaleCookie() as string);
  } else {
    axios.defaults.headers['Ehi-Locale'] = browserLocaleToIso3(i18next.language);
  }

  // Check if pend or complete claim was clicked for reload alert
  if (pendClaimClicked || completeClaimClicked) {
    pendCompleteClaimRef.current = pendCompleteClaimRef.current + 1;
  }

  // establish permission levels
  const perms = {
    read: ['condition:read'],
    create: ['condition:read', 'condition:create'],
    update: ['condition:read', 'condition:create', 'condition:update'],
    delete: ['condition:read', 'condition:create', 'condition:update', 'condition:delete'],
  };

  const snackBarConfirmation = (
    <IconButton size='small' aria-label='close' color='inherit' onClick={() => hideSnackBarAlert()}>
      <CloseIcon fontSize='small' />
    </IconButton>
  );

  const snackBar: SnackBarState = useAppSelector(snackBarData);
  const isClaimableDamage = useIsClaimableDamage();

  addEventListener('beforeunload', (event) => {
    if (isClaimableDamage() && pendCompleteClaimRef.current == 0) {
      event.preventDefault();
      event.returnValue = '';
    } else {
      return null;
    }
  });

  usePendoHandler();

  return (
    <>
      <EhiThemeProvider>
        <StyledEngineProvider injectFirst>
          <ProvideAuth userData={userData}>
            <BrowserRouter>
              <LaunchDarklyProvider>
                <div className='App'>
                  <Switch>
                    <Route exact path='/'>
                      <div data-testid='root-path'>
                        <VehicleSearch />
                      </div>
                    </Route>
                    <PrivateRoute exact path='/condition/:vid/capture' requiredPerms={perms.create}>
                      <div data-testid='vehicle-details-protected-access-read'>
                        <Main />
                        <ExitConditionButton />
                      </div>
                    </PrivateRoute>
                    <PrivateRoute path='/condition/:vid/add/:schematic' requiredPerms={perms.create}>
                      <div>
                        <Main addConditionModalOpen />
                        <Link to='/' data-testid='nav-read-to-home'>
                          homepage
                        </Link>
                      </div>
                    </PrivateRoute>
                    <PrivateRoute path='/condition/:vid/add-other/:schematic' requiredPerms={perms.create}>
                      <div>
                        <Main viewOtherConditionModalOpen={true} />
                        <Link to='/' data-testid='nav-read-to-home'>
                          homepage
                        </Link>
                      </div>
                    </PrivateRoute>
                    <PrivateRoute path='/condition/:vid/capture/view/:schematic' requiredPerms={perms.read}>
                      <div>
                        <Main viewConditionModalOpen />
                        <Link to='/' data-testid='nav-read-to-home'>
                          homepage
                        </Link>
                      </div>
                    </PrivateRoute>
                    <PrivateRoute path='/condition/:vid/claim' requiredPerms={perms.create}>
                      <ClaimsRoute />
                    </PrivateRoute>
                  </Switch>
                </div>
              </LaunchDarklyProvider>
            </BrowserRouter>
            <GlobalErrorModal />
          </ProvideAuth>
        </StyledEngineProvider>
      </EhiThemeProvider>
      <NetworkErrorAlert />
      <Snackbar
        anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
        open={snackBar.show}
        autoHideDuration={6000}
        onClose={() => hideSnackBarAlert()}
        message={snackBar.message}
        action={snackBarConfirmation}
      />
    </>
  );
};

export default App;
