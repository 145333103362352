import React, { ReactElement } from 'react';
import { useCallback } from 'react';
import { asyncWithLDProvider } from 'launchdarkly-react-client-sdk';
import { useAuth } from '@ehi/auth';
import { getConfig } from '../../utils/config';

/* eslint-disable max-len */
/**
 * Reads the clientSideId from config service and initializes LDProvider with the AsyncProviderConfig at the app entry point prior to rendering,
 * to ensure flags and the client are ready at the beginning of the app.
 *
 * If the value “ANONYMOUS” is passed to the SDK as the unique identifier, that will count as one MAU, even if it is used for thousands of users of your app.
 * The identification of a user can be useful in future contexts related to analytics by using the custom attributes as 'eid'.
 *
 * Custom attributes defines the target rules, ex: enable/disable the flags by location, end, eid, webEnvironment, etc.
 *
 * flags: If specified, the React SDK will only subscribe for updates to these flags. When this property is unspecified, the React SDK subscribes to all flags.
 * This property is optional.
 * **/
/* eslint-enable max-len */
type LDProviderProps = {
  children: React.ReactNode;
};

export const useLdProvider = () => {
  const userSessionInfo = useAuth();

  const getLdProvider = useCallback(async () => {
    try {
      console.debug('initializing launch darkly with asyncWithLDProvider');
      return await asyncWithLDProvider({
        clientSideID: `${getConfig().launchDarklyClientId}`,
        user: { key: userSessionInfo?.eid },
        flags: { 'veh-condition-history': true, 'veh-customer-submitted-condition': false },
      });
    } catch (error) {
      console.error('Error running getLdProvider (LaunchDarklyService.tsx)');
      return ({ children }: LDProviderProps) => children as ReactElement;
    }
  }, [userSessionInfo]);
  return { getLdProvider };
};
