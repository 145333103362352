import { Button } from '@mui/material';
import styled from 'styled-components';

export const EquipmentToggleButton = styled(Button)`
  border-radius: 1e8px !important;
  padding: 3px;
  width: fit-content;
  min-width: 98px;
  height: 32px;
  .MuiTypography-root {
    text-transform: none;
    font-weight: 400;
    font-size: 14px;
    line-height: 20px;
  }
`;
