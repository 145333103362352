import styled from 'styled-components';

export const StyledSignaturePad = styled.div`
  .pad-container {
    border: 1px solid #4d789d;
    background-color: #e7e7e7;
  }
  .red {
    color: red;
  }
  .clear-button {
    align-self: flex-end;
  }
  .float-right {
    float: right;
  }
  .signature-background {
    user-select: none;
    margin-top: -200px;
    height: 200px;
    z-index: -1;
    padding-left: 20px;
    padding-right: 20px;
  }
  width: 100%;
  position: relative;
  .signature-message-container {
    align-items: center;
    display: flex;
    justify-content: center;
    min-height: 1.5em;
  }
  .signature-message-container.shorten-opt-out-box {
    width: 10px;
  }
`;
