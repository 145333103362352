import React from 'react';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { DatePicker, LocalizationProvider } from '@mui/x-date-pickers';
import { customTheme } from '../../styles/screens/StyledPickerTheme';
import { ThemeProvider } from '@mui/material/styles';
import { TextField } from '@mui/material';
import { useTranslation } from 'react-i18next';
import i18next from 'i18next';
import { noop } from 'lodash';
import { localeLookup } from '../../utils/language';

interface ConditionDatePickerProps {
  title: string;
  fullWidth?: boolean;
  onChange?: Function;
  onError?: Function;
  value?: Date;
  disableFuture?: boolean;
}

const maskMap: Record<string, string> = {
  fr: '__/__/____',
  en: '__/__/____',
  de: '__.__.____',
};

const ConditionDatePicker = ({
  title,
  fullWidth,
  onChange = noop,
  onError = noop,
  value,
  disableFuture = false,
}: ConditionDatePickerProps) => {
  const { t } = useTranslation();

  return (
    <LocalizationProvider dateAdapter={AdapterDateFns} adapterLocale={localeLookup(i18next.language)}>
      <ThemeProvider theme={customTheme}>
        <DatePicker
          data-testid='picker'
          disableFuture={disableFuture}
          label={t(title)}
          mask={maskMap[i18next.language]}
          value={value}
          onChange={(newValue: Date | null) => {
            if (newValue && !isNaN(newValue?.getTime())) {
              onChange(newValue);
            }
            if (newValue === null) onError();
          }}
          onError={(reason, value) => onError(reason, value)}
          renderInput={(params: any) => (
            <TextField
              {...params}
              fullWidth={fullWidth}
              sx={{ svg: { color: '#507b9f' } }}
              data-testid='date-picker-id'
            />
          )}
        />
      </ThemeProvider>
    </LocalizationProvider>
  );
};

export default ConditionDatePicker;
