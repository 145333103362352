import axios from 'axios';
import { ConditionImage, VISConditionResponse, VISImageFile } from '../../types';
import { getConfig } from '../../utils/config';
import { store } from '../../redux-tool-kit/store';
import { ImageRequestPayload } from '../../types/images/VISConditionResponse';

export const reFormatImage = (image: VISConditionResponse, dateTime: string): ConditionImage => ({
  imageType: 'PHOTO',
  dateTime,
  formattedImages: image.files.map((file: VISImageFile) => ({
    height: file.resolution.height,
    width: file.resolution.width,
    url: file.publicUrl,
    mimeType: `image/${file.publicUrl.split('.')[file.publicUrl.split('.').length - 1]}`,
  })),
});

export const retrieveImageFromURN = async (
  urn: string,
  jwt?: string
): Promise<{ success: boolean; errorMessages: string; data: any }> => {
  try {
    const response: any = await axios.get(`${getConfig().superedge}${getConfig().imageRetrieve}&imageId=${urn}`, {
      headers: {
        'Ehi-Locale': 'en-US',
        'Content-Type': 'application/json',
        Accept: 'application/json',
        Authorization: `Bearer ${jwt}`,
      },
    });
    return { success: true, errorMessages: '', data: response };
  } catch (error: any) {
    return { success: false, errorMessages: error.message, data: '' };
  }
};

export const uploadConditionImage = async (
  image: string,
  vin: string
): Promise<{ success: boolean; errorMessages: string; data: ConditionImage }> => {
  const user = store.getState().user;
  const bytes: string = image.split(',')[1];
  const captureDateTime = new Date().toISOString();

  const payload: ImageRequestPayload = {
    imageBytes: bytes,
    captureDateTime,
    vin,
  };

  try {
    const response: any = await axios.post(`${getConfig().superedge}${getConfig().imageUpload}`, payload, {
      headers: {
        'Content-Type': 'application/prs.com-ehi.vehicle.image.employeeImage+json',
        Authorization: `Bearer ${user.jwt}`,
      },
    });

    return { success: true, errorMessages: '', data: reFormatImage(response.data, captureDateTime) };
  } catch (error: any) {
    return { success: false, errorMessages: error.message, data: {} as ConditionImage };
  }
};
