import { useHistory, useLocation } from 'react-router-dom';
import useAppParams from '../hooks/useAppParams';

export const useAppNavigation = () => {
  const history = useHistory();
  const { vid } = useAppParams();
  const { search } = useLocation();

  if (!vid) {
    return {
      ...history,
      reset: () => history.push('/'),
    };
  } else {
    return {
      ...history,
      push: (url: string) => history.push(`/condition/${vid}${url}${search}`),
      reset: () => history.push('/'),
    };
  }
};

export const useSearchParams = () => {
  const { search } = window.location;
  return new URLSearchParams(search);
};
