import { Box, Grid, TextField } from '@mui/material';
import React from 'react';
import { useTranslation } from 'react-i18next';

interface QuantityResponseProps {
  value: number | undefined;
  onChange: React.ChangeEventHandler<HTMLInputElement | HTMLTextAreaElement> | undefined;
}

export const QuantityResponse = ({ value, onChange }: QuantityResponseProps) => {
  const { t } = useTranslation();
  return (
    <Grid item xs={5}>
      <Box display='flex' justifyContent='flex-end'>
        <TextField
          inputProps={{ 'data-testid': 'quantityPicker', min: 0, max: 6 }}
          onChange={onChange}
          sx={{ width: '80%' }}
          inputMode='numeric'
          type={'number'}
          value={value ? value : 0}
          label={t('claims.quantity')}
          variant='outlined'
        />
      </Box>
    </Grid>
  );
};
