import { Grid, GridProps, ToggleButton, ToggleButtonGroup } from '@mui/material';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { StyledYesNoResponse } from '../../../styles/screens';

interface YesNoResponseProps extends GridProps {
  value: 'yes' | 'no' | '';
  change?: (event: React.MouseEvent<HTMLElement, MouseEvent>, value: any) => void;
}

export const YesNoResponse: React.FunctionComponent<YesNoResponseProps> = ({
  value,
  change,
  ...props
}: YesNoResponseProps): JSX.Element => {
  const { t } = useTranslation();

  return (
    <Grid item container xs={props.xs ?? 5} direction='column' alignItems='flex-end' {...props}>
      <StyledYesNoResponse>
        <ToggleButtonGroup exclusive value={value} onChange={change}>
          <ToggleButton
            data-testid='toggle-yes'
            sx={{
              '&:hover': {
                color: value === 'yes' ? '#FFFFFF' : '#4D789D',
                backgroundColor: value === 'yes' ? '#4D789D' : '#FFFFFF',
              },
              '&.Mui-selected': {
                color: '#FFFFFF',
                backgroundColor: '#4D789D !important',
              },
            }}
            value={'yes'}>
            {t('yes')}
          </ToggleButton>
          <ToggleButton
            data-testid='toggle-no'
            sx={{
              '&:hover': {
                color: value === 'no' ? '#FFFFFF' : '#4D789D',
                backgroundColor: value === 'no' ? '#4D789D' : '#FFFFFF',
              },
              '&.Mui-selected': {
                color: '#FFFFFF',
                backgroundColor: '#4D789D !important',
              },
            }}
            value={'no'}>
            {t('no')}
          </ToggleButton>
        </ToggleButtonGroup>
      </StyledYesNoResponse>
    </Grid>
  );
};
