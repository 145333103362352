import styled from 'styled-components';

export const StyledConditionHistory = styled.span`
  .bold {
    font-weight: bold;
  }
  ul {
    margin: 0;
  }
  .close-button {
    color: #1976d2;
    cursor: pointer;
    text-transform: uppercase;
    padding-top: 8px !important;
    max-width: 100px;
  }
  .flatfish-wrapper {
    overflow-y: hidden;
    overflow-x: hidden;
    display: flex;
    align-items: center;
  }
  .flatfish {
  }
  .footer {
    border-top: 1px solid lightgray;
    background-color: #f1f1f1;
    justify-content: flex-end;
  }
  .table-container {
    border-radius: 4px;
    width: 100%;
    overflow: visible;
  }
  .left-border {
    border-left: lightgray 1px solid;
  }
  .right-border {
    border-right: lightgray 1px solid;
  }
  table {
    border-collapse: separate;
  }
  table th {
    border: none;
  }
  table tr:first-child td {
    border-top: lightgray 1px solid;
  }
  table tr:first-child td:first-child {
    border-top-left-radius: 8px;
  }
  table tr:first-child td:last-child {
    border-top-right-radius: 8px;
  }
  table tr:last-child td:first-child {
    border-bottom-left-radius: 8px;
  }
  table tr:last-child td:last-child {
    border-bottom-right-radius: 8px;
  }
`;
