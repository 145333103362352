import React, { useRef, useEffect, useState } from 'react';
import {
  Button,
  Checkbox,
  Divider,
  FormControlLabel,
  Grid,
  Typography,
  DialogActions,
  DialogContent,
  DialogTitle,
  Dialog,
  RadioGroup,
  Radio,
} from '@mui/material';
import { useTranslation } from 'react-i18next';
import SignaturePad from 'react-signature-pad-wrapper';
import { StyledSignaturePad } from '../../../styles/screens';
import * as _ from 'lodash';
import { Label } from '@mui/icons-material';

interface SummarySignatureProps {
  setComplete: Function;
  onChange?: Function;
  initialValue?: string;
}

export const SummarySignature: React.FunctionComponent<SummarySignatureProps> = ({
  setComplete,
  onChange,
  initialValue,
}): JSX.Element => {
  const { t } = useTranslation();
  const signatureRef = useRef({} as SignaturePad);

  const [optOut, setOptOut] = useState(false);
  const [showOptOutModal, setShowOptOutModal] = useState(false);
  const [reason, setReason] = useState<'refused' | 'notPresent' | undefined>(undefined);

  useEffect(() => {
    if (initialValue && initialValue.length) {
      signatureRef.current.fromDataURL(_.cloneDeep(initialValue));
    }
  }, [initialValue]);

  const getReasonText = () => {
    switch (reason) {
      case 'refused':
        return t('refusedToSign');
      case 'notPresent':
        return t('renterNotPresent');
      default:
        return '';
    }
  };

  return (
    <StyledSignaturePad>
      <Grid container direction='row' justifyContent='flex-start' alignItems='center' data-testid='summary-signature'>
        <Grid item xs={12} data-testid='signatureExplanation'>
          {t('claims.summary.signatureExplanation')}
        </Grid>
        <Grid item xs={11}>
          <div
            data-testid='signature-box'
            onPointerUp={() => {
              onChange && onChange(signatureRef.current.toDataURL());
              setComplete(true);
            }}>
            <div className='pad-container'>
              <SignaturePad
                redrawOnResize={true}
                height={200}
                ref={signatureRef}
                canvasProps={{ className: 'sigPad' }}
              />
            </div>
            <Grid
              container
              direction='column'
              justifyContent='space-around'
              className='signature-background'
              alignItems='stretch'>
              <Grid item sx={{ width: '10px' }}>
                <Label fontSize='large' className='sign-here-icon' color={optOut ? 'disabled' : 'primary'} />
              </Grid>
              <Grid item className={`signature-message-container ${!getReasonText() && 'shorten-opt-out-box'}`}>
                <Typography variant='button'>{getReasonText()}</Typography>
              </Grid>
              <Grid item>
                <Divider sx={{ borderColor: '#575757' }} />
              </Grid>
            </Grid>
          </div>
        </Grid>
        <Grid item xs={6}>
          <FormControlLabel
            checked={optOut}
            onChange={() => {
              if (!optOut) {
                setShowOptOutModal(true);
              } else {
                setOptOut(false);
                setReason(undefined);
                signatureRef.current.on();
                setComplete(false);
              }
            }}
            control={<Checkbox />}
            label={t('optOutOfSignature')}
            labelPlacement='end'
          />
        </Grid>
        <Grid item xs={5}>
          <Button
            onClick={() => {
              signatureRef.current.clear();
              onChange && onChange('');
              setComplete(false);
            }}
            className='clear-button float-right'
            data-testid='clear-button'>
            {t('clear')}
          </Button>
        </Grid>
      </Grid>
      <Dialog open={showOptOutModal} onClose={() => setShowOptOutModal(false)} fullWidth>
        <DialogTitle>{t('optOutReason')}</DialogTitle>
        <DialogContent>
          <Typography>{t('selectReasonForOptingOutSignature')}</Typography>
          <RadioGroup>
            <FormControlLabel
              sx={{
                paddingTop: '10px',
                paddingBottom: '10px',
              }}
              value={t('renterNotPresent')}
              control={<Radio />}
              label={t('renterNotPresent')}
              onChange={() => {
                setReason('notPresent');
              }}
            />
            <Divider />
            <FormControlLabel
              sx={{
                paddingTop: '10px',
                paddingBottom: '10px',
              }}
              value={t('refusedToSign')}
              control={<Radio />}
              label={t('refusedToSign')}
              onChange={() => {
                setReason('refused');
              }}
            />
            <Divider />
          </RadioGroup>
        </DialogContent>
        <DialogActions>
          <Button
            onClick={() => {
              setReason(undefined);
              setOptOut(false);
              setShowOptOutModal(false);
              signatureRef.current.on();
            }}>
            {t('cancel')}
          </Button>
          <Button
            variant='contained'
            disabled={!reason}
            onClick={() => {
              setOptOut(true);
              setShowOptOutModal(false);
              signatureRef.current.clear();
              signatureRef.current.off();
              setComplete(true);
            }}>
            {t('useSelected')}
          </Button>
        </DialogActions>
      </Dialog>
    </StyledSignaturePad>
  );
};
