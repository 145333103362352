import styled from 'styled-components';

export const StyledViewCondition = styled.span`
  .flatfish-wrapper {
    overflow-y: hidden;
    overflow-x: hidden;
    display: flex;
    align-items: center;
  }
  .flatfish {
  }
  .gray {
    font-size: 14px;
  }
  .footer {
    border-top: 1px solid lightgray;
    background-color: #f1f1f1;
    padding: 16px;
  }
  .close-button {
    cursor: pointer;
    text-transform: uppercase;
    padding-top: 8px !important;
    max-width: 100px;
  }
  .fit {
    object-fit: fill !important;
  }
  ul {
    margin-block-start: 0 !important;
    margin-block-end: 0 !important;
    overflow-y: scroll;
    max-height: 400px;
  }
  img {
    border-radius: 4px;
  }
`;

export const StyledModalAddConditionContainer = styled.div`
  background-color: #f1f4f7;
  .damages-list-group-container {
    border: 1px solid #c4c4c4;
    border-radius: 4px;
    width: 95%;
    margin-left: 20px;
    margin-bottom: 2%;
    background-color: #ffffff;
  }
  .condition-list-title {
    font-size: 20px;
    font-weight: 500;
    line-height: 24px;
    letter-spacing: 0.15000000596046448px;
    text-align: left;
  }
  .damages-list-item-container {
    padding-top: 15px;
    padding-bottom: 15px;
  }
`;
