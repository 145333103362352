import { Grid } from '@mui/material';
import styled from 'styled-components';

export const StyledErrorMessage = styled(Grid)`
  text-align: center;
  font-weight: 700;
  font-size: 20px;
  line-height: 24px;
  letter-spacing: 0.15px;
  margin: 10px;
  padding-top: 10px;
  .content {
    margin-top: 30px;
  }
  .bottom {
    padding-bottom: 15px;
    margin-left: 30vw;
    margin-right: 10px;
  }
  .button {
    background: #4d789d;
    border-radius: 0px;
    width: 100px;
    height: 33px;
    font-weight: 500;
    font-size: 14px;
    line-height: 16px;
    display: flex;
    align-items: center;
    letter-spacing: 1.25px;
    text-transform: uppercase;
    color: #ffffff;
    flex: none;
    order: 0;
    flex-grow: 0;
  }
`;
