import React from 'react';
import { Box, CircularProgress } from '@mui/material';

interface TemporaryImageProps {
  bytes: string;
}

const TemporaryImage = ({ bytes }: TemporaryImageProps) => (
  <Box
    sx={{
      height: '4rem',
      width: '8.25rem',
      background: `url(${bytes}) gray`,
      backgroundSize: 'cover',
      cursor: 'pointer',
      textAlign: 'center',
      justifyContent: 'center',
      paddingTop: '2rem',
    }}>
    <CircularProgress />
  </Box>
);

export default TemporaryImage;
