import { OdometerReading, VehicleSliceData } from '../../types';
import { mapConditionData } from '../../types/conditionTypes/ConditionSubmit';
import { store } from '../../redux-tool-kit/store';
import { selectNewConditions, selectOdometer, setConditionSource } from '../../redux-tool-kit/conditionSlice';
import { manageConditionSaveAndRemove } from './ConditionListCheckHelper';
import useConditionType from '../../hooks/useConditionType';
import useCondition from '../../hooks/useCondition';
import { useAppSelector } from '../../redux-tool-kit/hooks';
import { CommonCondition, ConditionCheck } from '../../types/conditionTypes/Condition';
import { capitalize, isEmpty } from 'lodash';
import { combinedEquipmentURNs } from '../../shared/Equipment/Constants';
import { mostSignificant } from '../urn';
import useVehicle from '../../hooks/useVehicle';
import { useSearchParams } from '../useURLs';
import { defaultActivity } from '../../types/conditionTypes/ConditionSource';
import { useRaNumber } from '../../hooks/useRaWearAndTear';

export const useGetConditionTypeName = () => {
  const { data: conditionTypes } = useConditionType();
  return (urn: string): string => conditionTypes?.[urn] ?? capitalize(mostSignificant(urn));
};

export const useGetConditions = () => {
  const { data: previousConditions } = useCondition();
  const newConditions = useAppSelector(selectNewConditions);
  return (isClaimable: boolean): CommonCondition[] => {
    return [
      ...newConditions.filter((condition: CommonCondition) => isClaimable === condition.isClaimable),
      ...(previousConditions
        ? previousConditions.filter((condition: CommonCondition) => isClaimable === condition.isClaimable)
        : []),
    ];
  };
};

const prepConditionSource = (
  vehicle: VehicleSliceData | undefined,
  rentalAgreement: string | null,
  activity: string
) => {
  const user = store.getState().user;
  const locationId = vehicle?.controllingLocation?.split(':');
  return {
    locationId: locationId ? locationId[locationId.length - 1] : '',
    author: user.eid ? user.eid.toUpperCase() : '',
    activity,
    source: rentalAgreement || undefined,
  };
};

export const fuelPercentageFromSixteenths = (sixteenths: number | string): number => Number(sixteenths) * 6.25;

export const useIsClaimableDamage = () => {
  const searchParams = useSearchParams();
  return () => {
    const condition = store.getState().condition;
    const checks: Record<string, ConditionCheck> = store.getState().condition.conditionCheckBoxes;
    return (
      searchParams.get('skipClaimsFlow') !== 'true' &&
      condition.newConditions.some(
        (cond) =>
          cond.isClaimable &&
          !combinedEquipmentURNs.includes(cond.component) &&
          mostSignificant(cond.component)?.indexOf('key') === -1 &&
          checks[cond.urn]?.checked
      )
    );
  };
};

export const useGetPayloadReadyConditionReport = () => {
  const { data: previousConditions } = useCondition();
  const { data: vehicle } = useVehicle();
  const rentalAgreement = useRaNumber();
  const odometer = useAppSelector(selectOdometer);
  const activity = useSearchParams().get('activityType') ?? defaultActivity;
  return () => {
    const conditionData = store.getState().condition;
    if (
      !conditionData.currentConditionReport.source.locationId ||
      !conditionData.currentConditionReport.source.author ||
      !conditionData.currentConditionReport.source.activity ||
      !conditionData.currentConditionReport.source.source
    ) {
      store.dispatch(setConditionSource(prepConditionSource(vehicle, rentalAgreement, activity)));
    }
    if (!isEmpty(conditionData.conditionCheckBoxes)) {
      manageConditionSaveAndRemove(previousConditions ?? []);
    }
    const payload = mapConditionData(store.getState().condition.currentConditionReport);
    if (!odometer.value) {
      throw new Error('Odometer not set');
    }
    payload.odometerReading = odometer as OdometerReading;

    return payload;
  };
};

export const getFlatfishViewFromUrl = () => {
  const url = window.location.href;

  if (url.includes('LEFT')) {
    return 'left';
  } else if (url.includes('RIGHT')) {
    return 'right';
  } else if (url.includes('FRONT')) {
    return 'front';
  } else if (url.includes('BACK')) {
    return 'back';
  } else if (url.includes('TOP')) {
    return 'top';
  } else if (url.includes('NONE')) {
    return 'other';
  } else {
    return 'main-menu';
  }
};
