import styled from 'styled-components';

export const StyledTabs = styled.div`
  padding-right: 36px;
  margin-top: -20px;

  .MuiButtonBase-root {
    font-weight: 525;
    line-height: 16px;
  }
  .Mui-selected {
    color: #4d789d !important;
  }
`;
