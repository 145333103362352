import React from 'react';
import { MsalProvider as Provider } from '@ehi/auth';
import { getMsalConfig } from './MsalConfig';
import { useConfig } from '../../services/Config/ConfigService';

type MsalProviderProps = {
  children: React.ReactNode;
};

export const MsalProvider = ({ children }: MsalProviderProps): JSX.Element | null => {
  const { data: config } = useConfig();

  if (!config || !config?.clientId) {
    return null;
  }

  return <Provider config={getMsalConfig(config?.clientId)}>{children}</Provider>;
};
