import React from 'react';
import { Button, Divider } from '@mui/material';
import LoadingButton from '@mui/lab/LoadingButton';
import { useTranslation } from 'react-i18next';
import { SubmissionState } from '../../../types';
import PendoTag from '../../../shared/PendoTag';
import { ChevronLeft, ChevronRight } from '@mui/icons-material';

type NavButtonProps = {
  activeStep: 'claim' | 'loss' | 'summary';
  previousButtonClick: () => void;
  nextButtonClick?: () => void;
  pendClaimButtonClick?: () => void;
  completeButtonClick?: () => void;
  allRequiredFieldsFilled: boolean;
  responseInProgress?: SubmissionState;
};

export const NavButtons = ({
  activeStep,
  previousButtonClick,
  nextButtonClick,
  allRequiredFieldsFilled,
  completeButtonClick,
  pendClaimButtonClick,
  responseInProgress,
}: NavButtonProps) => {
  const { t } = useTranslation();

  if (activeStep === 'claim') {
    return (
      <>
        <div style={{ width: '100%' }}>
          <Divider />
        </div>
        <div style={{ flexDirection: 'row', width: '100%', display: 'flex' }}>
          <Button data-testid='cancel-claim' onClick={previousButtonClick}>
            <strong>{t('cancel')}</strong>
          </Button>
          <Button
            data-testid='next-claim'
            disabled={!allRequiredFieldsFilled}
            onClick={nextButtonClick}
            sx={{ color: allRequiredFieldsFilled ? '#6389a9' : '#b0b0b0' }}
            style={{ marginLeft: 'auto' }}>
            <strong>{t('next')}</strong>
            <ChevronRight htmlColor={allRequiredFieldsFilled ? '#6389a9' : '#b0b0b0'} fontSize='large' />
          </Button>
        </div>
      </>
    );
  }
  if (activeStep === 'loss') {
    return (
      <>
        <div style={{ width: '100%' }}>
          <Divider />
        </div>
        <div style={{ flexDirection: 'row', width: '100%', display: 'flex' }}>
          <Button data-testid='previous-button' onClick={previousButtonClick}>
            <ChevronLeft htmlColor='#6389a9' fontSize='large' />
            <strong>{t('claims.previous')}</strong>
          </Button>
          <Button
            data-testid='next-button'
            disabled={!allRequiredFieldsFilled}
            onClick={nextButtonClick}
            sx={{ color: allRequiredFieldsFilled ? '#6389a9' : '#b0b0b0' }}
            style={{ marginLeft: 'auto' }}>
            <strong>{t('next')}</strong>
            <ChevronRight htmlColor={allRequiredFieldsFilled ? '#6389a9' : '#b0b0b0'} fontSize='large' />
          </Button>
        </div>
      </>
    );
  }
  if (activeStep === 'summary') {
    return (
      <div style={{ flexDirection: 'row', width: '100%', display: 'flex' }}>
        <Button data-testid='cancel-claim' onClick={previousButtonClick}>
          <ChevronLeft htmlColor='#6389a9' fontSize='large' />
          <strong>{t('claims.previous')}</strong>
        </Button>
        <PendoTag tag='pendo-pend-claim'>
          <LoadingButton
            disabled={!allRequiredFieldsFilled || responseInProgress === 'claim'}
            data-testid='pendClaim'
            sx={{ color: '#6389a9' }}
            onClick={pendClaimButtonClick}
            style={{ marginLeft: 'auto', ...(responseInProgress === 'pend' ? { paddingLeft: '2rem' } : {}) }}
            loading={responseInProgress === 'pend'}
            loadingPosition='start'>
            <strong>{t('pendClaim')}</strong>
          </LoadingButton>
        </PendoTag>
        <PendoTag tag='pendo-complete-claim'>
          <LoadingButton
            disabled={!allRequiredFieldsFilled || responseInProgress === 'pend'}
            data-testid='complete-claim'
            sx={{ color: '#6389a9' }}
            onClick={completeButtonClick}
            variant='contained'
            style={{
              color: !allRequiredFieldsFilled || responseInProgress === 'pend' ? '#bdbdbd' : '#fff',
              ...(responseInProgress === 'claim' ? { paddingLeft: '2rem' } : {}),
            }}
            loading={responseInProgress === 'claim'}
            loadingPosition='start'>
            <strong>{t('complete')}</strong>
          </LoadingButton>
        </PendoTag>
      </div>
    );
  } else {
    return null;
  }
};
