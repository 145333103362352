import React, { useCallback, useState } from 'react';
import { Camera, FACING_MODES } from 'react-html5-camera-photo';
import 'react-html5-camera-photo/build/css/index.css';
import { StyledCamera, StyledImagePreview } from '../../../styles/screens';
import { StyledIcon } from '../../../styles/mui';
import { ArrowBack } from '@mui/icons-material';
import { ImageCapturePreview } from './ImageCapturePreview';
import { Dialog } from '@mui/material';
import type { ImageUploadCallback } from '../../../types/conditionTypes/ConditionImage';
import { uniqueId } from 'lodash';

const RESOLUTION = { width: 1080, height: 720 }; // Do not inline, library does not memoize

interface ImageCaptureProps {
  renderIcon: JSX.Element;
  showPreview?: boolean;
  onUpload: ImageUploadCallback;
}

export const ImageCapture = ({ renderIcon, showPreview = false, onUpload }: ImageCaptureProps) => {
  const [currentImage, setCurrentImage] = useState('');
  const [captureImage, setCaptureImage] = useState(false);

  const openImageCapture = () => {
    setCurrentImage('');
    setCaptureImage(true);
  };

  const handleImageCapture = useCallback(async (uri: string) => {
    setCurrentImage(uri);
    setCaptureImage(false);
    if (!showPreview) {
      await upload();
    }
  }, []);

  const upload = async () => {
    onUpload(currentImage, `${uniqueId('image_')}.png`);
    clear();
  };

  const clear = () => {
    setCurrentImage('');
    setCaptureImage(false);
  };

  return (
    <>
      <span onClick={openImageCapture} data-testid='image-capture'>
        {renderIcon}
      </span>
      <Dialog
        style={{ maxHeight: '95vh' }}
        open={(captureImage && currentImage === '') || (currentImage !== '' && showPreview)}
        onClose={clear}
        fullWidth>
        <>
          {captureImage && currentImage === '' && (
            <StyledCamera>
              <StyledIcon>
                <ArrowBack fontSize='large' className='back-button' onClick={clear} data-testid='back-button' />
              </StyledIcon>
              <Camera
                isSilentMode
                idealResolution={RESOLUTION}
                idealFacingMode={FACING_MODES.ENVIRONMENT}
                onTakePhotoAnimationDone={handleImageCapture}
                imageType='png'
              />
            </StyledCamera>
          )}
          {currentImage !== '' && showPreview && (
            <StyledImagePreview>
              <ImageCapturePreview
                image={currentImage}
                handleAccept={upload}
                handleCancel={clear}
                handleRetake={openImageCapture}
              />
            </StyledImagePreview>
          )}
        </>
      </Dialog>
    </>
  );
};
