import { ClaimLevelInformation, claimLevelInformationInitialState } from './claimInformation/ClaimLevelInformation';
import { QuestionInformation } from './claimInformation/QuestionInformation';
import { RentalContract } from './contract/RentalContract';
import { AuthorityDepartment } from './person/AuthorityDepartment';
import { Pedestrian } from './person/Pedestrian';
import { Witness } from './person/Witness';
import { ThirdPartyProperty } from './property/ThirdPartyProperty';
import {
  firstPartyVehicleInformationInitialState,
  FirstPartyVehicleInformation,
} from './vehicle/FirstPartyVehicleInformation';
import { ThirdPartyVehicleInformation } from './vehicle/ThirdPartyVehicleInformation';

export interface ClaimPayload {
  claimLevelInformation: ClaimLevelInformation;
  fireDepartments?: AuthorityDepartment[];
  firstPartyVehicleInformation: FirstPartyVehicleInformation;
  onRent?: boolean;
  pedestrians?: Pedestrian[];
  policeDepartments?: AuthorityDepartment[];
  rentalContract?: RentalContract;
  thirdPartyProperties?: ThirdPartyProperty[];
  thirdPartyVehiclesInformation?: ThirdPartyVehicleInformation[];
  witnesses?: Witness[];
  documentIds?: number[];
  questionsInformation: QuestionInformation[];
  firstPartyMasked?: boolean;
  thirdPartyMasked?: boolean;
  otherPartyMasked?: boolean;
}

export type SubmissionState = 'pend' | 'claim' | 'none';

export const claimPayloadInitialState: ClaimPayload = {
  claimLevelInformation: claimLevelInformationInitialState,
  firstPartyVehicleInformation: firstPartyVehicleInformationInitialState,
  questionsInformation: [],
};
