import React, { useEffect, useState } from 'react';
import { Grid, Skeleton } from '@mui/material';
import EvStationIcon from '@mui/icons-material/EvStation';
import { useAppSelector } from '../../redux-tool-kit/hooks';
import { useTranslation } from 'react-i18next';
import { capitalize } from 'lodash';
import {
  StyledBasicVehicleData,
  StyledLabel,
  StyledRightSideLabel,
  StyledRightSideValues,
  StyledValue,
  StyledVehicleConditionHeader,
  StyledVehicleHeaderRight,
  StyledVehicleMakeHeader,
  StyledWidgetPlaceholder,
} from '../../styles/shared/Vehicle/StyledBasicVehicleData';
import { ConnectedCarIcon } from './ConnectedCarIcon';
import { useVehicleRetrieve } from '../../services/vehicleService/useVehicleRetrieve';
import { useSearchParams } from '../../utils/useURLs';
import { mostSignificant } from '../../utils/urn';
import useMultiRetrieve from '../../hooks/useMultiRetrieve';
import { claimData } from '../../redux-tool-kit/claimSlice';
import useAppParams from '../../hooks/useAppParams';
import useVehicle from '../../hooks/useVehicle';
import { useRentalAgreements } from '../../hooks/useRentalAgreements';
import { failConditionCapture } from '../../utils/navigation';
import { RentalAgreement } from '../../types';
import { store } from '../../redux-tool-kit/store';
import { addSuccessCall } from '../../redux-tool-kit/networkSuccessSlice';
import { MultiRetrieveResponse } from '../../types/apiTypes';

type Territory = 'State' | 'Country';

interface BasicVehicleDataProps {
  labelXsSize?: number;
  dataXsSize?: number;
  hideHeader?: boolean;
  isClaims?: boolean;
}

export const BasicVehicleData = ({
  labelXsSize: _labelXsSize,
  dataXsSize: _dataXsSize,
  hideHeader = false,
  isClaims = false,
}: BasicVehicleDataProps) => {
  const { t } = useTranslation();
  const { vid } = useAppParams();
  const { data: vehicle, isLoading: isVehicleLoading } = useVehicle();
  const claim = useAppSelector(claimData);

  const searchObject = useSearchParams();
  const conditionContext = searchObject.get('conditionContext');

  const vehicleSpecification = vehicle?.vehicleAsset.specification;
  const vehicleJurisdiction = vehicle?.vehicleAsset.registrationPlate.jurisdiction.country;
  const { vid: vehicleUnitNumber } = useAppParams();
  const vehiclePlate = vehicle?.vehicleAsset.registrationPlate?.number;
  const vehiclePlateState = vehicle?.vehicleAsset.registrationPlate?.jurisdiction?.countrySubdivision;
  const vin = vehicle?.vehicleAsset.vin.slice(-8);
  const vehicleColor = vehicle?.vehicleAsset?.color?.exteriorBaseColor;
  const vehicleYear = vehicleSpecification?.year;
  const vehicleMake = vehicleSpecification?.make.name;
  const vehicleModel = vehicleSpecification?.model.name;

  const colorString = vehicleColor as string;
  const vehicleMakeString = vehicleMake as string;
  const vehicleColorFormat = capitalize(colorString);
  const vehicleMakeFormat = capitalize(vehicleMakeString);
  const { data, error, isLoading } = useVehicleRetrieve(vid);

  const { data: rentalAgreements, isLoading: isRentalAgreementsLoading } = useRentalAgreements([
    'CLOSED',
    'CLOSE_PEND',
    'INCOMPLETE',
    'OPEN',
    'CHECKED_IN',
    'VOID',
  ]);
  const allAgreements = rentalAgreements?.flat() ?? [];

  const RaInClaim = claim.rentalContract?.rentalContractNumber || false;
  const RaInSearch = conditionContext ? mostSignificant(conditionContext) : null;
  const RaForProtectionsSearch = RaInClaim ? RaInClaim : RaInSearch;
  const { data: protectionsData, isLoading: isProtectionsLoading } = useMultiRetrieve(RaForProtectionsSearch);
  const [protections, setProtections] = useState<MultiRetrieveResponse | null>(null);
  const raToUseForDisplayName = allAgreements.find(
    (result: RentalAgreement) =>
      mostSignificant(result.rentalAgreement?.rentalAgreement) === claim.rentalContract?.rentalContractNumber
  );

  const displayNameIfContext = () => {
    return !!(allAgreements?.length && claim.onRent && isClaims && conditionContext);
  };

  const displayRaAndProtections = () => {
    return (
      (conditionContext && isClaims) ||
      (!conditionContext && claim.onRent && isClaims && claim.rentalContract?.rentalContractNumber)
    );
  };

  const displayNameIfNoContext = () => {
    return !!(
      allAgreements?.length &&
      claim.onRent &&
      isClaims &&
      !conditionContext &&
      claim &&
      claim.firstPartyVehicleInformation.driver?.personalInformation?.firstName &&
      claim.firstPartyVehicleInformation.driver?.personalInformation?.lastName
    );
  };

  useEffect(() => {
    if (!isProtectionsLoading) {
      setProtections(protectionsData ?? null);
    }
  }, [isProtectionsLoading]);

  const vehicleWidget = () => {
    if (isLoading) {
      return <StyledWidgetPlaceholder />;
    } else if (error) {
      failConditionCapture();
      return null;
    } else {
      store.dispatch(addSuccessCall('vehicleData'));
      const activationStatus = data?.fleetVehicle?.telematics.activationStatus;
      const fuelCategory = data?.fleetVehicle?.vehicleAsset.specification.fuelCategory;
      const fuelCategoryType = fuelCategory?.split(':')[6];

      return (
        <>
          {activationStatus === 'ACTIVATED' ? (
            <ConnectedCarIcon className='connected' data-testid='connected-car-icon' />
          ) : null}
          {fuelCategoryType === 'ELECTRIC' ? <EvStationIcon /> : null}
        </>
      );
    }
  };

  const getIfRegistrationOrLicense = (stateOrCountry: Territory): string => {
    if (vehicleJurisdiction) {
      if (['GBR', 'IRL', 'DEU'].includes(vehicleJurisdiction)) {
        if (stateOrCountry === 'Country') {
          return 'claims.summary.labels.registrationNumber';
        } else {
          return 'claims.summary.labels.registrationState';
        }
      } else {
        if (stateOrCountry === 'Country') {
          return 'claims.licensePlate';
        } else {
          return 'claims.summary.labels.state';
        }
      }
    }
    if (stateOrCountry === 'Country') {
      return 'claims.licensePlate';
    } else {
      return 'claims.summary.labels.licensePlateState';
    }
  };
  if (isVehicleLoading || isRentalAgreementsLoading) {
    return (
      <Skeleton
        style={{
          position: 'relative',
          maxWidth: '100vw',
          marginLeft: '-50vw',
          left: '50%',
          width: '100vw !important',
          height: '50px',
        }}
      />
    );
  } else {
    return (
      <StyledBasicVehicleData>
        <Grid container rowSpacing={2} paddingLeft={isClaims ? '5%' : 0} paddingTop={isClaims ? 1.5 : 0}>
          {hideHeader ? null : (
            <Grid item container xs={12} paddingBottom={1}>
              <StyledVehicleConditionHeader item>{t('condition.capture.title')}</StyledVehicleConditionHeader>
              <Grid item xs={2} />
              {conditionContext && (
                <StyledVehicleHeaderRight item xs={12} sm={6}>
                  {`${t('claims.ra')} ${mostSignificant(conditionContext)}`}
                </StyledVehicleHeaderRight>
              )}
            </Grid>
          )}
          {(displayNameIfContext() || displayNameIfNoContext()) && (
            <strong>
              {`${raToUseForDisplayName?.rentalAgreement.renter.surname}, ${raToUseForDisplayName?.rentalAgreement.renter.givenName}`}
            </strong>
          )}

          {displayRaAndProtections() && (
            <div style={{ flexDirection: 'column', display: 'flex', marginLeft: 'auto' }}></div>
          )}
          <Grid container xs={12} paddingTop={isClaims ? 1 : 0}>
            <StyledVehicleMakeHeader item>
              <b className='bold'>
                {vehicleYear}&nbsp;
                {vehicleMakeFormat}
                &nbsp;
                {vehicleModel}
                ,&nbsp;
              </b>
              {vehicleColorFormat}
              {vehicleWidget()}
            </StyledVehicleMakeHeader>
          </Grid>
          <Grid
            item
            container
            className='unit-vin-container'
            direction='column'
            rowSpacing={1}
            xs={isClaims ? 6 : 12}
            sm={isClaims ? 2 : 3.5}
            marginBottom={isClaims ? '8px' : 0}>
            <Grid item container>
              <StyledLabel item className='label'>
                {t('claims.summary.labels.unit')}
              </StyledLabel>

              <StyledValue item>{vehicleUnitNumber}</StyledValue>
            </Grid>

            <Grid item container>
              <StyledLabel item className='label'>
                {t('claims.summary.labels.vin')}
              </StyledLabel>

              <StyledValue item xs='auto'>
                {vin}
              </StyledValue>
            </Grid>
          </Grid>
          <Grid item container direction='column' rowSpacing={1} xs={isClaims ? 6 : 12} sm={3.5}>
            <Grid item container>
              <StyledLabel item className='label'>
                {t(getIfRegistrationOrLicense('Country'))}
              </StyledLabel>

              <StyledValue item xs='auto'>
                {vehiclePlate}
              </StyledValue>
            </Grid>

            <Grid container>
              <StyledLabel item className='label'>
                {t(getIfRegistrationOrLicense('State'))}
              </StyledLabel>

              <StyledValue item>{vehiclePlateState}</StyledValue>
            </Grid>
          </Grid>
          <Grid item marginLeft={'auto'} xs={12} sm={2} md={4} marginTop={'-1.5em'} paddingLeft={'5px'}>
            {displayRaAndProtections() && (
              <>
                <StyledRightSideLabel item>{`${t('claims.ra')}`}</StyledRightSideLabel>
                <strong>
                  <StyledRightSideValues item>{claim.rentalContract?.rentalContractNumber}</StyledRightSideValues>
                </strong>

                <StyledRightSideLabel paddingTop={1} item>
                  {t('claims.summary.labels.protections')}
                </StyledRightSideLabel>
                {protections ? (
                  protections.protection.length ? (
                    <div style={{ flexDirection: 'row', display: 'flex' }}>
                      {protections.protection.map((pro, index) => {
                        return (
                          <StyledRightSideValues key={pro.id} item>
                            <strong>
                              {`${mostSignificant(pro.type)}${
                                index !== protections.protection.length - 1 ? `, ` : ' '
                              }`}
                            </strong>
                          </StyledRightSideValues>
                        );
                      })}
                    </div>
                  ) : (
                    <strong>{t('none')}</strong>
                  )
                ) : null}
              </>
            )}
          </Grid>
        </Grid>
      </StyledBasicVehicleData>
    );
  }
};
