import React from 'react';
import { useTranslation } from 'react-i18next';
import CloseIcon from '@mui/icons-material/Close';
import { Alert, Box, Collapse, IconButton, Snackbar } from '@mui/material';
import { useAppSelector } from '../../redux-tool-kit/hooks';
import { networkErrorAlertData, showNetworkErrorAlert } from '../../redux-tool-kit/networkErrorAlertSlice';
import { store } from '../../redux-tool-kit/store';

export const NetworkErrorAlert = () => {
  const { t } = useTranslation();
  const alertInfo = useAppSelector(networkErrorAlertData);

  return (
    <Box sx={{ width: '100%' }}>
      <Collapse in={alertInfo.showAlert}>
        <Snackbar open={alertInfo.showAlert} anchorOrigin={{ vertical: 'top', horizontal: 'center' }}>
          <Alert
            sx={{ mb: 2 }}
            severity='error'
            action={
              <IconButton
                aria-label='close'
                color='inherit'
                size='small'
                onClick={() => {
                  store.dispatch(
                    showNetworkErrorAlert({
                      showAlert: false,
                    })
                  );
                }}>
                <CloseIcon fontSize='inherit' />
              </IconButton>
            }>
            {alertInfo.message ? t(`${alertInfo.message}`) : ''}
          </Alert>
        </Snackbar>
      </Collapse>
    </Box>
  );
};
