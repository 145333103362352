import React from 'react';

export const ConnectedCarIcon = (props: any) => {
  return (
    <svg width='15' height='20' viewBox='0 0 15 20' fill='none' xmlns='http://www.w3.org/2000/svg' {...props}>
      <path
        d='M1.66667 11.6667H13.3333L12.0833 7.91667H2.91667L1.66667 11.6667ZM12.0833 15.8333C12.4149 15.8333 12.7328 15.7016 12.9672 15.4672C13.2016 15.2328 13.3333 14.9149 13.3333 14.5833C13.3333 14.2518 13.2016 13.9339 12.9672 13.6995C12.7328 13.465 12.4149 13.3333 12.0833 13.3333C11.7518 13.3333 11.4339 13.465 11.1995 13.6995C10.965 13.9339 10.8333 14.2518 10.8333 14.5833C10.8333 14.9149 10.965 15.2328 11.1995 15.4672C11.4339 15.7016 11.7518 15.8333 12.0833 15.8333ZM2.91667 15.8333C3.24819 15.8333 3.56613 15.7016 3.80055 15.4672C4.03497 15.2328 4.16667 14.9149 4.16667 14.5833C4.16667 14.2518 4.03497 13.9339 3.80055 13.6995C3.56613 13.465 3.24819 13.3333 2.91667 13.3333C2.58515 13.3333 2.2672 13.465 2.03278 13.6995C1.79836 13.9339 1.66667 14.2518 1.66667 14.5833C1.66667 14.9149 1.79836 15.2328 2.03278 15.4672C2.2672 15.7016 2.58515 15.8333 2.91667 15.8333ZM13.2667 7.5L15 12.5V19.1667C15 19.3877 14.9122 19.5996 14.7559 19.7559C14.5996 19.9122 14.3877 20 14.1667 20H13.3333C13.1123 20 12.9004 19.9122 12.7441 19.7559C12.5878 19.5996 12.5 19.3877 12.5 19.1667V18.3333H2.5V19.1667C2.5 19.3877 2.4122 19.5996 2.25592 19.7559C2.09964 19.9122 1.88768 20 1.66667 20H0.833333C0.61232 20 0.400358 19.9122 0.244078 19.7559C0.0877973 19.5996 0 19.3877 0 19.1667V12.5L1.73333 7.5C1.9 7.01667 2.375 6.66667 2.91667 6.66667H12.0833C12.625 6.66667 13.1 7.01667 13.2667 7.5ZM7.5 0C9.26667 0 10.9583 0.716667 12.2083 1.95833L11.025 3.14167C10.0917 2.20833 8.81667 1.66667 7.5 1.66667C6.18333 1.66667 4.90833 2.20833 3.975 3.14167L2.8 1.95833C4.04167 0.716667 5.73333 0 7.5 0ZM7.5 3.33333C8.38333 3.33333 9.225 3.7 9.85 4.31667L8.66667 5.5C8.35833 5.19167 7.94167 5 7.5 5C7.08333 5 6.64167 5.19167 6.33333 5.5L5.15 4.31667C5.775 3.7 6.61667 3.33333 7.5 3.33333Z'
        fill='black'
      />
    </svg>
  );
};
