import React from 'react';
import { useTranslation } from 'react-i18next';
import { InputAdornment, TextField } from '@mui/material';

interface ElectricFuelSelectorProps {
  onChange: (val: number) => any;
  value: number | null | undefined;
  isOptional: boolean;
}

const ElectricFuelSelector = ({ value, onChange, isOptional }: ElectricFuelSelectorProps) => {
  const { t } = useTranslation();
  const isValid = (value == null && isOptional) || (value != null && value >= 0 && value <= 1.0);

  return (
    <TextField
      fullWidth
      label={t('batteryLevel')}
      placeholder={!isOptional ? t('batteryLevelRequired') : ''}
      defaultValue={value != null ? value * 100.0 : ''}
      error={!isValid}
      onChange={(e) => onChange(Number(e.target.value) / 100.0)}
      InputProps={{ endAdornment: <InputAdornment position='start'>%</InputAdornment> }}
    />
  );
};

export default ElectricFuelSelector;
