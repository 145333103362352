import React, { useState, useEffect, useCallback, useRef } from 'react';
import { paintCurrentComponents } from '../../../utils/flatfish/FlatfishUtils';
import { renderToString } from 'react-dom/server';
import { mostSignificant } from '../../../utils/urn';
import { useVehicleSVG } from '../../../services/vehicleService/useVehicleSVG';
import { StyledFlatfishPlaceholder } from '../../../styles/screens/StyledFlatfishPlaceholder';
import { useGetConditions } from '../../../utils/condition/ConditionHelper';

interface VehicleSVGProps {
  imageUrn: String;
  clickHandler?: (e: React.MouseEvent) => void;
  setLocalIndicators?: React.Dispatch<React.SetStateAction<NodeListOf<SVGUseElement>>>;
}

const VehicleSVG: React.FC<VehicleSVGProps> = ({ imageUrn, clickHandler, setLocalIndicators }: VehicleSVGProps) => {
  const vehicleSVGData = useVehicleSVG();
  const [svgData, setSvgData] = useState<Record<string, string>>();
  const [curSvg, setCurSvg] = useState<string>();
  const getConditions = useGetConditions();

  useEffect(() => {
    if (vehicleSVGData.data) {
      setSvgData(vehicleSVGData.data);
    }
  }, [vehicleSVGData]);

  useEffect(() => {
    if (svgData !== null) {
      const carPerspective = mostSignificant(String(imageUrn));
      const svg = carPerspective ? svgData?.[carPerspective] : '';
      setCurSvg(svg);
    }
  }, [svgData]);

  function getDefsHTML() {
    const html = (
      <>
        <g id='indicator'>
          <circle r='10' />
          <circle r='5' fill='white' />
        </g>
        <g id='one'>
          <circle r='10' />
          <text id='one' transform='translate(-4.8, 4.8)' stroke='white'>
            2
          </text>
        </g>
      </>
    );
    return renderToString(html);
  }

  const svgRef = useRef<HTMLDivElement>();
  const svgCallback = useCallback(
    (svgEl: HTMLDivElement) => {
      if (svgEl) {
        svgRef.current = svgEl;
        svgRef.current.firstElementChild?.setAttribute('overflow', 'visible');
        const defsEl = svgEl.querySelector('defs');
        defsEl?.insertAdjacentHTML('afterbegin', getDefsHTML());
        const iconList = svgEl.querySelectorAll('use');
        if (setLocalIndicators) setLocalIndicators(iconList);
        paintCurrentComponents(iconList, getConditions);
      }
    },
    [curSvg]
  );

  return curSvg ? (
    // Once the svg data is retrieved, this div will have its inner
    // HTML replaced with that data to allow for dynamic presentation
    <div
      style={{ width: '100%' }}
      dangerouslySetInnerHTML={{ __html: curSvg! }}
      ref={svgCallback}
      onClick={(e: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
        if (clickHandler) clickHandler(e);
      }}
    />
  ) : (
    <StyledFlatfishPlaceholder variant='rectangular' id='schematic-placeholder' />
  );
};

export default VehicleSVG;
