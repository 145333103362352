import React, { useEffect, useState } from 'react';
import { Divider, Grid, Button, Modal, Box, Dialog, Typography } from '@mui/material';
import DirectionsCarIcon from '@mui/icons-material/DirectionsCar';
import { useTranslation } from 'react-i18next';
import { globalErrorModalData, clearModal } from '../../redux-tool-kit/globalErrorModalSlice';
import { useAppDispatch, useAppSelector } from '../../redux-tool-kit/hooks';
import {
  selectNewConditions,
  initCheckboxes,
  removeNewUnchecked,
  selectMetrics,
} from '../../redux-tool-kit/conditionSlice';
import EquipmentConditionsView from './equipmentCards/EquipmentConditionsView';
import VehicleConditionCapture from './vehicleCondtionCapture/VehicleConditionCapture';
import { BasicVehicleData } from '../../shared/Vehicle/BasicVehicleData';
import VehicleInfo from './VehicleInfo';
import { ExteriorInteriorComponentToggle } from './ExteriorInteriorComponentToggle';
import { ClaimRequiredModal } from './ClaimRequiredModal';
import { setReportErrorModal } from '../../utils/validation/reportError';
import { BasicMetricsPayload, persistMetrics } from '../../services/vehicleService/VehicleMetrics';
import { useAppNavigation } from '../../utils/useURLs';
import { StyledVehicleCondition } from '../../styles/screens/StyledVehicleCondition';
import { BasicVehicleDataCard } from '../../styles/shared/Vehicle/StyledBasicVehicleData';
import { ConditionCardHeader, ConditionPaper } from '../../styles/common';
import VehicleConditionListsWrapper from './conditionLists/VehicleConditionListsWrapper';
import { AddCondition, ViewCondition } from './conditionModal';
import { submitConditionReport } from '../../services/condition/SubmitConditionService';
import useCondition from '../../hooks/useCondition';
import {
  useGetConditions,
  useGetPayloadReadyConditionReport,
  useIsClaimableDamage,
} from '../../utils/condition/ConditionHelper';
import { paintCurrentComponents } from '../../utils/flatfish/FlatfishUtils';
import { completeConditionCapture } from '../../utils/navigation';
import useVehicle from '../../hooks/useVehicle';
import useAppParams from '../../hooks/useAppParams';
import { ExistingClaimModal } from './ExistingClaimsModal';
import { useExistingClaims } from '../../hooks/useExistingClaims';
import { claimData } from '../../redux-tool-kit/claimSlice';
import PendoTag from '../../shared/PendoTag';

interface VehicleConditionProps {
  viewConditionModalOpen?: boolean;
  addConditionModalOpen?: boolean;
  viewOtherConditionModalOpen?: boolean;
}

const VehicleCondition = ({
  viewConditionModalOpen = false,
  addConditionModalOpen = false,
  viewOtherConditionModalOpen = false,
}: VehicleConditionProps) => {
  const { t } = useTranslation();
  const newConditions = useAppSelector(selectNewConditions);
  const getConditions = useGetConditions();
  const claimState = useAppSelector(claimData);
  const { data: vehicle, isLoading: vehicleIsLoading } = useVehicle();
  const { data: existingClaimsData } = useExistingClaims();
  const globalErrModData = useAppSelector(globalErrorModalData);
  const [isExterior, setIsExterior] = useState(true);
  const [isListView, setIsListView] = useState(false);
  const navigation = useAppNavigation();
  const dispatch = useAppDispatch();
  const [openClaimModal, setOpenClaimModal] = useState(false);
  const [softErrors, setSoftErrors] = useState<string[]>([]);
  const [CCAPSaveClicked, setCCAPSaveClicked] = useState(false);
  const [isMetricsValid, setIsMetricsValid] = useState(true);
  const { data: previousConditions, isLoading: isPreviousConditionLoading } = useCondition();
  const getPayloadReadyConditionReport = useGetPayloadReadyConditionReport();
  const { vid } = useAppParams();
  const metrics = useAppSelector(selectMetrics);
  const isClaimableDamage = useIsClaimableDamage();

  const handleCaptureDoneFlow = async () => {
    if (isClaimableDamage()) {
      setOpenClaimModal(true);
    } else {
      completeConditionCapture(
        await submitConditionReport(getPayloadReadyConditionReport(), vid),
        claimState.claimLevelInformation.claimNumber,
        metrics?.fuelPercentage,
        metrics?.odometer.value,
        false
      );
    }
  };

  useEffect(() => {
    if (previousConditions) {
      dispatch(initCheckboxes(previousConditions));
    }
  }, [isPreviousConditionLoading]);

  useEffect(() => {
    paintCurrentComponents(document.querySelectorAll('use'), getConditions);
  }, [previousConditions, newConditions]);

  useEffect(() => {
    if (globalErrModData.softErrorDismiss) {
      handleConditionClick();
    }
  }, [globalErrModData.softErrorDismiss]);

  const handleConditionClick = async () => {
    setCCAPSaveClicked(true);
    dispatch(removeNewUnchecked());
    try {
      if (softErrors.length > 0 && !globalErrModData.softErrorDismiss) {
        setReportErrorModal({ errors: softErrors }, true);
      } else {
        dispatch(clearModal());
        await persistMetrics(vid, metrics as BasicMetricsPayload);
        await handleCaptureDoneFlow();
      }
    } finally {
      setCCAPSaveClicked(false);
    }
  };

  return (
    <StyledVehicleCondition>
      {!!vehicle ? (
        <>
          <BasicVehicleDataCard data-testid='basic-vehicle-data-card'>
            <BasicVehicleData />
          </BasicVehicleDataCard>
          <VehicleInfo setSoftErrors={setSoftErrors} setIsValid={setIsMetricsValid} />
          <ConditionPaper elevation={15}>
            <Grid container rowSpacing={2}>
              <Grid item xs={12}>
                <ConditionCardHeader>{t('reviewAndApproveEquipmentAndCondition')}</ConditionCardHeader>
                <EquipmentConditionsView />
              </Grid>
              <Grid item xs={12}>
                <Divider />
              </Grid>
              <Grid item xs={12} data-testid='interior-exterior-menu' style={{ minWidth: '103%' }}>
                <Grid container direction='row' style={{ marginTop: 40 }} justifyContent='flex-end'>
                  <Typography className='condition-heading'>{t('condition.view.labels.condition')}</Typography>
                  <ExteriorInteriorComponentToggle
                    isExterior={isExterior}
                    isListView={isListView}
                    setIsExterior={setIsExterior}
                    setIsListView={setIsListView}
                  />
                </Grid>
                <Grid item xs={12} marginTop={2} data-testid='vehicle-condition-capture'>
                  <VehicleConditionCapture listOverride={isListView} isExterior={isExterior} />
                </Grid>
              </Grid>
            </Grid>
            <VehicleConditionListsWrapper />
          </ConditionPaper>
          <PendoTag tag='pendo-save-and-confirm-condition'>
            <Button
              variant='contained'
              data-testid='complete-condition-capture'
              disabled={CCAPSaveClicked || !isMetricsValid}
              sx={{ float: 'right' }}
              startIcon={<DirectionsCarIcon />}
              onClick={async () => await handleConditionClick()}>
              {t('condition.capture.saveAndConfirmCondition')}
            </Button>
          </PendoTag>
          {existingClaimsData && existingClaimsData.length ? (
            <ExistingClaimModal
              existingClaimsData={existingClaimsData}
              open={openClaimModal}
              onCancel={() => setOpenClaimModal(false)}
            />
          ) : (
            <ClaimRequiredModal open={openClaimModal} onCancel={() => setOpenClaimModal(false)} />
          )}
        </>
      ) : (
        <div>
          <Modal open={!vehicle && !vehicleIsLoading}>
            <div
              style={{
                padding: '2.5rem',
                background: '#fff',
                display: 'inline-block',
                position: 'absolute',
                transform: 'translate(-50%, -50%)',
                top: '50%',
                left: '50%',
              }}
              role='dialog'>
              <h2 data-testid='error'>{t('errors.errorAdmin')}</h2>
              <div data-testid='adminReturnLink'>
                <button data-testid='return-button' onClick={() => navigation.reset()}>
                  {t('conditionCapScr.errReturnBtn')}
                </button>
              </div>
            </div>
          </Modal>
        </div>
      )}
      <VehicleConditionModal open={viewConditionModalOpen}>
        <Box className='addCondition-modal-container'>
          <ViewCondition />
        </Box>
      </VehicleConditionModal>
      <VehicleConditionModal open={addConditionModalOpen}>
        <Box className='addCondition-modal-container'>
          <AddCondition />
        </Box>
      </VehicleConditionModal>
      <VehicleConditionModal open={viewOtherConditionModalOpen}>
        <Box>
          <AddCondition />
        </Box>
      </VehicleConditionModal>
    </StyledVehicleCondition>
  );
};

interface VehicleConditionModalProps {
  open: boolean;
  children: React.ReactNode;
}

const VehicleConditionModal = ({ open, children }: VehicleConditionModalProps) => (
  <Dialog open={open} scroll='body' maxWidth='md' fullWidth>
    {children}
  </Dialog>
);

export default VehicleCondition;
