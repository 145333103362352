import React from 'react';
import AddIcon from '@mui/icons-material/Add';
import { Button, Grid } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { SingleFlatFish } from '../../../shared/images/Flatfish/SingleFlatFish';
import { useAppNavigation } from '../../../utils/useURLs';
import { useVehicleSVG } from '../../../services/vehicleService/useVehicleSVG';
import { StyledFlatfishPlaceholder } from '../../../styles/screens/StyledFlatfishPlaceholder';
import { StyledSchematicContainer } from '../../../styles/screens/StyledSchematicContainer';
import { getConfig } from '../../../utils/config';
import useVehicle from '../../../hooks/useVehicle';

const makeDirUrn = (dir: string) =>
  `urn:com.ehi:${getConfig().environment}:reference:vehicle:condition:position:${dir}`;

const ConditionCaptureListView = () => {
  const { t } = useTranslation();
  const navigation = useAppNavigation();
  const { isLoading } = useVehicleSVG();
  const { data: vehicle, isLoading: isVehicleLoading } = useVehicle();
  const sippCode = vehicle?.vehicleAsset.specification.sippCode;

  return (
    <StyledSchematicContainer data-testid='condition-capture'>
      {isLoading ? (
        <div id='skeleton-holder'>
          <StyledFlatfishPlaceholder variant='rectangular' id='schematic-placeholder' />
        </div>
      ) : (
        <Grid
          container
          paddingLeft={1}
          paddingRight={1}
          paddingBottom={0}
          rowSpacing={0}
          columnSpacing={0}
          data-testid={isVehicleLoading || !sippCode ? 'condition-capture' : `condition-capture-${sippCode}`}>
          <Grid item xs={12} md={6}>
            <SingleFlatFish imageUrn={makeDirUrn('LEFT')} addButton />
          </Grid>
          <Grid item xs={6} md={3}>
            <SingleFlatFish imageUrn={makeDirUrn('FRONT')} addButton />
          </Grid>
          <Grid item xs={6} md={3}>
            <SingleFlatFish imageUrn={makeDirUrn('BACK')} addButton />
          </Grid>
          <Grid item xs={12} md={6}>
            <SingleFlatFish imageUrn={makeDirUrn('RIGHT')} addButton />
          </Grid>
          <Grid item xs={12} md={6}>
            <SingleFlatFish imageUrn={makeDirUrn('TOP')} addButton />
          </Grid>
          <Grid container direction='row' justifyContent='flex-end'>
            <Grid item>
              <Button
                variant='text'
                className='active'
                startIcon={<AddIcon />}
                onClick={() => navigation.push('/add-other/NONE')}>
                {t('condition.capture.addOtherCondition')}
              </Button>
            </Grid>
          </Grid>
        </Grid>
      )}
    </StyledSchematicContainer>
  );
};

export default ConditionCaptureListView;
