import { getConfig } from '../../utils/config';

export const defaultActivity = `urn:com.ehi:${
  getConfig().environment
}:reference:vehicle:condition:activityType:CONDITION_REPORT`;
export interface ConditionSource {
  locationId: string;
  activity: string;
  author: string;
  source?: string;
}

export const conditionSourceInitialState: ConditionSource = {
  locationId: '',
  author: '',
  activity: defaultActivity,
};
