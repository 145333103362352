import styled from 'styled-components';

export const StyledClaimPrintEmail = styled.span`
  background-color: white;
  border-radius: 5px;
  color: black;
  .bold {
    font-weight: bold;
  }
  .MuiFormControlLabel-label {
    font-weight: bold;
  }
  .header-title-container {
    margin-top: 10px;
  }
  .collapse {
    width: 400px;
  }
`;
