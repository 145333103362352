import styled from 'styled-components';
import { InputAdornment } from '@mui/material';

export const StyledValidationTextField = styled.div`
  text-align: right;
`;

export const MultilineInputAdornment = styled(InputAdornment)`
  position: absolute;
  bottom: 1em;
  right: 0;
`;
