import React from 'react';
import { useTranslation } from 'react-i18next';
import { Button, Checkbox, Grid } from '@mui/material';
import { useAppDispatch, useAppSelector } from '../../../redux-tool-kit/hooks';
import { useAppNavigation } from '../../../utils/useURLs';
import { selectNewConditions, toggleCheckbox } from '../../../redux-tool-kit/conditionSlice';
import { getFlatfishViewFromUrl, useGetConditionTypeName } from '../../../utils/condition/ConditionHelper';
import { getCheckBox } from '../../../utils/condition/ConditionListCheckHelper';
import { setActiveCondition } from '../../../redux-tool-kit/activeConditionSlice';
import { useFindLocationView } from '../../../services/vehicleService/useVehicleSVG';
import { mostSignificant } from '../../../utils/urn';
import { CommonCondition, conditionInitialState } from '../../../types/conditionTypes/Condition';
import { useComponentMap } from '../../../hooks/useComponent';
import PendoTag from '../../../shared/PendoTag';

interface VehicleConditionsListItemProps {
  condition: CommonCondition;
  isModal: boolean;
}

const VehicleConditionsListItem = ({ condition, isModal }: VehicleConditionsListItemProps) => {
  const { t } = useTranslation();

  const navigation = useAppNavigation();
  const newConditions = useAppSelector(selectNewConditions);
  const componentMap = useComponentMap();
  const check = getCheckBox(condition.urn);
  const dispatch = useAppDispatch();
  const findLocationView = useFindLocationView();
  const getConditionTypeName = useGetConditionTypeName();

  const conditionCommentCheck = condition.comment !== undefined && condition.comment.length > 1;

  function handleCheck(event: React.ChangeEvent<HTMLInputElement>) {
    dispatch(toggleCheckbox(event.target.id));
  }

  function onViewSelect(condition: CommonCondition) {
    dispatch(setActiveCondition({ ...conditionInitialState, ...condition }));
    navigation.push(`/capture/view/${findLocationView(mostSignificant(condition.component) ?? '')}`);
  }

  function getBackground() {
    if (Object.values(newConditions).includes(condition)) {
      return condition.isClaimable ? 'rgb(255,161,0,0.3)' : 'rgb(99,34,137,0.3)';
    }
  }

  return (
    <div className='damages-list-item-container' style={{ backgroundColor: getBackground() }}>
      <Grid justifyContent='center' container>
        {!isModal && (
          <Grid item xs={1.5} md={1} alignSelf='center' data-testid='damage-icon'>
            <Checkbox
              checked={check?.checked || false}
              onChange={handleCheck}
              data-testid={`${condition.urn}-checkbox`}
              id={condition.urn}
            />
          </Grid>
        )}
        <Grid item xs={4} alignSelf='center' className='bold' data-testid='component-name'>
          <span style={{ alignSelf: 'center' }}>{componentMap[condition.component]}</span>
        </Grid>
        <Grid item xs={4} md={5} alignSelf='center' data-testid='condition-name'>
          {conditionCommentCheck ? (
            <span>
              <span className='bold'>{`${getConditionTypeName(condition.conditionType)}: `}</span>
              <span>{`${condition?.comment?.slice(0, 15)} ...`}</span>
            </span>
          ) : (
            <span>
              <span className='bold'>{`${getConditionTypeName(condition.conditionType)}`}</span>
            </span>
          )}
        </Grid>

        <Grid item xs={1} textAlign='right' justifySelf='flex-end' alignSelf='center'>
          <PendoTag tag={`pendo-view-condition-${getFlatfishViewFromUrl()}`}>
            <Button
              sx={{ padding: '0px', alignSelf: 'flex-end', margin: '0px', minWidth: '0px' }}
              variant='text'
              data-testid='view-button'
              onClick={() => onViewSelect(condition)}>
              {t('view')}
            </Button>
          </PendoTag>
        </Grid>
      </Grid>
    </div>
  );
};

export default VehicleConditionsListItem;
