import React from 'react';
import { parseISO } from 'date-fns';
import { Grid, TextField } from '@mui/material';
import { Question } from '../Question';
import { YesNoResponse } from '../YesNoResponse';
import { useTranslation } from 'react-i18next';
import {
  addClaimQuestion,
  addClaimQuestionDefault,
  claimData as claimState,
  makeRenterDriver,
  makeRenterNotDriver,
  selectDriverPersonalInformation,
  selectInsuranceInfo,
  setClaim,
  setDriverInformation,
  setInsuranceInformation,
} from '../../../../redux-tool-kit/claimSlice';
import ConditionDatePicker from '../../../../shared/DatePicker/ConditionDatePicker';
import { useAppDispatch, useAppSelector } from '../../../../redux-tool-kit/hooks';
import {
  getQuestionIntegerAnswer,
  getQuestionYesNoAnswer,
  isZeroInfoClaim,
} from '../../../../utils/claims/ClaimHelpers';
import { QuestionType } from '../../../../types/enums';
import { QuestionsHeader } from '../../shared/QuestionsHeaders';
import { QuantityResponse } from '../QuantityResponse';
import DocumentUploader from '../DocumentUploader';
import { YesNo } from '../../../../types/util/UtilTypes';
import moment from 'moment';

export const DxQuestions = () => {
  const claimData = useAppSelector(claimState);
  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  const driverPersonalInfo = useAppSelector(selectDriverPersonalInformation);
  const insuranceInfo = useAppSelector(selectInsuranceInfo);

  const showWitnessQuestion = () => {
    if (getQuestionYesNoAnswer(claimData.questionsInformation, QuestionType.OTHER_VEHICLE_INVOLVED) === 'yes') {
      return getQuestionYesNoAnswer(claimData.questionsInformation, QuestionType.THIRD_PARTY_HAS_INSURANCE) !== '';
    } else {
      return getQuestionYesNoAnswer(claimData.questionsInformation, QuestionType.OTHER_VEHICLE_INVOLVED) === 'no';
    }
  };

  return (
    <Grid container item sx={{ pt: '0px !important' }} data-testid='dx-questions'>
      <QuestionsHeader />
      <Grid container sx={{ marginBottom: '3%' }}>
        <Question>{`${t('claims.renterPresent')}*`}</Question>
        <YesNoResponse
          change={(e: React.MouseEvent<HTMLElement, MouseEvent>, value: YesNo): void => {
            dispatch(
              addClaimQuestion({
                answerIndicator: value === 'yes',
                questionTypeId: QuestionType.RENTER_PRESENT,
              })
            );
          }}
          value={getQuestionYesNoAnswer(claimData.questionsInformation, QuestionType.RENTER_PRESENT)}
        />
      </Grid>
      {getQuestionYesNoAnswer(claimData.questionsInformation, QuestionType.RENTER_PRESENT) !== '' && (
        <Grid container sx={{ marginBottom: '3%' }}>
          <Question>{`${t('claims.howDamageOccured')}*`}</Question>
          <YesNoResponse
            change={(e: React.MouseEvent<HTMLElement, MouseEvent>, value: YesNo): void => {
              dispatch(
                addClaimQuestion({
                  answerIndicator: value === 'yes',
                  questionTypeId: QuestionType.RENTER_KNOWN_DAMAGE,
                })
              );
            }}
            value={getQuestionYesNoAnswer(claimData.questionsInformation, QuestionType.RENTER_KNOWN_DAMAGE)}
          />
        </Grid>
      )}

      {getQuestionYesNoAnswer(claimData.questionsInformation, QuestionType.RENTER_KNOWN_DAMAGE) !== '' && (
        <Grid container sx={{ marginBottom: '3%' }}>
          <Question>{`${t('claims.renterOrDriverInsurance')}*`}</Question>
          <YesNoResponse
            change={(e: React.MouseEvent<HTMLElement, MouseEvent>, value: YesNo): void => {
              dispatch(
                addClaimQuestion({
                  answerIndicator: value === 'yes',
                  questionTypeId: QuestionType.DRIVER_INSURANCE_INFO,
                })
              );
              if (value === 'no') {
                dispatch(setInsuranceInformation());
              }
            }}
            value={getQuestionYesNoAnswer(
              claimData.questionsInformation,
              QuestionType.DRIVER_INSURANCE_INFO
            )}></YesNoResponse>
        </Grid>
      )}

      {getQuestionYesNoAnswer(claimData.questionsInformation, QuestionType.DRIVER_INSURANCE_INFO) === 'yes' && (
        <Grid item container sx={{ p: 0 }} xs={12} justifyContent='center'>
          <Grid
            data-testid='1stPartyForm'
            container
            sx={{ backgroundColor: 'rgba(245,245,245,1)', marginBottom: '3%', paddingLeft: '2rem' }}
            justifyContent='center'>
            <Grid container sx={{ width: '90%' }}>
              <Grid item xs={12} sx={{ marginBottom: '3%' }}>
                <p>
                  <strong style={{ color: 'rgba(0,0,0,0.87)' }}>{`${t('claims.firstInsuranceLabel')}`}</strong>
                </p>
              </Grid>
              <Grid item xs={6}>
                <TextField
                  data-testid='companyNameTextField'
                  sx={{ width: '90%' }}
                  value={insuranceInfo?.companyName ?? ''}
                  onChange={(e: React.ChangeEvent<HTMLInputElement>): void => {
                    dispatch(
                      setInsuranceInformation({
                        ...insuranceInfo,
                        companyName: e.target.value,
                      })
                    );
                  }}
                  label={t('claims.labels.companyName')}
                  variant='outlined'
                />
              </Grid>
              <Grid item xs={6}>
                <TextField
                  data-testid='policyNumberTextField'
                  sx={{ width: '90%' }}
                  value={insuranceInfo?.policyNumber ?? ''}
                  onChange={(e: React.ChangeEvent<HTMLInputElement>): void => {
                    dispatch(
                      setInsuranceInformation({
                        ...insuranceInfo,
                        policyNumber: e.target.value,
                      })
                    );
                  }}
                  label={t('claims.labels.policyNumber')}
                  variant='outlined'
                />
              </Grid>
              <DocumentUploader carouselHeader={t('claims.claimInformationDocuments')} />
            </Grid>
          </Grid>
        </Grid>
      )}

      {!isZeroInfoClaim() &&
        getQuestionYesNoAnswer(claimData.questionsInformation, QuestionType.DRIVER_INSURANCE_INFO) !== '' && (
          <Grid container sx={{ marginBottom: '3%' }}>
            <Question>{`${t('claims.wasDriverRenter')}*`}</Question>
            <YesNoResponse
              change={(e: React.MouseEvent<HTMLElement, MouseEvent>, value: YesNo): void => {
                dispatch(
                  addClaimQuestion({
                    answerIndicator: value === 'yes',
                    questionTypeId: QuestionType.DRIVER_IS_RENTER,
                  })
                );
                if (value === 'no') {
                  dispatch(makeRenterNotDriver());
                } else {
                  dispatch(makeRenterDriver());
                }
                dispatch(
                  addClaimQuestionDefault({
                    questionTypeId: QuestionType.PASSENGERS_IN_OUR_VEHICLE,
                    answerInteger: 0,
                  })
                );
              }}
              value={getQuestionYesNoAnswer(
                claimData.questionsInformation,
                QuestionType.DRIVER_IS_RENTER
              )}></YesNoResponse>
          </Grid>
        )}
      {!isZeroInfoClaim() &&
        getQuestionYesNoAnswer(claimData.questionsInformation, QuestionType.DRIVER_IS_RENTER) === 'no' && (
          <Grid container justifyContent='center' xs={12} sx={{ p: 0, marginBottom: '5%' }}>
            <Grid item justifyContent='center' xs={12} sm={6} sx={{ pl: 2, pt: 1, marginBottom: '3%' }}>
              <TextField
                inputProps={{ 'data-testid': 'claims.labels.driverFirstName' }}
                sx={{ marginBottom: '3%' }}
                fullWidth
                placeholder={t('claims.labels.driverFirstName')}
                variant='standard'
                value={claimData.firstPartyVehicleInformation?.driver?.personalInformation?.firstName ?? ''}
                onChange={(e: React.ChangeEvent<HTMLInputElement>): void => {
                  dispatch(
                    setDriverInformation({
                      ...claimData.firstPartyVehicleInformation?.driver?.personalInformation,
                      firstName: e.target.value,
                    })
                  );
                }}
              />
              <TextField
                inputProps={{ 'data-testid': 'claims.labels.driverLastName' }}
                placeholder={`${t('claims.labels.driverLastName')}*`}
                fullWidth
                sx={{ mt: 1, marginBottom: '10%' }}
                variant='standard'
                value={claimData.firstPartyVehicleInformation?.driver?.personalInformation?.lastName ?? ''}
                onChange={(e: React.ChangeEvent<HTMLInputElement>): void => {
                  dispatch(
                    setDriverInformation({
                      ...claimData.firstPartyVehicleInformation?.driver?.personalInformation,
                      lastName: e.target.value,
                    })
                  );
                }}
              />
              <ConditionDatePicker
                title={'claims.labels.driverDateOfBirth'}
                fullWidth
                onChange={(date: Date): void => {
                  dispatch(
                    setDriverInformation({
                      ...claimData.firstPartyVehicleInformation?.driver?.personalInformation,
                      dateOfBirth: moment(date).format('YYYY-MM-DD'),
                    })
                  );
                }}
                value={driverPersonalInfo?.dateOfBirth ? parseISO(driverPersonalInfo.dateOfBirth) : undefined}
              />
            </Grid>
          </Grid>
        )}
      {getQuestionYesNoAnswer(claimData.questionsInformation, QuestionType.DRIVER_IS_RENTER) !== '' && (
        <Grid container sx={{ marginBottom: '3%' }}>
          <Question>{t('claims.offRentPassenger')}</Question>
          <QuantityResponse
            onChange={(e): void => {
              const answerInteger = parseInt(e.target.value);
              if (!Number.isNaN(answerInteger)) {
                dispatch(
                  addClaimQuestion({
                    questionTypeId: QuestionType.PASSENGERS_IN_OUR_VEHICLE,
                    answerInteger,
                  })
                );
              }
            }}
            value={getQuestionIntegerAnswer(claimData.questionsInformation, QuestionType.PASSENGERS_IN_OUR_VEHICLE)}
          />
        </Grid>
      )}
      {getQuestionYesNoAnswer(claimData.questionsInformation, QuestionType.DRIVER_IS_RENTER) !== '' && (
        <Grid container sx={{ marginBottom: '3%' }}>
          <Question>{`${t('claims.otherVehicleInvolved')}*`}</Question>
          <YesNoResponse
            change={(e: React.MouseEvent<HTMLElement, MouseEvent>, value: YesNo): void => {
              dispatch(
                addClaimQuestion({
                  questionTypeId: QuestionType.OTHER_VEHICLE_INVOLVED,
                  answerIndicator: value === 'yes',
                })
              );
            }}
            value={getQuestionYesNoAnswer(claimData.questionsInformation, QuestionType.OTHER_VEHICLE_INVOLVED)}
          />
        </Grid>
      )}

      {getQuestionYesNoAnswer(claimData.questionsInformation, QuestionType.OTHER_VEHICLE_INVOLVED) === 'yes' && (
        <Grid container sx={{ marginBottom: '3%' }}>
          <Question>{`${t('claims.otherInsurance')}*`}</Question>
          <YesNoResponse
            change={(e: React.MouseEvent<HTMLElement, MouseEvent>, value: YesNo): void => {
              dispatch(
                addClaimQuestion({
                  questionTypeId: QuestionType.THIRD_PARTY_HAS_INSURANCE,
                  answerIndicator: value === 'yes',
                })
              );
              dispatch(
                addClaimQuestionDefault({
                  questionTypeId: QuestionType.PASSENGERS_IN_OTHER_VEHICLE,
                  answerInteger: 0,
                })
              );
              dispatch(
                setClaim({
                  thirdPartyVehiclesInformation: [
                    {
                      insurance: {
                        companyName: '',
                        policyNumber: '',
                      },
                    },
                  ],
                })
              );
            }}
            value={getQuestionYesNoAnswer(claimData.questionsInformation, QuestionType.THIRD_PARTY_HAS_INSURANCE)}
          />
        </Grid>
      )}
      {getQuestionYesNoAnswer(claimData.questionsInformation, QuestionType.THIRD_PARTY_HAS_INSURANCE) === 'yes' &&
        getQuestionYesNoAnswer(claimData.questionsInformation, QuestionType.OTHER_VEHICLE_INVOLVED) === 'yes' && (
          <Grid item container sx={{ p: 0, marginBottom: '3%' }} xs={12} justifyContent='center'>
            <Grid
              data-testid='3rdPartyForm'
              container
              sx={{ backgroundColor: 'rgba(245,245,245,1)', marginBottom: '3%', paddingLeft: '2rem' }}
              justifyContent='center'>
              <Grid container sx={{ width: '90%' }}>
                <Grid item xs={12} sx={{ marginBottom: '3%' }}>
                  <p>
                    <strong style={{ color: 'rgba(0,0,0,0.87)' }}>{`${t('claims.thirdInsuranceLabel')}`}</strong>
                  </p>
                </Grid>
                <Grid item xs={6}>
                  <TextField
                    inputProps={{ 'data-testid': 'companyNameTextField' }}
                    sx={{ width: '90%' }}
                    value={claimData.thirdPartyVehiclesInformation![0]?.insurance?.companyName}
                    onChange={(e: React.ChangeEvent<HTMLInputElement>): void => {
                      dispatch(
                        setClaim({
                          thirdPartyVehiclesInformation: [
                            {
                              insurance: {
                                ...claimData.thirdPartyVehiclesInformation![0]?.insurance,
                                companyName: e.target.value,
                              },
                            },
                          ],
                        })
                      );
                    }}
                    label={t('claims.labels.companyName')}
                    variant='outlined'
                  />
                </Grid>
                <Grid item xs={6}>
                  <TextField
                    inputProps={{ 'data-testid': 'policyNumberTextField' }}
                    sx={{ width: '90%' }}
                    value={claimData.thirdPartyVehiclesInformation![0]?.insurance?.policyNumber}
                    onChange={(e: React.ChangeEvent<HTMLInputElement>): void => {
                      dispatch(
                        setClaim({
                          thirdPartyVehiclesInformation: [
                            {
                              insurance: {
                                ...claimData.thirdPartyVehiclesInformation![0]?.insurance,
                                policyNumber: e.target.value,
                              },
                            },
                          ],
                        })
                      );
                    }}
                    label={t('claims.labels.policyNumber')}
                    variant='outlined'
                  />
                </Grid>
                <DocumentUploader carouselHeader={t('claims.claimInformationDocuments')} />
              </Grid>
            </Grid>
          </Grid>
        )}

      {getQuestionYesNoAnswer(claimData.questionsInformation, QuestionType.OTHER_VEHICLE_INVOLVED) === 'yes' &&
        getQuestionYesNoAnswer(claimData.questionsInformation, QuestionType.THIRD_PARTY_HAS_INSURANCE) !== '' && (
          <Grid container sx={{ marginBottom: '3%' }}>
            <Question>{t('claims.passengersOtherVehicle')}</Question>
            <QuantityResponse
              onChange={(e): void => {
                const answerInteger = parseInt(e.target.value);
                if (!Number.isNaN(answerInteger)) {
                  dispatch(
                    addClaimQuestion({
                      questionTypeId: QuestionType.PASSENGERS_IN_OTHER_VEHICLE,
                      answerInteger,
                    })
                  );
                }
              }}
              value={getQuestionIntegerAnswer(claimData.questionsInformation, QuestionType.PASSENGERS_IN_OTHER_VEHICLE)}
            />
          </Grid>
        )}
      {showWitnessQuestion() && (
        <Grid container sx={{ marginBottom: '3%' }}>
          <Question>{`${t('claims.otherPeopleInvolved')}*`}</Question>
          <YesNoResponse
            change={(e: React.MouseEvent<HTMLElement, MouseEvent>, value: YesNo): void => {
              dispatch(setClaim({ witnesses: [], pedestrians: [] }));
              dispatch(
                addClaimQuestion({
                  questionTypeId: QuestionType.WITNESS_PEDESTRIAN_INVOLVED,
                  answerIndicator: value === 'yes',
                })
              );
            }}
            value={getQuestionYesNoAnswer(claimData.questionsInformation, QuestionType.WITNESS_PEDESTRIAN_INVOLVED)}
          />
        </Grid>
      )}
      {getQuestionYesNoAnswer(claimData.questionsInformation, QuestionType.WITNESS_PEDESTRIAN_INVOLVED) !== '' && (
        <Grid container sx={{ marginBottom: '3%' }}>
          <Question>{`${t('claims.anyInjuries')}*`}</Question>
          <YesNoResponse
            change={(e: React.MouseEvent<HTMLElement, MouseEvent>, value: YesNo): void => {
              dispatch(addClaimQuestion({ questionTypeId: QuestionType.INJURIES, answerIndicator: value === 'yes' }));
            }}
            value={getQuestionYesNoAnswer(claimData.questionsInformation, QuestionType.INJURIES)}
          />
        </Grid>
      )}

      {getQuestionYesNoAnswer(claimData.questionsInformation, QuestionType.DEDUCTIBLE_MATRIX_PRICING) === 'yes' && (
        <Grid item container sx={{ p: 0 }} xs={12} justifyContent='center'>
          <Grid item sx={{ pl: 2, pt: 1 }} xs={12} sm={6}>
            <TextField
              fullWidth
              label={t('claims.labels.deductibleAmount')}
              InputProps={{ 'aria-label': t('claims.labels.deductibleAmount') }}
              variant='standard'
              type='number'
              value={claimData.rentalContract?.deductibleAmountOwed ?? ''}
              onChange={(e: React.ChangeEvent<HTMLInputElement>): void => {
                dispatch(
                  setClaim({
                    rentalContract: {
                      deductibleAmountOwed: e.target.value ? parseFloat(e.target.value) : undefined,
                    },
                  })
                );
              }}
            />
          </Grid>
        </Grid>
      )}
    </Grid>
  );
};
