import { ConditionImage } from '../types';
import { VIS_THUMB_RESOLUTION } from '../shared/constants';

const epochRe = /\/\/.*\/[^_]*_(?<epoch>\d+)/;
export const visTimestampToEpoch = (img: ConditionImage) => {
  const url = img.formattedImages[VIS_THUMB_RESOLUTION].url;
  const epoch = parseInt(epochRe.exec(url)!.groups!.epoch);
  let epochTime = new Date(epoch * 1000);
  // Give VIS 2k years to fix
  if (epochTime.getFullYear() > 4000) {
    epochTime = new Date(epoch);
  }
  return epochTime;
};
