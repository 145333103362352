import React from 'react';
import { UserState } from '../../types';
import { authContext } from './index';

interface PAProps {
  userData: UserState;
  children?: React.ReactNode;
}

const ProvideAuth: React.FC<PAProps> = ({ userData, children }) => {
  const auth = userData;
  return <authContext.Provider value={auth}>{children}</authContext.Provider>;
};

export default ProvideAuth;
