import styled from 'styled-components';

export const StyledSummarySection = styled.span`
  .title-text {
    margin-top: 10px;
    margin-bottom: 10px;
    margin-left: 8px;
    font-weight: bold;
  }
  .title-section {
    color: white;
  }
  .bold {
    font-weight: bold;
  }
`;
