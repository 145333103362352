import React from 'react';
import styled from 'styled-components';

interface StyledRentalAgreementSelectProps {
  children: React.ReactNode;
}

export const StyledRentalAgreementSelect = styled.div<StyledRentalAgreementSelectProps>`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 0px;
  isolation: isolate;
  max-width: 561px;
  min-width: 340px;
  background: #fff;
  transform: translate(-50%, -50%);
  position: absolute;
  top: 40%;
  left: 49%;
  box-shadow: 0px 24px 38px rgba(0, 0, 0, 0.14), 0px 9px 46px rgba(0, 0, 0, 0.12), 0px 11px 15px rgba(0, 0, 0, 0.2);
  border-radius: 4px;
  min-height: 330px;

  div#rental-agreement-select-header {
    font-style: normal;
    font-weight: 500;
    font-size: 20px;
    line-height: 24px;
    letter-spacing: 0.15px;
    width: inherit;
    padding: 20px 20px 20px 30px;
  }

  div#ras-subhead {
    padding: 0px 30px 30px;
  }

  #rental-agreement-radio-group {
    width: calc(100% - 20px);
    margin: 0 10px 40px;
    max-height: 400px;
    overflow: auto;

    #table-selection {
      padding: 0 20px 0 30px;

      #table-headers {
        color: #a0a0a0;
        font-size: 0.8rem;
      }

      .divider {
        border-bottom: 1px solid #e6e6e6;
        padding-left: 0;
      }

      .divider:last-of-type {
        border-bottom: none;
      }

      .rental-agreement-radios {
        padding-left: 0;
      }
    }
  }

  #rental-agreement-loading,
  #none-found,
  #network-error {
    position: relative;
    height: 215px;
    width: 100%;

    #spinner {
      position: absolute;
      top: calc(50% - 20px);
      left: calc(50% - 20px);
    }

    #msg {
      width: 60%;
      position: absolute;
      top: calc(50% - 30px);
      left: 50%;
      transform: translateX(-50%);
    }
  }

  div#close-button {
    padding: 0 5px 5px 0;
  }
`;
