import styled from 'styled-components';
import { FormLabel, Paper } from '@mui/material';

export const ConditionPaper = styled(Paper)`
  padding: 15px;
  margin: 10px;
  padding-bottom: 30px;
  .fill {
    width: 130px;
  }
`;

export const BasicInfoWrapper = styled(Paper)`
  padding: 15px;

  .fill {
    width: 130px;
  }
  position: relative;
  max-width: 100vw;
  margin-left: -50vw;
  left: 50%;
  width: 100vw !important;
`;

export const ConditionCardHeader = styled.div`
  font-weight: bold;
  font-size: 20px;
  letter-spacing: 0.15px;
  margin-top: 15px;
`;

export const ConditionCardSubHeader = styled.div`
  color: #7f7f7f;
  font-size: 10pt;
`;

export const BlackFormLabel = styled(FormLabel)`
  color: #111111;
`;
