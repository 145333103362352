import useSWRImmutable from 'swr/immutable';

import useAppParams from './useAppParams';
import { useAppSelector } from '../redux-tool-kit/hooks';
import { getConfig } from '../utils/config';
import { edgeGetFetcher, edgePostFetcher } from '../services';
import { selectJwt } from '../redux-tool-kit/userSlice';
import { mostSignificant } from '../utils/urn';
import { Condition } from '../types';
import { useSearchParams } from '../utils/useURLs';
import { selectRentalAgreement } from '../redux-tool-kit/claimSlice';

// Realistically the max is 2, but let's get 25 to be safe
export const MAX_CONDITION_REPORTS = 25;

interface BaseConditionReportResponse {
  conditionReports: [
    {
      urn: string;
    }
  ];
}

export interface ConditionReport {
  captureDateTime: string;
  newConditions?: Condition[];
  closedConditions?: Condition[];
}

export const useRaNumber = () => {
  const searchParams = useSearchParams();
  const queryParamRa = searchParams.get('conditionContext');
  const manuallyEnteredRa = useAppSelector(selectRentalAgreement);

  return manuallyEnteredRa ?? queryParamRa ?? null;
};

const searchFetcher = ([vid, jwt, ra]: [string, string | null, string | null]) => {
  const data = {
    unitNumber: vid,
    rentalTicketNumber: ra,
    paginationCriteria: {
      firstResultIndex: 1,
      pageSize: MAX_CONDITION_REPORTS,
    },
  };
  return edgePostFetcher(getConfig().searchConditionReports, jwt, data, 'errors.noConditionReportsFound').then(
    ({ conditionReports }: BaseConditionReportResponse) => conditionReports.map(({ urn }) => mostSignificant(urn))
  );
};

const useSearchConditionReports = (ra: string | null) => {
  const { vid } = useAppParams();
  const jwt = useAppSelector(selectJwt);
  return useSWRImmutable<string[]>(ra ? [vid, jwt, ra] : null, searchFetcher);
};

export const extractOpenWearAndTear = (reports: ConditionReport[]) => {
  return reports.flatMap<Condition>((report) => report.newConditions?.filter(({ isClaimable }) => !isClaimable) ?? []);
};

const wearAndTearFetcher = ([vid, jwt, conditionReports]: [string, string | null, string[]]) => {
  return Promise.all(
    conditionReports.map((reportId) =>
      edgeGetFetcher(
        `${getConfig().getConditionReport}&id=${vid}&reportId=${reportId}`,
        jwt,
        'errors.noConditionReportsFound',
        {
          Accept: 'application/prs.com-ehi.vehicle.fleetvehicle.conditionreport.detailedReport+json, application/json',
        }
      ).then(({ conditionReport }) => conditionReport)
    )
  ).then(extractOpenWearAndTear);
};

const useRaWearAndTear = () => {
  const { vid } = useAppParams();
  const jwt = useAppSelector(selectJwt);
  const raUrn = useRaNumber();
  const ra = raUrn ? mostSignificant(raUrn) : null;
  const { isLoading: isConditionReportsLoading, data: conditionReports } = useSearchConditionReports(ra);
  const swrResponse = useSWRImmutable(
    isConditionReportsLoading || ra === null ? null : [vid, jwt, conditionReports],
    wearAndTearFetcher
  );
  if (isConditionReportsLoading) {
    return { isLoading: true, data: undefined };
  } else {
    return swrResponse;
  }
};

export default useRaWearAndTear;
