import useSWRImmutable from 'swr/immutable';
import { useAppSelector } from '../redux-tool-kit/hooks';
import { selectJwt } from '../redux-tool-kit/userSlice';
import { edgeGetFetcher } from '../services';
import { getConfig } from '../utils/config';
import useUnitLocation from './useUnitLocation';
import { MultiRetrieveResponse } from '../types/apiTypes';

const fetcher = ([url, jwt, unitLocation]: [string, string | null, string | null]) =>
  edgeGetFetcher(url, jwt, 'errors.getProtectionsError', { 'condition-unit-location': unitLocation });

const useMultiRetrieve = (rentalAgreementNumber: string | null | undefined) => {
  const jwt = useAppSelector(selectJwt);
  const unitLocation = useUnitLocation();
  const validatedRa =
    rentalAgreementNumber && rentalAgreementNumber.length >= 6 && rentalAgreementNumber.length <= 10
      ? rentalAgreementNumber
      : null;
  return useSWRImmutable<MultiRetrieveResponse>(
    validatedRa && [`${getConfig().getProtections}&ticketNumber=${validatedRa}`, jwt, unitLocation],
    fetcher
  );
};

export default useMultiRetrieve;
