import React from 'react';
import {
  Button,
  Divider,
  Grid,
  Modal,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from '@mui/material';
import { useTranslation } from 'react-i18next';

import { StyledClaimRequiredModal } from '../../styles/screens';
import { useAppNavigation } from '../../utils/useURLs';
import { ExistingClaimsResponse } from '../../types/apiTypes';
import useAppParams from '../../hooks/useAppParams';

interface ExistingClaimsModalProps {
  open: boolean;
  existingClaimsData: ExistingClaimsResponse[];
  onCancel: () => void;
}

export const ExistingClaimModal = ({ open, onCancel, existingClaimsData }: ExistingClaimsModalProps) => {
  const { t } = useTranslation();
  const { vid } = useAppParams();
  const navigation = useAppNavigation();
  const options: Intl.DateTimeFormatOptions = { day: 'numeric', month: 'short', year: 'numeric' };
  const closeModalAndForward = () => {
    onCancel();
    navigation.push('/claim');
  };
  return (
    <Modal open={open} onClose={onCancel}>
      <StyledClaimRequiredModal>
        <Grid container data-testid='claimRequired' padding={1}>
          <Grid item xs={12} className='title bold' data-testid='title'>
            {t('claims.labels.claimRequired')}
          </Grid>
        </Grid>
        <Divider />
        <Grid container data-testid='claimRequiredMessage' padding={1}>
          <Grid item xs={12} data-testid='message'>
            {t('claims.existingClaimMessage')}
          </Grid>
        </Grid>
        <Grid container>
          <Grid item xs={12}>
            <strong>{`${t('claims.existingClaims')} ${vid}`}</strong>
          </Grid>
        </Grid>
        <Grid container>
          <Grid item xs={12}>
            <TableContainer sx={{ width: 'auto', maxHeight: '200px', overflow: 'auto' }}>
              <Table stickyHeader>
                <TableHead sx={{ justifyContent: 'space-between', width: '100%' }}>
                  <TableRow
                    key='tableRow'
                    sx={{
                      backgroundColor: '#d0cece',
                      height: 'auto',

                      justifyContent: 'space-between',
                    }}>
                    <TableCell sx={{ padding: '8px 16px', fontWeight: 'bold' }} key='claimNumber'>
                      <strong>{t('claimNumber')}</strong>
                    </TableCell>
                    <TableCell sx={{ padding: '8px 16px', fontWeight: 'bold' }} key='claimsRa'>
                      <strong>{t('claims.ra')}</strong>
                    </TableCell>
                    <TableCell sx={{ padding: '8px 16px', fontWeight: 'bold' }} key='claimDate'>
                      <strong>{t('claimDate')}</strong>
                    </TableCell>
                    <TableCell sx={{ padding: '8px 16px', fontWeight: 'bold' }} key='repairCode'>
                      <strong>{t('repairCode')}</strong>
                    </TableCell>
                    <TableCell sx={{ padding: '8px 16px', fontWeight: 'bold' }} key='damageDescription'>
                      <strong>{t('damageDescription')}</strong>
                    </TableCell>
                  </TableRow>
                </TableHead>

                <TableBody>
                  {existingClaimsData.map((element) => {
                    return (
                      <TableRow key={element.claimId} sx={{ padding: '5px' }}>
                        <TableCell sx={{ padding: '8px 16px' }}>{element.claimNumber}</TableCell>
                        <TableCell sx={{ padding: '8px 16px' }}>
                          {element.rentalAgreementNumber ? element.rentalAgreementNumber : '--'}
                        </TableCell>
                        <TableCell sx={{ padding: '8px 16px' }}>
                          {element.lossDate &&
                            new Intl.DateTimeFormat('en', options).format(new Date(element.lossDate)).toString()}
                        </TableCell>
                        <TableCell sx={{ padding: '8px 16px' }}>
                          {element.repairCode ? element.repairCode : `--`}
                        </TableCell>
                        <TableCell sx={{ padding: '8px 16px' }}>
                          {element.vehicleDamageDescription ? (
                            <>
                              {element.vehicleDamageDescription.split(' | ').map((description, index) => (
                                <div key={`${element.claimId}-${index}`}>
                                  <strong>{description.substring(0, description.indexOf(':') + 1)}</strong>
                                  {description.substring(description.indexOf(':') + 1)}
                                </div>
                              ))}
                            </>
                          ) : (
                            '--'
                          )}
                        </TableCell>
                      </TableRow>
                    );
                  })}
                </TableBody>
              </Table>
            </TableContainer>
          </Grid>
        </Grid>
        <Grid container justifyContent='space-between' padding={1}>
          <Grid item xs={12} md={1}>
            <Button data-testid='cancel-button' onClick={() => onCancel()}>
              {t('cancel')}
            </Button>
          </Grid>
          <Grid item xs={12} lg={4} md={5}>
            <Button variant='contained' data-testid='ok-button' onClick={closeModalAndForward}>
              {t('continueClaim')}
            </Button>
          </Grid>
        </Grid>
      </StyledClaimRequiredModal>
    </Modal>
  );
};
