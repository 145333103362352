import React, { ChangeEvent, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { TextField, Button, Paper, Grid } from '@mui/material';
import { useHistory } from 'react-router-dom';

const VehicleSearch = () => {
  const history = useHistory();
  const [value, setValue] = useState<string>('');
  const { t } = useTranslation();

  const viewCondition = (vid: string) => history.push(`/condition/${vid}/capture`);

  const updateValue = (e: ChangeEvent<HTMLTextAreaElement | HTMLInputElement>) => {
    setValue(e.target.value);
  };

  return (
    <div data-testid='entry-point'>
      <Paper sx={{ alignItems: 'center', marginTop: '12px' }}>
        <Grid container margin={2} padding={2} xs={12}>
          <Grid item xs={12}>
            <TextField
              sx={{ marginTop: '7px' }}
              id='vehicle-ident'
              type='text'
              value={value ? value : ''}
              onChange={(e) => updateValue(e)}
              label={t('vehicleId')}
            />
            <Button
              variant='contained'
              disabled={!value}
              onClick={async () => {
                await viewCondition(value);
              }}>
              {t('vehicleIdSubmit')}
            </Button>
          </Grid>
        </Grid>
      </Paper>
    </div>
  );
};

export default VehicleSearch;
