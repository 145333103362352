import { edgePostFetcher } from '../services';
import { useAppSelector } from '../redux-tool-kit/hooks';
import { selectJwt } from '../redux-tool-kit/userSlice';
import { first } from 'lodash';
import useSWR from 'swr';
import type { VehicleSliceData } from '../types';
import { getConfig } from '../utils/config';
import useAppParams from './useAppParams';

const fetcher = ([url, jwt, vid]: [string, string | null, string]) =>
  edgePostFetcher(url, jwt, { fleetVehicle: { ids: [vid] } }, 'errors.getVehicleError').then(({ fleetVehicles }) => {
    return first(fleetVehicles) as VehicleSliceData;
  });

const useVehicle = () => {
  const jwt = useAppSelector(selectJwt);
  const { vid } = useAppParams();
  return useSWR<VehicleSliceData>(vid ? [`${getConfig().fleetVehicleSearch}`, jwt, vid] : null, fetcher);
};

export default useVehicle;
