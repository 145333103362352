export enum ClaimWorkflowStageTypeId {
  CREATE = 1,
  RENTAL_COMPLETE = 2,
  DRU_OWNED = 3,
  SEND_BACK_TO_RENTAL = 4,
  RETURN_TO_DRU = 5,
  DUPLICATE = 6,
  INVALID = 7,
  PENDED = 8,
  VOIDED = 9,
  SCHEDULED_FOR_PURGE = 10,
  READY_FOR_PURGE = 11,
}
