import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { NetworkSuccessState } from '../types/sliceTypes';
import { passAPICall } from '../utils/navigation';
import type { RootState } from './store';

export const networkErrorAlertInitialState: NetworkSuccessState = {
  callNames: [],
};

export const networkSuccess = createSlice({
  name: 'networkSuccess',
  initialState: networkErrorAlertInitialState,
  reducers: {
    addSuccessCall: (state, action: PayloadAction<string>) => {
      if (!state.callNames.includes(action.payload)) {
        const newState: NetworkSuccessState = { callNames: [...state.callNames, action.payload] };
        if (newState.callNames.length === 3) {
          passAPICall();
        }
        return newState;
      }
    },
  },
});

export const { addSuccessCall } = networkSuccess.actions;

export const networkSuccessData = (state: RootState) => state.networkSuccess;

export default networkSuccess.reducer;
