import React, { useState } from 'react';

import { Suspense } from 'react';
import { Switch, useRouteMatch } from 'react-router-dom';
import { BasicVehicleData } from '../../shared/Vehicle/BasicVehicleData';
import { BasicInfoWrapper } from '../../styles/common';
import { Employee } from '../../types/claim/person/Employee';
import { PrivateRoute } from '../../utils/RBAC';
import { Claim } from './decisionTree/Claim';
import LossInformation from './lossInformation/LossInformation';
import Stepper from './shared/Stepper';
import { ClaimSummary } from './summary/ClaimSummary';

export function ClaimsRoute() {
  const { path } = useRouteMatch();
  const perms = {
    read: ['condition:read'],
    create: ['condition:read', 'condition:create'],
    update: ['condition:read', 'condition:create', 'condition:update'],
    delete: ['condition:read', 'condition:create', 'condition:update', 'condition:delete'],
  };
  const [employeeData, setEmployeeData] = useState<Employee>({});

  return (
    <Switch>
      <PrivateRoute requiredPerms={perms.create} exact path={path}>
        <Suspense fallback={<div>Loading...</div>}>
          <BasicInfoWrapper>
            <BasicVehicleData hideHeader isClaims />
          </BasicInfoWrapper>
          <Stepper activeStep={0} />
          <Claim storeEmp={setEmployeeData} />
        </Suspense>
      </PrivateRoute>
      <PrivateRoute requiredPerms={perms.create} path={`${path}/loss-information`}>
        <Suspense fallback={<div>Loading...</div>}>
          <BasicInfoWrapper>
            <BasicVehicleData hideHeader isClaims />
          </BasicInfoWrapper>
          <Stepper activeStep={1} />
          <LossInformation />
        </Suspense>
      </PrivateRoute>
      <PrivateRoute requiredPerms={perms.create} path={`${path}/summary`}>
        <Suspense fallback={<div>Loading...</div>}>
          <BasicInfoWrapper>
            <BasicVehicleData hideHeader isClaims />
          </BasicInfoWrapper>
          <Stepper activeStep={2} />
          <ClaimSummary employeeData={employeeData} />
        </Suspense>
      </PrivateRoute>
    </Switch>
  );
}
