import React from 'react';
import { useTranslation } from 'react-i18next';
import { Grid, List } from '@mui/material';
import IconButton from '@mui/material/IconButton';
import FormatListBulletedIcon from '@mui/icons-material/FormatListBulleted';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import { StyledTabs } from '../../styles/screens/StyledExteriorInteriorComponentToggle';

interface ExteriorInteriorComponentToggleProps {
  isExterior: boolean;
  setIsExterior: (newVal: boolean) => void;
  isListView: boolean;
  setIsListView: (newVal: boolean) => void;
}

export const ExteriorInteriorComponentToggle: React.FunctionComponent<ExteriorInteriorComponentToggleProps> = (
  props: ExteriorInteriorComponentToggleProps
): JSX.Element => {
  const { t } = useTranslation();
  const [value, setValue] = React.useState('exterior');

  const handleExteriorChange = (event: React.SyntheticEvent, newValue: string) => {
    setValue(newValue);
    props.setIsExterior(true);
    props.setIsListView(false);
  };

  const handleInteriorChange = (event: React.SyntheticEvent, newValue: string) => {
    setValue(newValue);
    props.setIsExterior(false);
    props.setIsListView(true);
  };

  return (
    <StyledTabs>
      <Grid container>
        <Tabs
          value={value}
          onChange={value === 'exterior' ? handleInteriorChange : handleExteriorChange}
          textColor='secondary'
          indicatorColor='primary'
          aria-label='exterior-interior tab'
          data-testid='exterior-interior-button'>
          <Tab value='exterior' label={t('condition.capture.exterior')} />
          <Tab value='interior' label={t('condition.capture.interior')} />
        </Tabs>

        <IconButton
          disabled={!props.isExterior}
          data-testid='list-view-button'
          color='secondary'
          onClick={() => {
            props.setIsExterior(true);
            props.setIsListView(!props.isListView);
          }}>
          <FormatListBulletedIcon />
          <List className={!props.isExterior ? 'button active-button white active-icon' : 'icon'} />
        </IconButton>
      </Grid>
    </StyledTabs>
  );
};
