import { CALLING_APP_ID } from '../shared/constants';
import { getConfig } from '../utils/config';

const useLogoutParams = () => {
  const appConfig = getConfig();
  return {
    authWebUrl: new URL(appConfig['authWebUrl']),
    callingAppId: CALLING_APP_ID,
    port: Number(location.port),
  };
};

export default useLogoutParams;
