import React from 'react';

import VehicleConditionsListItem from './VehicleConditionsListItem';
import { Grid } from '@mui/material';
import { CommonCondition } from '../../../types/conditionTypes/Condition';

interface VehicleConditionGumstickProps {
  conditions: CommonCondition[];
  isModal: boolean;
}

const VehicleConditionGumstick = ({ conditions, isModal }: VehicleConditionGumstickProps) => (
  <div className={'damages-list-group-container'}>
    {conditions.map((condition) => (
      <Grid item xs={12} key={condition.urn}>
        <VehicleConditionsListItem isModal={isModal} condition={condition} />
      </Grid>
    ))}
  </div>
);

export default VehicleConditionGumstick;
