import React, { useEffect, useMemo } from 'react';
import { sortBy } from 'lodash';
import { Autocomplete, Box, Grid, TextField } from '@mui/material';
import { useTranslation } from 'react-i18next';
import {
  initialState,
  lossInfoData,
  setLossInfo,
  setLossDate,
  removeLossState,
} from '../../../redux-tool-kit/lossInfoSlice';
import ValidatedTextField from '../../../shared/ValidatedTextField/ValidatedTextField';
import { useAppDispatch, useAppSelector } from '../../../redux-tool-kit/hooks';
import { useAppNavigation } from '../../../utils/useURLs';
import ConditionDatePicker from '../../../shared/DatePicker/ConditionDatePicker';
import ConditionTimePicker from '../../../shared/TimePicker/ConditionTimePicker';
import { NavButtons } from '../shared/NavButtons';
import useCountries from '../../../hooks/useCountries';
import useStates from '../../../hooks/useStates';

import { CountriesListResponse } from '../../../types/apiTypes';
import { ConditionCardHeader, ConditionCardSubHeader, ConditionPaper } from '../../../styles/common';
import useVehicle from '../../../hooks/useVehicle';

const LossInformation = () => {
  const { t } = useTranslation();

  const { data: vehicleInfo } = useVehicle();
  const lossInfo = useAppSelector(lossInfoData);
  const dispatch = useAppDispatch();
  const navigation = useAppNavigation();

  const { data: countriesData, isLoading: isCountriesLoading } = useCountries();
  const country = useMemo(
    () =>
      countriesData?.find(
        (element: CountriesListResponse) => element.name === getCountryFromIso3Code(lossInfo.lossCountry)
      ),
    [countriesData, lossInfo.lossCountry]
  );
  const { data: stateData, isLoading: isStatesLoading } = useStates(country?.countryIso3Code ?? null);

  useEffect(() => {
    window.scroll(0, 0);
    dispatch(setLossDate(new Date()));
  }, []);

  useEffect(() => {
    if (!isCountriesLoading) {
      if (vehicleInfo) {
        countriesData?.forEach((element) => {
          if (element.countryIso3Code === vehicleInfo?.operatingCountry) {
            dispatch(setLossInfo({ lossCountry: getIso3Code(element.name) }));
          }
        });
      }
    }
  }, [vehicleInfo, isCountriesLoading]);

  useEffect(() => {
    if (isStatesLoading) dispatch(setLossInfo({ lossState: undefined }));
  }, [isStatesLoading]);

  const areRequiredFieldsFilled = !!(
    lossInfo.factsOfLoss?.length &&
    lossInfo.lossCountry?.length &&
    lossInfo.lossState?.name &&
    lossInfo.timeOfLoss &&
    lossInfo.timeOfLoss < new Date()
  );

  const handleDateChange = (date: Date) => {
    dispatch(setLossDate(date));
  };

  return (
    <ConditionPaper data-testid='loss-information'>
      <Box marginLeft={3}>
        <ConditionCardHeader className='header'>{t('loss')}</ConditionCardHeader>
        <ConditionCardSubHeader className='requiredFields'>{`${t('requiredFields')}*`}</ConditionCardSubHeader>
      </Box>
      <Grid container spacing={3} padding={3}>
        <Grid item xs={12} sm={4}>
          <ConditionDatePicker
            value={lossInfo.timeOfLoss}
            title={`${t('datePicker.label')}*`}
            disableFuture
            onChange={(newValue: Date) => {
              handleDateChange(newValue);
            }}
          />
        </Grid>
        <Grid item xs={12} sm={4}>
          <ConditionTimePicker
            value={lossInfo.timeOfLoss}
            title={`${t('timePicker.label')}`}
            disableFuture
            onChange={(newValue: Date) => handleDateChange(newValue)}
          />
        </Grid>
        <Grid item xs={12} className='LossInformation-factsOfLoss-container'>
          <ValidatedTextField
            showAdornment={false}
            sx={{
              '&.MuiTextField-root p': {
                display: 'flex',
                marginLeft: 'auto',
                alignSelf: 'flex-end',
              },
            }}
            label={`${t('factsOfLoss')}*`}
            validatedVariant='outlined'
            multiline
            minRows={4}
            maxLength={4000}
            helperText={<HelperText charCount={lossInfo.factsOfLoss.length} limit={4000} />}
            value={lossInfo.factsOfLoss}
            onChange={(e) => {
              dispatch(setLossInfo({ factsOfLoss: e.target.value }));
            }}
          />
        </Grid>
        <Grid item xs={12} sm={4} className='LossInformation-lossCountry-container'>
          <Autocomplete
            value={
              country ?? {
                name: '',
                countryIso2Code: '',
                countryIso3Code: '',
                currency: {
                  code: '',
                  description: '',
                },
                unitsOfMeasure: { distance: '', volume: '' },
              }
            }
            disabled={isCountriesLoading}
            options={
              countriesData
                ? [
                    ...countriesData,
                    {
                      name: '',
                      countryIso2Code: '',
                      countryIso3Code: '',
                      currency: {
                        code: '',
                        description: '',
                      },
                      unitsOfMeasure: { distance: '', volume: '' },
                    },
                  ]
                : []
            }
            getOptionLabel={(option) => option.name ?? ''}
            isOptionEqualToValue={(option, value) => option.name === '' || option.name === value.name}
            placeholder={`${t('lossCountry')}*`}
            renderInput={(params) => <TextField {...params} label={`${t('lossCountry')}*`} />}
            onInputChange={(e, newInputValue) => {
              dispatch(setLossInfo({ lossCountry: getIso3Code(newInputValue) }));
            }}
            data-testid='country-autocomplete'
          />
        </Grid>
        <Grid item xs={12} sm={4} className='LossInformation-LossState-container'>
          {stateData?.subdivision ? (
            <Autocomplete
              value={lossInfo.lossState ? lossInfo.lossState : { name: '', abbreviation: '', type: '' }}
              isOptionEqualToValue={(option, value) => option.name === value.name}
              disabled={!lossInfo.lossCountry || isStatesLoading}
              options={sortBy(
                [
                  ...stateData.subdivision,
                  {
                    name: '',
                    abbreviation: '',
                    type: '',
                  },
                ],
                'name'
              )}
              placeholder={`${t('lossState')}*`}
              getOptionLabel={(option) => option.name}
              renderInput={(params) => <TextField {...params} label={`${t('lossState')}*`} />}
              onChange={(e, newValue) => {
                if (newValue == null) {
                  dispatch(removeLossState());
                } else {
                  dispatch(setLossInfo({ lossState: newValue }));
                }
              }}
              data-testid='state-autocomplete'
            />
          ) : (
            <TextField
              disabled={!lossInfo.lossCountry || isStatesLoading}
              label={`${t('lossState')}*`}
              variant='outlined'
              fullWidth
              value={lossInfo.lossState?.name ?? ''}
              onChange={(e) => {
                dispatch(setLossInfo({ lossState: { name: e.target.value || '', abbreviation: '', type: 'STATE' } }));
              }}
            />
          )}
        </Grid>
        <Grid item xs={12} sm={4} className='LossInformation-lossCity-container'>
          <TextField
            label={`${t('lossCity')}`}
            variant='outlined'
            fullWidth
            value={lossInfo.lossCity}
            onChange={(e) => {
              dispatch(setLossInfo({ lossCity: e.target.value }));
            }}
          />
        </Grid>
        <Grid item xs={12} className='LossInformation-lossLocation-container'>
          <ValidatedTextField
            data-testid='loss-location-field'
            sx={{
              'p.MuiFormHelperText-root': {
                display: 'flex',
                flexDirection: 'row',
                justifyContent: 'space-between',
              },
            }}
            showAdornment={false}
            label={`${t('lossLocation')}`}
            validatedVariant='outlined'
            maxLength={100}
            value={lossInfo.lossLocation}
            helperText={<HelperText charCount={lossInfo.lossLocation.length} limit={100} helperTextEnabled />}
            onChange={(e) => {
              dispatch(setLossInfo({ lossLocation: e.target.value }));
            }}
          />
        </Grid>

        <NavButtons
          activeStep='loss'
          previousButtonClick={() => {
            dispatch(setLossInfo(initialState));
            navigation.push('/claim');
          }}
          nextButtonClick={() => navigation.push('/claim/summary')}
          allRequiredFieldsFilled={areRequiredFieldsFilled}
        />
      </Grid>
    </ConditionPaper>
  );

  function getIso3Code(country: string): string {
    const filteredCountry = countriesData?.find((element) => element.name === country);
    if (filteredCountry?.countryIso3Code) return filteredCountry?.countryIso3Code;
    else {
      return '';
    }
  }

  function getCountryFromIso3Code(iso3Code: string): string {
    const filteredCountry = countriesData?.find((element) => element.countryIso3Code === iso3Code);
    if (filteredCountry?.name) return filteredCountry?.name;
    else {
      return '';
    }
  }
};

interface HelperTextProps {
  charCount: number;
  limit: number;
  helperTextEnabled?: boolean;
}
const HelperText = ({ charCount, limit, helperTextEnabled = false }: HelperTextProps) => {
  const { t } = useTranslation();
  return (
    <>
      {helperTextEnabled && <span className='helperText'>{t('helperText')}</span>}
      <span className='helperCount'>{`${charCount}/${limit}`}</span>
    </>
  );
};

export default LossInformation;
