import React from 'react';
import { Button } from '@mui/material';
import { cancelConditionCapture } from '../../utils/navigation';
import { useTranslation } from 'react-i18next';
import PendoTag from '../../shared/PendoTag';

export const ExitConditionButton = () => {
  const { t } = useTranslation();

  return (
    <PendoTag tag='pendo-exit-condition'>
      <Button onClick={cancelConditionCapture}>{t('exitCondition')}</Button>
    </PendoTag>
  );
};
