import { Grid, GridProps } from '@mui/material';
import React from 'react';
import { StyledQuestion } from '../../../styles/screens';

export const Question: React.FunctionComponent<GridProps> = (props: GridProps): JSX.Element => {
  return (
    <Grid
      item
      container
      direction='column'
      justifyContent='flex-end'
      xs={props.xs ?? 7}
      {...props}
      data-testid='question'>
      <StyledQuestion>{props.children}</StyledQuestion>
    </Grid>
  );
};
