import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { NetworkErrorAlertState } from '../types/sliceTypes';
import type { RootState } from './store';

export const networkErrorAlertInitialState: NetworkErrorAlertState = {
  showAlert: false,
};

export const networkErrorAlert = createSlice({
  name: 'networkErrorAlert',
  initialState: networkErrorAlertInitialState,
  reducers: {
    showNetworkErrorAlert: (state, action: PayloadAction<NetworkErrorAlertState>) => {
      const newState: NetworkErrorAlertState = {
        ...action.payload,
      };
      return newState;
    },
  },
});

export const { showNetworkErrorAlert } = networkErrorAlert.actions;

export const networkErrorAlertData = (state: RootState) => state.networkErrorAlert;

export default networkErrorAlert.reducer;
