import React from 'react';
import { Button } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { CameraAltOutlined } from '@mui/icons-material';

import { getUserDevice } from '../../utils/deviceInfo';
import { ImageCapture } from './ImageCapture/ImageCapture';
import { ImageUpload } from './ImageUpload/ImageUpload';
import { ImageUploadCallback } from '../../types/conditionTypes/ConditionImage';

interface AddPhotoButtonProps {
  disabled?: boolean;
}
const AddPhotoButton = ({ disabled = false }: AddPhotoButtonProps) => {
  const { t } = useTranslation();
  return (
    <Button
      className='claim-add-photo-btn'
      startIcon={<CameraAltOutlined />}
      variant='outlined'
      sx={{ borderStyle: 'dashed' }}
      disabled={disabled}>
      {t('addPhoto')}
    </Button>
  );
};

interface ImageWidgetProps {
  onUpload: ImageUploadCallback;
  isLoading?: boolean;
}

const ImageWidget = ({ onUpload, isLoading = false }: ImageWidgetProps) => {
  if (getUserDevice().type === 'mobile' || getUserDevice().type === 'tablet') {
    return <ImageCapture showPreview renderIcon={<AddPhotoButton disabled={isLoading} />} onUpload={onUpload} />;
  } else {
    return <ImageUpload showPreview renderIcon={<AddPhotoButton />} onUpload={onUpload} />;
  }
};

export default ImageWidget;
