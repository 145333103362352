import React from 'react';
import { Box, Skeleton } from '@mui/material';
import styled from 'styled-components';

export const SkeletonBox = styled(Box)`
  * {
    display: block;
    margin-left: auto;
    margin-right: auto;
    transform: scale(1, 0.9);
  }
`;

const VehicleConditionSkeleton = () => (
  <SkeletonBox>
    <Skeleton width={750} height={300} />
    <Skeleton width={600} height={200} />
    <Skeleton width={600} height={600} />
  </SkeletonBox>
);

export default VehicleConditionSkeleton;
