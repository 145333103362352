import type { useGetConditions } from '../condition/ConditionHelper';

export const paintComponent = (node: SVGUseElement, fill?: String) => {
  node.setAttribute('display', `inherit`);
  if (fill) {
    node.setAttribute('style', `fill:${fill} !important`);
  }
};

export const markComponent = (node: SVGUseElement, fill?: String) => {
  node.setAttribute('display', `inherit`);
  if (fill) {
    node.setAttribute('style', `fill:${fill} !important`);
  }
};

export const unMarkComponent = (node: SVGUseElement) => {
  node.setAttribute('display', `none`);
};

export const paintCurrentComponents = (
  useList: NodeListOf<SVGUseElement>,
  getConditions: ReturnType<typeof useGetConditions>
) => {
  const conditions = [...getConditions(false), ...getConditions(true)];
  if (conditions) {
    conditions.forEach((condition) => {
      useList.forEach((icon) => {
        if (icon.id.substring(0, icon.id.length - 1) === getPartNameFromURN(condition.component)) {
          paintComponent(icon, condition.isClaimable ? '#FFA100' : '#632289');
        }
      });
    });
  }
};

export const getPartNameFromURN = (urn: string) => {
  const lastIndex = urn.lastIndexOf(':');
  return urn.slice(lastIndex + 1);
};
