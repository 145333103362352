import { store } from '../../redux-tool-kit/store';

import { Option, QuestionInformation } from '../../types';
import { ClaimInfoType, IncidentType, QuestionType } from '../../types/enums';
import { ClaimTypeResponse, IncidentTypeResponse } from '../../types/apiTypes';
import { YesNo } from '../../types/util/UtilTypes';

export const getOxIncidentTypes = (claim: number | null, incidents: IncidentTypeResponse): Option[] => {
  switch (claim) {
    case ClaimInfoType.EMPLOYEE_LOSS:
      return oxEmployeeLossIncidentTypes(incidents);
    case ClaimInfoType.OTHER_VEHICLE_LOSS:
      return oxOtherLossIncidentTypes(incidents);

    default:
      return [];
  }
};

export const isZeroInfoClaim = () => {
  const notPresent =
    getQuestionYesNoAnswer(store.getState().claim.questionsInformation, QuestionType.RENTER_PRESENT) === 'no' || '';
  const notKnown =
    getQuestionYesNoAnswer(store.getState().claim.questionsInformation, QuestionType.RENTER_KNOWN_DAMAGE) === 'no' ||
    '';
  const noInsuranceData =
    getQuestionYesNoAnswer(store.getState().claim.questionsInformation, QuestionType.DRIVER_INSURANCE_INFO) === 'no' ||
    '';
  return !!(notPresent && notKnown && noInsuranceData);
};

export const getQuestionYesNoAnswer = (questions: QuestionInformation[], questionId: QuestionType): YesNo => {
  const answer = questions.filter((q: QuestionInformation) => q.questionTypeId === questionId);
  return answer.length && answer[0].answerIndicator !== undefined ? (answer[0].answerIndicator ? 'yes' : 'no') : '';
};

export const getQuestionIntegerAnswer = (
  questions: QuestionInformation[],
  questionId: QuestionType
): number | undefined => {
  const answer = questions.filter((q: QuestionInformation) => q.questionTypeId === questionId);
  return answer.length ? answer[0].answerInteger! : undefined;
};

// Temporary hard coded drop downs for claim and incident type
export const dxIncidentTypes = (incidentTypes: IncidentTypeResponse): Option[] => {
  const incidentTypesOnRent = [
    IncidentType.CONVERSION,
    IncidentType.DAMAGE,
    IncidentType.FIRE,
    IncidentType.GLASS,
    IncidentType.UNACCOUNTED_THEFT,
    IncidentType.VANDALISM,
    IncidentType.WEATHER,
  ];
  return incidentTypes.incidentTypeList
    .filter((incidentType) => incidentTypesOnRent.includes(incidentType.id))
    .map((incidentType) => ({
      value: incidentType.id,
      key: incidentType.description,
    }));
};

export const oxEmployeeLossIncidentTypes = (incidentTypes: IncidentTypeResponse): Option[] => {
  const incidentTypesEmployeeLoss = [IncidentType.EMPLOYEE_LOSS, IncidentType.TRANSPORT_EMPLOYEE];
  return incidentTypes.incidentTypeList
    .filter((incidentType) => incidentTypesEmployeeLoss.includes(incidentType.id))
    .map((incidentType) => ({
      value: incidentType.id,
      key: incidentType.description,
    }));
};

export const oxOtherLossIncidentTypes = (incidentTypes: IncidentTypeResponse): Option[] => {
  const incidentTypesOther = [
    IncidentType.FIRE,
    IncidentType.LOT_DAMAGE,
    IncidentType.TRANSPORT_NEW,
    IncidentType.UNACCOUNTED_THEFT,
    IncidentType.VANDALISM,
    IncidentType.WEATHER,
  ];
  return incidentTypes.incidentTypeList
    .filter((incidentType) => incidentTypesOther.includes(incidentType.id))
    .map((incidentType) => ({
      value: incidentType.id,
      key: incidentType.description,
    }));
};

export const dxClaimTypes = (claimTypes: ClaimTypeResponse[]): Option[] => {
  const claimTypesOnRent = [ClaimInfoType.DAILY_RENTAL_ON_CONTRACT];
  return claimTypes
    .filter((claimType) => claimTypesOnRent.includes(claimType.id))
    .map((claimType) => ({
      value: claimType.id,
      key: claimType.description,
    }));
};

export const oxClaimTypes = (claimTypes: ClaimTypeResponse[]): Option[] => {
  const claimTypesOffRent = [
    ClaimInfoType.EMPLOYEE_LOSS,
    ClaimInfoType.OTHER_VEHICLE_LOSS,
    ClaimInfoType.UNDOCUMENTED_DAMAGE,
  ];
  return claimTypes
    .filter((claimType) => claimTypesOffRent.includes(claimType.id))
    .map((claimType) => ({
      value: claimType.id,
      key: claimType.description,
    }));
};
