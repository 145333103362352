import React from 'react';
import { useTheme } from '@mui/material/styles';
import Box from '@mui/material/Box';
import Paper from '@mui/material/Paper';
import Typography from '@mui/material/Typography';
import Button from '@mui/material/Button';
import KeyboardArrowLeft from '@mui/icons-material/KeyboardArrowLeft';
import KeyboardArrowRight from '@mui/icons-material/KeyboardArrowRight';
import { StyledImageCarousel } from '../../../styles/shared/images/StyledImageCarousel';
import { useTranslation } from 'react-i18next';
import SwipeableViews from 'react-swipeable-views';
import { Grid } from '@mui/material';
import { DatedImage } from '../../../types/images/Image';

interface ImageCarouselProps {
  images: DatedImage[];
  initialIndex: number;
  header?: string;
}

export const ImageCarousel: React.FunctionComponent<ImageCarouselProps> = ({
  images,
  initialIndex,
  header,
}: ImageCarouselProps): JSX.Element => {
  const theme = useTheme();
  const [activeStep, setActiveStep] = React.useState(initialIndex);
  const [activeDate, setActiveDate] = React.useState(images[initialIndex].date);
  const maxSteps = images.length;

  const { t } = useTranslation();

  const handleNext = () => {
    setActiveStep((prevActiveStep) => prevActiveStep + 1);
    setActiveDate(images[activeStep].date);
  };

  const handleBack = () => {
    setActiveStep((prevActiveStep) => prevActiveStep - 1);
    setActiveDate(images[activeStep].date);
  };

  const handleStepChange = (step: number) => {
    setActiveStep(step);
    setActiveDate(images[activeStep].date);
  };
  return (
    <StyledImageCarousel>
      <Paper sx={{ maxWidth: 600, flexGrow: 1 }}>
        <Typography className='header'>{header && header}</Typography>
        <SwipeableViews
          axis={theme.direction === 'rtl' ? 'x-reverse' : 'x'}
          index={activeStep}
          onChangeIndex={handleStepChange}
          enableMouseEvents>
          {images.map((step, index) => (
            <div key={'carousel-image-' + index}>
              {Math.abs(activeStep - index) <= 2
                ? step.src && (
                    <Box
                      component='img'
                      sx={{
                        maxHeight: '100%',
                        display: 'block',
                        maxWidth: '100%',
                        overflow: 'hidden',
                      }}
                      src={step.src}
                      alt={step.date?.toString() || ''}
                    />
                  )
                : null}
            </div>
          ))}
        </SwipeableViews>
        <Grid container justifyContent='space-between'>
          <Grid item>
            <Button size='small' onClick={handleBack} disabled={activeStep === 0}>
              {theme.direction === 'rtl' ? <KeyboardArrowRight /> : <KeyboardArrowLeft />}
              {t('claims.previous')}
            </Button>
          </Grid>
          {activeDate && (
            <Grid className='date-time-taken'>
              <Typography sx={{ textAlign: 'center' }}>{t('takenOn')}</Typography>
              <Typography sx={{ textAlign: 'center' }}>
                <b>{t('intlImageDate', { date: new Date(activeDate) })}</b>
                {t('intlImageTime', { date: new Date(activeDate) })}
              </Typography>
            </Grid>
          )}
          <Grid item>
            <Button size='small' onClick={handleNext} disabled={activeStep === maxSteps - 1}>
              {t('next')}
              {theme.direction === 'rtl' ? <KeyboardArrowLeft /> : <KeyboardArrowRight />}
            </Button>
          </Grid>
        </Grid>
      </Paper>
    </StyledImageCarousel>
  );
};
