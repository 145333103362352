import React from 'react';
import VehicleCondition from '../vehicleCondition/VehicleCondition';
import VehicleConditionSkeleton from '../vehicleCondition/VehicleConditionSkeleton';
import useVehicle from '../../hooks/useVehicle';

interface MainProps {
  viewConditionModalOpen?: boolean;
  addConditionModalOpen?: boolean;
  viewOtherConditionModalOpen?: boolean;
}

const Main = ({
  viewConditionModalOpen = false,
  addConditionModalOpen = false,
  viewOtherConditionModalOpen = false,
}: MainProps) => {
  const { isLoading, error } = useVehicle();

  if (isLoading) {
    return <VehicleConditionSkeleton />;
  } else if (error) {
    return <div>Error: {error}</div>;
  } else {
    return (
      <VehicleCondition
        viewConditionModalOpen={viewConditionModalOpen}
        addConditionModalOpen={addConditionModalOpen}
        viewOtherConditionModalOpen={viewOtherConditionModalOpen}
      />
    );
  }
};

export default Main;
