import { ConditionImage } from '../../types';

export const setImgElemStyle = (img?: ConditionImage) => ({
  width: '100%',
  height: '100px',
  background: `url(${img?.formattedImages[1]?.url || img?.formattedImages[0]?.url}) gray`,
  backgroundSize: 'cover',
  textAlign: 'center',
});

export const setClaimImgElemStyle = (img: string) => ({
  height: '4rem',
  width: '8.25rem',
  margin: '0.5rem 1rem 0.5rem 0',
  background: `url(${img}) gray`,
  backgroundSize: 'cover',
  cursor: 'pointer',
});
