import styled from 'styled-components';
import { Grid } from '@mui/material';

export const StyledClaimUpload = styled(Grid)`
  padding-right: 1rem;
  padding-bottom: 1rem;

  .claim-add-photo-btn {
    display: flex;
    flex-direction: column;
    margin: 0.6rem !important;
    padding: 0.5rem 1rem !important;
    border-style: dashed;
    border-radius: 4px;

    span {
      margin: 0;
    }
  }
`;
