/* eslint-disable max-len */
import { store } from '../../redux-tool-kit/store';
import { Condition } from '../../types';
import { setCondition, toggleCheckbox } from '../../redux-tool-kit/conditionSlice';
import { NewCondition } from '../../types/conditionTypes/Condition';
import { omit } from 'lodash';

export const getCheckBox = (urn: string) => {
  const checkboxes = store.getState().condition.conditionCheckBoxes;

  return checkboxes[urn];
};

export const manageConditionSaveAndRemove = (previousConditions: Condition[]) => {
  const newConditions = store.getState().condition.newConditions;
  const checkboxArray = Object.entries(store.getState().condition.conditionCheckBoxes);
  const conditionsToSubmit: NewCondition[] = [];
  const conditionsToRemove: Condition[] = [];

  checkboxArray?.forEach(([urn, check]) => {
    newConditions?.forEach((condition) => {
      if (urn === condition.urn && check.checked) {
        conditionsToSubmit.push(condition);
      }
    });
    previousConditions?.forEach((condition) => {
      if (urn === condition.urn && !check.checked) {
        conditionsToRemove.push(condition);
      }
    });
  });

  setConditionReportPayload(conditionsToSubmit, conditionsToRemove);
};

export const setConditionReportPayload = (checked: NewCondition[], unchecked: Condition[]) => {
  let conditionState = store.getState().condition;

  conditionState = {
    ...conditionState,
    currentConditionReport: {
      ...conditionState.currentConditionReport,
      newConditions: checked.map((condition) => omit(condition, 'urn')),
      overriddenConditions: unchecked,
    },
  };

  store.dispatch(setCondition(conditionState));
};

// if item is not checked it doesnt do anything if it is checked it unchecks
export const removeCondition = (urn: string) => {
  const checkBoxForCurrentCondition = getCheckBox(urn);
  checkBoxForCurrentCondition?.checked && store.dispatch(toggleCheckbox(urn));
};
