import React from 'react';
import { Box, Divider, Grid, Button, useMediaQuery, useTheme } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { StyledConditionModal } from '../../../styles/screens/StyledConditionModal';
import { BasicVehicleData } from '../../../shared/Vehicle/BasicVehicleData';
import { SingleFlatFish } from '../../../shared/images/Flatfish/SingleFlatFish';
import ViewConditionDetails from './ViewConditionDetails';
import { useAppSelector } from '../../../redux-tool-kit/hooks';
import { activeConditionData } from '../../../redux-tool-kit/activeConditionSlice';
import { useAppNavigation } from '../../../utils/useURLs';
import { removeCondition } from '../../../utils/condition/ConditionListCheckHelper';
import useAppParams from '../../../hooks/useAppParams';
import { getConfig } from '../../../utils/config';
import { getFlatfishViewFromUrl } from '../../../utils/condition/ConditionHelper';
import PendoTag from '../../../shared/PendoTag';

const ViewCondition = () => {
  const { t } = useTranslation();
  const { schematic } = useAppParams();
  const viewData = useAppSelector(activeConditionData);
  const navigation = useAppNavigation();
  const theme = useTheme();
  const lessThanSmall = useMediaQuery(theme.breakpoints.down('sm'));

  function getSVGHeight() {
    if (schematic !== 'FRONT' && schematic !== 'BACK' && lessThanSmall) {
      return 100;
    }
    return 320;
  }

  return (
    <StyledConditionModal>
      <Box padding={2} className='header'>
        {t('addCondScr.viewCondition')}
      </Box>
      <Divider />
      <Box padding={3}>
        <BasicVehicleData hideHeader />
        <Box sx={{ position: 'relative' }}>
          {schematic !== 'NONE' && (
            <SingleFlatFish
              imageUrn={`urn:com.ehi:${getConfig().environment}:reference:vehicle:condition:position:${schematic}`}
              imageHeight={getSVGHeight()}
            />
          )}
        </Box>
        <ViewConditionDetails data={viewData} />
      </Box>
      <Grid container padding={1} direction='row' justifyContent='flex-end' alignItems='flex-end' xs={12}>
        <Grid item>
          <PendoTag tag={`pendo-remove-condition-${getFlatfishViewFromUrl()}`}>
            <Button
              variant='text'
              className='bold ehi-blue'
              onClick={() => {
                if (viewData.urn) {
                  removeCondition(viewData.urn);
                }
                navigation.push('/capture');
              }}>
              {t('condition.view.removeCondition')}
            </Button>
          </PendoTag>
        </Grid>
        <Grid item>
          <Button variant='contained' className='active-button' onClick={() => navigation.push('/capture')}>
            {t('close')}
          </Button>
        </Grid>
      </Grid>
    </StyledConditionModal>
  );
};

export default ViewCondition;
