import React from 'react';
import { Button } from '@mui/material';
import { StyledReportSubmitError } from '../../styles/modals/StyledReportSubmitError';
import { useTranslation } from 'react-i18next';

interface RSEProps {
  errorMsgs?: string[];
  close: () => void;
}

const ReportSubmitError: React.FC<RSEProps> = ({ errorMsgs, close }: RSEProps) => {
  const { t } = useTranslation();
  const headertext = () => {
    let headText = '';
    const mileErrs = ['errorEmptyOdometer', 'errorOnlyNumbers', 'errorLengthSix'];
    const fuelErr = 'errorNoFuel';

    const mileageErrPresent =
      errorMsgs?.includes(mileErrs[0]) || errorMsgs?.includes(mileErrs[1]) || errorMsgs?.includes(mileErrs[2])
        ? true
        : false;
    const fuelErrPresent = errorMsgs?.includes(fuelErr) ? true : false;

    headText = fuelErrPresent ? 'errors.modalHead.fuel' : headText;
    headText = mileageErrPresent ? 'errors.modalHead.odometer' : headText;
    headText = fuelErrPresent && mileageErrPresent ? 'errors.modalHead.odometerAndFuel' : headText;
    return headText;
  };
  return (
    <StyledReportSubmitError>
      <div id='fuel-mileage-header'>{t(headertext())}</div>
      <div data-testid='error-msg'>
        <ul>
          {errorMsgs?.map((msg) => (
            <li key={msg}>{t(`errors.${msg}`)}</li>
          ))}
        </ul>
      </div>
      <div id='close-button'>
        <Button data-testid='modal-close' onClick={() => close()}>
          {t('close')}
        </Button>
      </div>
    </StyledReportSubmitError>
  );
};

export default ReportSubmitError;
