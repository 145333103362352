import styled from 'styled-components';

export const StyledCamera = styled.div`
  video {
    display: block;
    max-width: 100vmin;
    height: auto;
    margin-left: auto;
    margin-right: auto;
  }
`;
