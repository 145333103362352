import React, { useState } from 'react';
import { Button, Dialog, Grid, Typography } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { groupBy, isEmpty } from 'lodash';

import { useSearchParams } from '../../../utils/useURLs';
import { useAppSelector } from '../../../redux-tool-kit/hooks';
import { selectCheckboxes } from '../../../redux-tool-kit/conditionSlice';
import VehicleConditionsList from './VehicleConditionsList';
import { useGetConditions } from '../../../utils/condition/ConditionHelper';
import useImageSearch from '../../../hooks/useImageSearch';
import ViewImages from '../../../shared/images/ViewImages/ViewImages';
import { useFlags } from 'launchdarkly-react-client-sdk';

const VehicleConditionListsWrapper = () => {
  const { vehCustomerSubmittedCondition } = useFlags();
  const { t } = useTranslation();
  const searchObject = useSearchParams();
  const isRentalAgreementInSearch = searchObject.has('conditionContext');
  const conditionCheckBoxes = useAppSelector(selectCheckboxes);
  const { true: damageChecks, false: wearTearChecks } = groupBy(conditionCheckBoxes, 'isClaimable');
  const isAllDamageChecked = !isEmpty(damageChecks) && damageChecks.every((check) => check.checked);
  const isAllWearTearChecked = !isEmpty(wearTearChecks) && wearTearChecks.every((check) => check.checked);
  const getConditions = useGetConditions();

  const { data: imageData } = useImageSearch();
  const [showCustomerImagesModal, setShowCustomerImagesModal] = useState(false);
  return (
    <>
      <Grid item xs={12} data-testid='vehicle-damages'>
        {getConditions(true) ? (
          <VehicleConditionsList
            isModal={false}
            conditions={getConditions(true)}
            data-testid='vehicle-condition-damages'
            isAllChecked={isAllDamageChecked}
            claimable={true}
          />
        ) : null}
      </Grid>
      <Grid item xs={12} data-testid='vehicle-wear-and-tear'>
        {getConditions(false) ? (
          <VehicleConditionsList
            isModal={false}
            conditions={getConditions(false)}
            data-testid='vehicle-condition-wearTear'
            isAllChecked={isAllWearTearChecked}
            claimable={false}
          />
        ) : null}
      </Grid>
      {vehCustomerSubmittedCondition && isRentalAgreementInSearch && imageData && Object.keys(imageData).length ? (
        <>
          <Typography sx={{ color: 'gray', fontSize: '20px', fontWeight: 'bold' }}>
            {t('customerSubmissions')} ({imageData.images.length})
          </Typography>
          <div className={'damages-list-group-container'}>
            <div className='damages-list-item-container'>
              <Grid container columnSpacing={1} alignItems='center'>
                <Grid item xs={1}></Grid>
                <Grid
                  item
                  xs={7}
                  md={9}
                  direction='column'
                  justifyContent='center'
                  alignItems='flex-start'
                  className='bold'
                  data-testid='photos'>
                  <span> {t('customerPhotos')}</span>
                </Grid>
                <Grid item xs={4} md={2} direction='column' justifyContent='center' alignItems='flex-end'>
                  <Button variant='text' data-testid='view-button' onClick={() => setShowCustomerImagesModal(true)}>
                    {t('view')}
                  </Button>
                </Grid>
              </Grid>
            </div>
          </div>
        </>
      ) : null}
      <Dialog open={showCustomerImagesModal} onClose={() => setShowCustomerImagesModal(false)}>
        <ViewImages imageData={imageData} />
        <Grid container direction='row' justifyContent='flex-end' alignItems='flex-end'>
          <Grid item>
            <Button
              variant='contained'
              className='active-button'
              onClick={() => {
                setShowCustomerImagesModal(false);
              }}>
              {t('close')}
            </Button>
          </Grid>
        </Grid>
      </Dialog>
    </>
  );
};

export default VehicleConditionListsWrapper;
