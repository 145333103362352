import { CheckCircleOutline, DeleteForeverOutlined, RefreshOutlined } from '@mui/icons-material';
import { Grid } from '@mui/material';
import React from 'react';
import { StyledIcon } from '../../../styles/mui';
import PendoTag from '../../PendoTag';

interface ImageCapturePreviewProps {
  image: string;
  handleAccept: Function;
  handleCancel: Function;
  handleRetake: Function;
  className?: string;
}

export const ImageCapturePreview: React.FunctionComponent<ImageCapturePreviewProps> = (
  props: ImageCapturePreviewProps
): JSX.Element => {
  return (
    <div data-testid='image-preview' className={props.className}>
      <img src={props.image} alt={'Current Captured'} />
      <Grid container className='image-preview-buttons'>
        <PendoTag tag='pendo-accept-photo'>
          <Grid item xs={4}>
            <StyledIcon>
              <CheckCircleOutline
                className='image-action-button green'
                onClick={() => props.handleAccept()}
                data-testid='accept-button'
                fontSize='large'
              />
            </StyledIcon>
          </Grid>
        </PendoTag>
        <PendoTag tag='pendo-trash-photo'>
          <Grid item xs={4}>
            <StyledIcon>
              <DeleteForeverOutlined
                className='image-action-button red'
                onClick={() => props.handleCancel()}
                data-testid='cancel-button'
                fontSize='large'
              />
            </StyledIcon>
          </Grid>
        </PendoTag>
        <PendoTag tag='pendo-retake-photo'>
          <Grid item xs={4}>
            <StyledIcon>
              <RefreshOutlined
                className='image-action-button last'
                onClick={() => props.handleRetake()}
                data-testid='retake-button'
                fontSize='large'
              />
            </StyledIcon>
          </Grid>
        </PendoTag>
      </Grid>
    </div>
  );
};
