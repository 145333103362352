import axios from 'axios';
import { store } from '../../redux-tool-kit/store';
import { getConfig } from '../../utils/config';

export interface EmailResponse {
  success: boolean;
  error?: string;
}

export const sendEmail = async (
  emailAddress: string,
  currencyCode: string,
  unitLocation: string | null
): Promise<EmailResponse> => {
  const jwt = store.getState().user.jwt;
  const claimId = store.getState().claim.claimLevelInformation.claimId;
  const fuelOut = store.getState().condition.fuelPercentage?.toString();

  const emailApiRoute = `${getConfig().superedge}${getConfig().emailTarget}`;
  const payload = {
    claimId,
    fuelOut,
    emailAddresses: [emailAddress],
    currencyCode,
  };
  const headers = { Authorization: `Bearer ${jwt}`, 'condition-unit-location': unitLocation };

  try {
    const response: any = await axios.post(emailApiRoute, payload, { headers });
    return response.success ? { success: true } : { success: false, error: response.errorMessages };
  } catch (error: any) {
    return { success: false, error: error.message };
  }
};
