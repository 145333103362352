import styled from 'styled-components';

export const StyledLossInformation = styled.div`
  background-color: #ffffff;
  width: 100%;
  justify-self: center;
  justify-content: center;
  display: flex;
  .lossContainer {
    width: 90%;
    height: auto;
    justify-content: center;
    align-self: center;
  }
  .LossInformation-LossState-container {
    margin-bottom: 15px;
  }
  .grid {
    padding: 5px;
  }
  .timePicker {
    margin-bottom: 10px;
  }
  .header {
    font-size: 20px;
    margin-bottom: 5px;
    margin-top: 30px;
    line-height: 20px;
  }
  .requiredFields {
    margin-bottom: 25px;
    font-size: 12px;
    line-height: 16px;
    color: rgba(0, 0, 0, 0.54);
  }
  .helperContainer {
    flex-direction: row;
    width: 101.3%;
    display: flex;
  }
`;
