import React, { useEffect, useState } from 'react';
import { Route, Redirect } from 'react-router-dom';
import { useAuthContext } from './index';

interface PRProps {
  path: string;
  requiredPerms: string[];
  exact?: boolean;
  children?: React.ReactNode;
}

interface PermList {
  'condition:read': boolean;
  'condition:create': boolean;
  'condition:update': boolean;
  'condition:delete': boolean;
}

const PrivateRoute: React.FC<PRProps> = ({ children, path, requiredPerms, exact }) => {
  const userData = useAuthContext();
  const [perms, setPerms] = useState({} as PermList);

  useEffect(() => {
    if (!Object.keys(perms).length && userData.jwtData) {
      const jwt = JSON.parse(String(userData.jwtData));
      setPerms(jwt['com.ehi.abac-perm.vehicle.condition']);
    }
  }, [userData, perms]);

  const confirmAccess = (permsReqd: string[]): boolean => {
    const permsMet = permsReqd.map((perm: string) => perms[perm as keyof PermList]);
    return !permsMet.includes(false);
  };

  return Object.keys(perms).length ? (
    <Route exact={exact} path={path} render={() => (confirmAccess(requiredPerms) ? children : <Redirect to='/' />)} />
  ) : (
    <div>Your role contains no permissions.</div>
  );
};

export default PrivateRoute;
