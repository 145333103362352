import { useEffect } from 'react';
import { initializePendoLogger } from '@ehi/analytics';
import { useAuth } from '@ehi/auth';
import i18n from './../i18n/i18n';
import { useBasePendoAttributes } from '../hooks/useBasePendoMetadata';

export const usePendoHandler = () => {
  const authInfo = useAuth();
  const language = i18n.language;

  useEffect(() => {
    initializePendoLogger(
      '4e6cc623-4b45-4421-74b9-4db4ec0735dc',
      authInfo.eid.toUpperCase(),
      authInfo.groupBranch.toUpperCase()
    );
  }, []);

  useBasePendoAttributes({
    eid: authInfo.eid.toUpperCase(),
    userGroupBranch: authInfo.groupBranch,
    userRole: authInfo.role,
    locale: language,
  });
};
