import styled from 'styled-components';
import { Box } from '@mui/material';

export const StyledConditionModal = styled.div`
  display: flex;
  flex-direction: column;
  font-family: Helvetica, Arial, sans-serif;
  .header {
    font-weight: bold;
    font-size: 18px;
  }
  .bold {
    font-weight: bold;
  }
  .ehi-blue {
    color: #4d789d !important;
  }
  .ehi-gray-background {
    background-color: #e2e9f0 !important;
  }
  .select-label {
    color: gray;
    font-size: smaller;
  }
  .select-label-container {
    /* padding: 11px; */
  }
  .active-button {
    background-color: #4d789d !important;
    box-shadow: none !important;
  }
  .grid-vehicle-image {
    position: relative;
  }
  .flatfish {
  }
  .flatfish-wrapper {
    overflow-y: hidden;
    overflow-x: hidden;
    display: flex;
    align-items: center;
    padding: 8px;

    svg {
      position: absolute;
      left: 50%;
      transform: translateX(-50%) translateY(-50%);
      max-width: 85%;
      height: 95%;
    }
  }
  .placeHolderImageBox {
    background-color: silver;
    display: inline-block;
    height: 0;
    padding-bottom: 80%;
    width: 100%;
  }
  .capitalize::first-letter {
    display: block;
    text-transform: capitalize;
  }
  p::first-letter {
    text-transform: capitalize;
  }
  .select-image-instructions {
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 16px;
    letter-spacing: 0.4px;
    color: rgba(0, 0, 0, 0.54);
    flex: none;
    order: 0;
    flex-grow: 0;
  }
`;

export const AddConditionDetailsBox = styled.div`
  border: 1px solid;
  border-color: lightgrey;
  border-radius: ${({ theme }) => theme.shape.borderRadius}px;
  padding: 10px;
  .takenOn-label {
    text-align: center;
    color: rgba(0, 0, 0, 0.54);
    padding-top: 3px;
    fontsize: '14px';
  }
`;

export const AddConditionHeader = styled(Box)`
  font-weight: bold;
  padding: 8px;
`;

export const ConditionLabel = styled(Box)`
  font-weight: default;
  margin-bottom: 10px;
`;

export const ConditionColumn = styled(Box)`
  margin: 4px;
  & > * {
    margin: 5px;
  }
`;
