import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import type { RootState } from './store';

export interface LossProvince {
  // "province" in lieu of "state" to avoid further confusion
  abbreviation: string;
  name: string;
  type: string;
}
export interface LossInformationState {
  factsOfLoss: string;
  lossLocation: string;
  lossCity: string;
  lossState?: LossProvince;
  lossCountry: string;
  timeOfLoss?: Date;
}

// Define the initial state using that type
export const initialState: LossInformationState = {
  factsOfLoss: '',
  lossLocation: '',
  lossCity: '',
  lossCountry: '',
};

export const lossInfoSlice = createSlice({
  name: 'lossInfo',
  initialState,
  reducers: {
    setLossInfo: (state, action: PayloadAction<Partial<LossInformationState>>) => {
      const newState: LossInformationState = {
        ...state,
        ...action.payload,
      };
      return newState;
    },
    removeLossState: (state) => {
      delete state.lossState;
    },
    setLossDate: (state, action: PayloadAction<Date>) => {
      state.timeOfLoss = action.payload;
    },
  },
});

export const { setLossInfo, setLossDate, removeLossState } = lossInfoSlice.actions;

// Other code such as selectors can use the imported `RootState` type
export const lossInfoData = (state: RootState) => state.lossInfo;

export default lossInfoSlice.reducer;
