import styled from 'styled-components';

export const StyledIcon = styled.span`
  .image-capture-upload {
    cursor: pointer;
    padding: 8px;
  }
  .back-button {
    color: white;
    position: absolute;
    top: 0;
    left: 0;
    z-index: 2;
    padding: 12px;
    background-color: black;
    opacity: 0.7;
    @media (min-width: 1024px) {
      display: none !important;
    }
  }
  .close-button {
    color: white;
    position: absolute;
    top: 0;
    right: 0;
    z-index: 2;
    padding: 12px;
    background-color: black;
    opacity: 0.7;
  }
  .previous-button {
    top: auto;
    right: auto;
    left: 0;
    bottom: 0;
    padding-left: 20px;
  }
  .next-button {
    left: auto;
    top: auto;
    right: 0;
    bottom: 0;
  }
  .image-action-button {
    color: white;
    z-index: 2;
    padding-top: 12px;
    padding-bottom: 12px;
    width: 100%;
    background-color: black;
    opacity: 0.6;
    border-right: black 2px solid;
  }
  .last {
    border: none;
  }
  .red {
    color: red;
  }
  .green {
    color: green;
  }
`;
