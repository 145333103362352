import React from 'react';
import { LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import TextField from '@mui/material/TextField';
import { useTranslation } from 'react-i18next';
import { TimePicker } from '@mui/x-date-pickers';
import { ThemeProvider } from '@mui/material/styles';
import { customTheme } from '../../styles/screens/StyledPickerTheme';
import i18next from 'i18next';
import { localeLookup } from '../../utils/language';
import { noop } from 'lodash';

interface ConditionTimePickerProps {
  title: string;
  fullWidth?: boolean;
  onChange?: Function;
  onError?: (reason: any, value: any) => void;
  value?: Date;
  disableFuture?: boolean;
}

/* Reusable component does not format date. This can be done when using on a screen page:
 * example: const newYears = new Date('2021/01/01');
 * const formattedDate = format(newYears, 'MM/DD/YYYY'); */

//  Time format coming back from API: "dateTime": "2021-11-08T17:29:35.863Z"

const ConditionTimePicker = ({
  title,
  fullWidth,
  onChange = noop,
  onError = noop,
  value,
  disableFuture = false,
}: ConditionTimePickerProps) => {
  const { t } = useTranslation();

  let maxTime;
  if (value && disableFuture) {
    maxTime = new Date();
  }

  return (
    <LocalizationProvider dateAdapter={AdapterDateFns} adapterLocale={localeLookup(i18next.language)}>
      <ThemeProvider theme={customTheme}>
        <TimePicker
          label={t(title)}
          value={value}
          maxTime={maxTime}
          disableIgnoringDatePartForTimeValidation
          onChange={(newValue: Date | null) => {
            if (onChange) {
              if (newValue && !isNaN(newValue?.getTime())) {
                onChange(newValue);
              }
            }
          }}
          onError={onError}
          renderInput={(params: any) => (
            <TextField
              fullWidth={fullWidth}
              sx={{ svg: { color: '#507b9f' } }}
              {...params}
              data-testid='time-picker-id'
            />
          )}
        />
      </ThemeProvider>
    </LocalizationProvider>
  );
};

export default ConditionTimePicker;
