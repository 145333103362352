import React, { Dispatch, SetStateAction, useEffect, useState } from 'react';
import {
  Grid,
  FormControl,
  FormControlLabel,
  Radio,
  RadioGroup,
  TextField,
  Select,
  InputLabel,
  Divider,
  Box,
  Modal,
  FormGroup,
  Checkbox,
} from '@mui/material';
import { debounce, isEmpty, keyBy } from 'lodash';
import { useTranslation } from 'react-i18next';
import { DxQuestions } from './questions/DxQuestions';
import { OxOtherLossQuestions } from './questions/OxOtherLossQuestions';
import { OxEmployeeLossQuestions } from './questions/OxEmployeLossQuestions';
import RentalAgreementSelection from '../RentalAgreementSelection';
import {
  claimData as claimState,
  setOnRent,
  setRentalAgreementNumber,
  selectClaimType,
  setClaimTypeId,
  selectIncidentType,
  setIncidentTypeId,
  setClaim,
  setGroupBranch,
  setDriverInformation,
  setRenterInformation,
  removeDriverInformation,
  selectDriverPersonalInformation,
  selectIsRenterDriver,
} from '../../../redux-tool-kit/claimSlice';
import { useAppDispatch, useAppSelector } from '../../../redux-tool-kit/hooks';
import {
  dxClaimTypes,
  dxIncidentTypes,
  getOxIncidentTypes,
  getQuestionYesNoAnswer,
  isZeroInfoClaim,
  oxClaimTypes,
} from '../../../utils/claims/ClaimHelpers';
import { ClaimInfoType, QuestionType } from '../../../types/enums';
import { useAppNavigation } from '../../../utils/useURLs';
import { BlackFormLabel, ConditionCardHeader, ConditionCardSubHeader, ConditionPaper } from '../../../styles/common';
import { claimPayloadInitialState } from '../../../types/claim/ClaimPayload';
import { StyledMenuItem } from '../../../styles/screens/StyledVehicleInfoGrid';
import { useSearchParams } from '../../../utils/useURLs';
import { NavButtons } from '../shared/NavButtons';
import { useEmployeeInfo } from '../../../hooks/useEmployeeInfo';
import { mostSignificant } from '../../../utils/urn';
import useLocationBranch from '../../../hooks/useLocationBranch';
import useIncidentType from '../../../hooks/useIncidentType';
import useClaimType from '../../../hooks/useClaimType';
import { Employee } from '../../../types/claim/person/Employee';
import useMultiRetrieve from '../../../hooks/useMultiRetrieve';
import useAppParams from '../../../hooks/useAppParams';

interface ClaimProps {
  storeEmp?: Dispatch<SetStateAction<Employee>>;
}

export const Claim = ({ storeEmp }: ClaimProps) => {
  const { t } = useTranslation();
  const navigation = useAppNavigation();

  const claimData = useAppSelector(claimState);

  const { vid: unitNumber } = useAppParams();

  const claimType = useAppSelector(selectClaimType);
  const incidentType = useAppSelector(selectIncidentType);

  const driverPersonalInfo = useAppSelector(selectDriverPersonalInformation);
  const isRenterDriver = useAppSelector(selectIsRenterDriver);

  const dispatch = useAppDispatch();
  const searchObject = useSearchParams();
  const isRentalAgreementInSearch = searchObject.has('conditionContext');

  const [showModal, setShowModal] = useState(false);
  const [isTemporaryFieldChecked, setIsTemporaryFieldChecked] = useState(false);
  const [selectedClaimType, setSelectedClaimType] = useState<boolean | null>(null);
  const [offrentDriverName, setOffrentDriverName] = useState('');
  const [eid, setEid] = useState('');
  const [raInvalid, setRaInvalid] = useState(false);
  const [invalidRaHelperText, setInvalidRaHelperText] = useState<string>('');
  const { data: employeeData, error: employeeError, isLoading: isEmployeeLoading } = useEmployeeInfo(eid);
  const { data: locationData, isLoading: isLocationLoading } = useLocationBranch(
    claimData.claimLevelInformation.branchLegacyIorgId
  );
  const { data: incidentTypeData } = useIncidentType();
  const { data: claimTypeData } = useClaimType();
  const debounceEmpId = debounce((eid: string) => setEid(eid), 750);
  const { data: manuallySearchedRentalAgreement, isLoading: raLoading } = useMultiRetrieve(
    claimData?.rentalContract?.rentalContractNumber
  );

  const getGroupBranchFromRentalAgreement = () => {
    return manuallySearchedRentalAgreement?.pickupInfo?.location?.groupBranchId ?? '';
  };

  // Handle manual RA search validation
  useEffect(() => {
    if (
      !raLoading &&
      claimData.rentalContract?.rentalContractNumber &&
      claimData.rentalContract?.rentalContractNumber.length > 0
    ) {
      if (!manuallySearchedRentalAgreement) {
        setRaInvalid(true);
        dispatch(setGroupBranch(''));
        setInvalidRaHelperText(t('errors.noMatchingRentalAgreementsFound'));
      } else if (
        manuallySearchedRentalAgreement?.vehicle.currentVehicle?.unitNumber !== unitNumber &&
        !manuallySearchedRentalAgreement?.vehicle.vehicleHistory.some((v) => v.unitNumber === unitNumber)
      ) {
        setRaInvalid(true);
        setInvalidRaHelperText(t('errors.vehicleNotAssignedToAnyRentalAgreements'));
        dispatch(setGroupBranch(''));
      } else {
        setRaInvalid(false);
        setInvalidRaHelperText('');
        dispatch(setGroupBranch(getGroupBranchFromRentalAgreement()));
        dispatch(
          setRenterInformation({
            personalInformation: {
              firstName: manuallySearchedRentalAgreement.driver.primaryDriver.firstName,
              lastName: manuallySearchedRentalAgreement.driver.primaryDriver.lastName,
            },
          })
        );
      }
    }
  }, [claimData.rentalContract?.rentalContractNumber, raLoading]);

  useEffect(() => window.scrollTo(0, 0), []);

  useEffect(() => {
    employeeData && storeEmp && storeEmp(employeeData);
    const groupBranch = (employeeData?.group ?? '') + (employeeData?.branch ?? '');
    dispatch(setGroupBranch(groupBranch));
  }, [employeeData]);

  const groupBranchValid = !isLocationLoading && typeof locationData === 'object';
  const employeeIdValid = !isEmployeeLoading && employeeData != undefined;

  useEffect(() => {
    if (isRentalAgreementInSearch || claimData.rentalContract?.rentalContractNumber) {
      dispatch(setOnRent(true));
      setSelectedClaimType(true);
      dispatch(setGroupBranch(getGroupBranchFromRentalAgreement()));

      const rentalAgreement = searchObject.get('conditionContext');

      if (rentalAgreement) {
        const significantRA = mostSignificant(rentalAgreement);

        if (significantRA) {
          dispatch(setRentalAgreementNumber(significantRA));
        }
      }
    } else {
      dispatch(setOnRent(false));
      setSelectedClaimType(false);
    }
  }, []);

  useEffect(() => {
    if (claimData.onRent) {
      dispatch(setClaimTypeId(1));
      if (!raInvalid) {
        dispatch(setGroupBranch(getGroupBranchFromRentalAgreement()));
      }
    }
  }, [claimData.onRent]);

  // Manually set Undocumented Damage incidentTypeId if Undocumented Damage claim type selected
  useEffect(() => {
    if (claimData.claimLevelInformation.claimTypeId === 3) {
      dispatch(setIncidentTypeId(12));
    }
  }, [claimData.claimLevelInformation.claimTypeId]);

  const handleStatusSelect = (e: { target: { value: string } }) => {
    dispatch(setOnRent(e.target.value === 'true'));
    setSelectedClaimType(e.target.value === 'true');

    if (e.target.value === 'true') {
      if (!claimData.rentalContract?.rentalContractNumber && !isRentalAgreementInSearch) {
        setShowModal(true);
      } else if (!claimData.rentalContract?.rentalContractNumber && isRentalAgreementInSearch) {
        const rentalAgreement = searchObject.get('conditionContext');

        if (rentalAgreement) {
          const significantRA = mostSignificant(rentalAgreement);

          if (significantRA) {
            dispatch(setRentalAgreementNumber(significantRA));
            if (!raInvalid) {
              dispatch(setGroupBranch(getGroupBranchFromRentalAgreement()));
            }
          }
        }
      }
    }
  };

  const onSubmit = () => {
    if (!isTemporaryFieldChecked && employeeData) {
      dispatch(
        setDriverInformation({
          firstName: employeeData.firstName,
          lastName: employeeData.lastName,
          middleName: employeeData.middleInitial,
        })
      );
    } else if (isTemporaryFieldChecked && offrentDriverName) {
      let firstName = '';
      let lastName: string;
      const names = offrentDriverName.split(' ');
      if (names.length === 1) {
        lastName = names[0];
      } else {
        lastName = names.pop() || '';
        firstName = names.join(' ');
      }
      dispatch(setDriverInformation({ firstName, lastName }));
    }

    navigation.push('/claim/loss-information');
  };

  const isSubmitEnabled = () => {
    if (!groupBranchValid) {
      return false;
    }
    if (claimData.onRent) {
      return (
        !!claimData.rentalContract?.rentalContractNumber &&
        (isRenterDriver || !isEmpty(driverPersonalInfo?.lastName)) &&
        !raInvalid &&
        (getQuestionYesNoAnswer(claimData.questionsInformation, QuestionType.POLICE_REPORT) !== '' || isZeroInfoClaim())
      );
    } else if (claimData.claimLevelInformation.claimTypeId === ClaimInfoType.EMPLOYEE_LOSS) {
      return (
        (eid.length === 6 || offrentDriverName.length > 0) &&
        (getQuestionYesNoAnswer(claimData.questionsInformation, QuestionType.RENTER_KNOWN_DAMAGE) === 'no' ||
          getQuestionYesNoAnswer(claimData.questionsInformation, QuestionType.POLICE_REPORT) !== '') &&
        (isTemporaryFieldChecked || employeeIdValid)
      );
    } else {
      return (
        getQuestionYesNoAnswer(claimData.questionsInformation, QuestionType.RENTER_KNOWN_DAMAGE) === 'no' ||
        getQuestionYesNoAnswer(claimData.questionsInformation, QuestionType.POLICE_REPORT) !== '' ||
        claimData.claimLevelInformation.claimTypeId === ClaimInfoType.UNDOCUMENTED_DAMAGE
      );
    }
  };

  const onGoBack = () => {
    dispatch(setClaim(claimPayloadInitialState));
    navigation.push('/capture');
  };

  const oxForm = (oxType: ClaimInfoType | null) => {
    switch (oxType) {
      case ClaimInfoType.EMPLOYEE_LOSS:
        return (
          <>
            {(claimData.onRent
              ? getQuestionYesNoAnswer(claimData.questionsInformation, QuestionType.DEDUCTIBLE_MATRIX_PRICING) !== ''
              : claimData.claimLevelInformation.claimTypeId !== null &&
                claimData.claimLevelInformation.incidentTypeId !== null) && <OxEmployeeLossQuestions />}
            {getQuestionYesNoAnswer(claimData.questionsInformation, QuestionType.INJURIES) !== '' && (
              <OxOtherLossQuestions />
            )}
          </>
        );
      case ClaimInfoType.OTHER_VEHICLE_LOSS:
        return (
          claimData.claimLevelInformation.claimTypeId !== null &&
          claimData.claimLevelInformation.incidentTypeId !== null && <OxOtherLossQuestions />
        );
      default:
        return null;
    }
  };

  const getClaimTypes = () => {
    if (claimTypeData) {
      return claimData.onRent === undefined
        ? []
        : claimData.onRent
        ? dxClaimTypes(claimTypeData)
        : oxClaimTypes(claimTypeData);
    } else {
      return [];
    }
  };

  const getIncidentTypes = () => {
    if (incidentTypeData) {
      return claimData.onRent === undefined
        ? []
        : claimData.onRent
        ? dxIncidentTypes(incidentTypeData)
        : getOxIncidentTypes(claimData.claimLevelInformation.claimTypeId, incidentTypeData);
    } else {
      return [];
    }
  };

  const invalidBranchText = `${t('invalidBranch')}`;
  const claimsTypeLabelText = `${t('claims.labels.claimType')} *`;
  const incidentTypeLabelText = `${t('claims.labels.incidentType')} *`;
  const claimTypeKey = keyBy(getClaimTypes(), 'value')?.[claimType ?? -1]?.key;
  const validRaInParams = isRentalAgreementInSearch && !raInvalid;

  return (
    <ConditionPaper>
      <Box marginLeft={3}>
        <ConditionCardHeader>{t('claims.claimInformation')}</ConditionCardHeader>
        <ConditionCardSubHeader>*{t('allFieldsRequired')}</ConditionCardSubHeader>
      </Box>
      <Grid container spacing={3} padding={3}>
        <Grid item xs={12} sm={6}>
          <FormControl fullWidth>
            <BlackFormLabel id='responsible-party-label'>{t('claims.labels.status')}</BlackFormLabel>
            <RadioGroup
              aria-labelledby='responsible-party-label'
              value={selectedClaimType}
              row
              onChange={handleStatusSelect}>
              <FormControlLabel
                disabled={validRaInParams}
                value={true}
                control={<Radio />}
                label={t('claims.labels.dxClaimType')}
              />
              <FormControlLabel
                disabled={validRaInParams}
                value={false}
                control={<Radio />}
                label={t('claims.labels.oxClaimType')}
              />
            </RadioGroup>
          </FormControl>
        </Grid>
        <Grid item xs={12} sm={6}>
          {claimData.onRent ? (
            <TextField
              fullWidth
              error={raInvalid}
              helperText={invalidRaHelperText}
              disabled={validRaInParams}
              value={claimData.rentalContract?.rentalContractNumber ?? ''}
              label={`${t('claims.labels.rentalAgreementNumber')} *`}
              onChange={(e) => dispatch(setRentalAgreementNumber(e.target.value))}
            />
          ) : (
            <Grid item xs={12} sm={6} className='fill' />
          )}
        </Grid>
        <Grid item xs={12} sm={6}>
          <FormControl fullWidth>
            <InputLabel id='claim-type-label'>{claimsTypeLabelText}</InputLabel>
            <Select
              labelId='claim-type-label'
              label={claimsTypeLabelText}
              value={claimType ?? ''}
              onChange={(e) => {
                dispatch(setClaimTypeId(Number(e.target.value)));
                if (claimData.onRent && !raInvalid) {
                  dispatch(setGroupBranch(getGroupBranchFromRentalAgreement()));
                }
                if (
                  Number(e.target.value) === ClaimInfoType.OTHER_VEHICLE_LOSS ||
                  Number(e.target.value) === ClaimInfoType.UNDOCUMENTED_DAMAGE
                ) {
                  dispatch(removeDriverInformation());
                }
              }}
              disabled={claimData.onRent === undefined}>
              {getClaimTypes().map(({ key, value }) => (
                <StyledMenuItem key={value} value={value}>
                  {key}
                </StyledMenuItem>
              ))}
            </Select>
          </FormControl>
        </Grid>
        <Grid item xs={12} sm={6}>
          <FormControl fullWidth>
            <InputLabel id='incident-type-label'>{incidentTypeLabelText}</InputLabel>
            {claimType === null || !isEmpty(getIncidentTypes()) ? (
              <Select
                labelId='incident-type-label'
                label={incidentTypeLabelText}
                value={incidentType ?? ''}
                onChange={(e) => {
                  dispatch(setIncidentTypeId(Number(e.target.value)));
                  if (claimData.onRent && !raInvalid) {
                    dispatch(setGroupBranch(getGroupBranchFromRentalAgreement()));
                  }
                }}
                disabled={claimData.onRent === undefined || claimType === null}>
                {getIncidentTypes().map(({ key, value }) => (
                  <StyledMenuItem key={value} value={value}>
                    {key}
                  </StyledMenuItem>
                ))}
              </Select>
            ) : (
              <Select labelId='incident-type-label' label={incidentTypeLabelText} value={claimType} disabled={true}>
                <StyledMenuItem value={claimType}>{t(claimTypeKey)}</StyledMenuItem>
              </Select>
            )}
          </FormControl>
        </Grid>
        {claimType === ClaimInfoType.EMPLOYEE_LOSS && (
          <>
            <Grid item xs={12} sm={6}>
              <FormGroup>
                <FormControlLabel
                  value='temporary'
                  control={
                    <Checkbox
                      checked={isTemporaryFieldChecked}
                      onChange={() => {
                        setOffrentDriverName('');
                        setEid('');
                        setIsTemporaryFieldChecked(!isTemporaryFieldChecked);
                      }}
                    />
                  }
                  label={t('temporary')}
                />
              </FormGroup>
            </Grid>
            {!isTemporaryFieldChecked && (
              <Grid item xs={12} sm={6}>
                <TextField
                  fullWidth
                  label={`${t('employeeId')} *`}
                  inputProps={{ maxLength: 6 }}
                  disabled={isEmployeeLoading}
                  error={eid.length !== 6 || employeeError || !employeeIdValid}
                  helperText={
                    eid.length !== 6
                      ? t('claims.mustBeSixCharacters')
                      : !employeeIdValid
                      ? t('claims.invalidId')
                      : t(employeeError)
                  }
                  onChange={(change) => {
                    debounceEmpId(change.target.value);
                  }}></TextField>
              </Grid>
            )}
            {isTemporaryFieldChecked && (
              <Grid item xs={12} sm={6}>
                <TextField
                  onChange={(change) => {
                    setOffrentDriverName(change.target.value);
                  }}
                  fullWidth
                  label={`${t('driverName')} *`}></TextField>
              </Grid>
            )}
          </>
        )}
        {(incidentType || (claimType && isEmpty(getIncidentTypes()))) && (
          <Grid item xs={6}>
            <TextField
              value={claimData.claimLevelInformation.branchLegacyIorgId ?? ''}
              fullWidth
              helperText={
                !groupBranchValid && claimData.claimLevelInformation.branchLegacyIorgId.length >= 1
                  ? invalidBranchText
                  : ''
              }
              error={!groupBranchValid && claimData.claimLevelInformation.branchLegacyIorgId.length >= 1}
              onChange={(e) => dispatch(setGroupBranch(e.target.value.toUpperCase()))}
              label={`${t('claims.labels.groupBranch')} *`}
              disabled={claimData.onRent}
            />
          </Grid>
        )}
        <Grid item xs={12}>
          <Divider />
        </Grid>
        {claimData.onRent
          ? claimData.claimLevelInformation.claimTypeId !== null &&
            claimData.claimLevelInformation.incidentTypeId !== null && (
              <>
                <DxQuestions />
                {oxForm(ClaimInfoType.EMPLOYEE_LOSS)}
              </>
            )
          : oxForm(claimData.claimLevelInformation.claimTypeId)}
        <NavButtons
          activeStep='claim'
          allRequiredFieldsFilled={isSubmitEnabled()}
          nextButtonClick={onSubmit}
          previousButtonClick={onGoBack}
        />
      </Grid>
      <Modal data-testid='rental-agreement-modal' open={showModal} onClose={setShowModal}>
        <div>
          <RentalAgreementSelection resetClaimType={setSelectedClaimType} modalShouldBeOpen={setShowModal} />
        </div>
      </Modal>
    </ConditionPaper>
  );
};
