import styled from 'styled-components';

export const StyledViewImagesModal = styled.span`
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 100%;
  height: 100vh;
  background-color: inherit;
  .fit {
    object-fit: contain;
  }
`;
