import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { Insurance, Person, PersonalInformation, QuestionInformation } from '../types';
import type { RootState } from './store';
import { claimPayloadInitialState } from '../types/claim/ClaimPayload';
import { getConfig } from '../utils/config';
import { claimLevelInformationInitialState } from '../types/claim/claimInformation/ClaimLevelInformation';

export const claimSlice = createSlice({
  name: 'claim',
  initialState: claimPayloadInitialState,
  reducers: {
    setOnRent: (state, action: PayloadAction<boolean>) => {
      const onRent = action.payload;
      state.onRent = onRent;
      state.claimLevelInformation = claimLevelInformationInitialState;
      state.questionsInformation = claimPayloadInitialState.questionsInformation;
      delete state.thirdPartyVehiclesInformation;
      delete state.policeDepartments;
      delete state.firstPartyVehicleInformation.renterIsAlsoDriver;
      delete state.rentalContract;
      if (!onRent) {
        delete state.firstPartyVehicleInformation.renter;
        delete state.firstPartyVehicleInformation.driver;
      }
    },
    setRentalAgreementNumber: (state, { payload }: PayloadAction<string>) => ({
      ...state,
      rentalContract: {
        ...state.rentalContract,
        rentalContractNumber: payload,
      },
    }),
    setClaimTypeId: (state, { payload }: PayloadAction<number | null>) => ({
      ...state,
      claimLevelInformation: {
        ...state.claimLevelInformation,
        claimTypeId: payload,
        incidentTypeId: null,
        groupLegacyIorgId: '',
        branchLegacyIorgId: '',
      },
    }),
    setIncidentTypeId: (state, { payload }: PayloadAction<number | null>) => ({
      ...state,
      claimLevelInformation: {
        ...state.claimLevelInformation,
        incidentTypeId: payload,
        groupLegacyIorgId: '',
        branchLegacyIorgId: '',
      },
    }),
    setClaim: (state, action: PayloadAction<object>) => {
      return {
        ...state,
        ...action.payload,
      };
    },
    addClaimQuestion: (state, action: PayloadAction<QuestionInformation>) => {
      const questions = state.questionsInformation.filter(
        (q: QuestionInformation) => q.questionTypeId !== action.payload.questionTypeId
      );
      questions.push(action.payload);
      return {
        ...state,
        questionsInformation: questions,
      };
    },
    addClaimQuestionDefault: (state, action: PayloadAction<QuestionInformation>) => {
      if (!state.questionsInformation.some(({ questionTypeId }) => questionTypeId === action.payload.questionTypeId)) {
        state.questionsInformation.push(action.payload);
      }
    },
    setGroupBranch: (state, action: PayloadAction<string>) => {
      state.claimLevelInformation.branchLegacyIorgId = action.payload;
      if (action.payload.length >= 2) {
        state.claimLevelInformation.groupLegacyIorgId = action.payload.substring(0, 2);
      } else {
        state.claimLevelInformation.groupLegacyIorgId = action.payload;
      }
    },
    setDriverInformation: (state, action: PayloadAction<PersonalInformation>) => {
      const driver = state.firstPartyVehicleInformation.driver || {};
      driver.personalInformation = action.payload;
      state.firstPartyVehicleInformation.driver = driver;
    },
    setRenterInformation: (state, action: PayloadAction<Person>) => {
      const renter = state.firstPartyVehicleInformation.renter || {};
      renter.partyInformation = action.payload;
      state.firstPartyVehicleInformation.renter = renter;
    },
    removeDriverInformation: (state) => {
      if (state.firstPartyVehicleInformation.driver) {
        delete state.firstPartyVehicleInformation.driver;
      }
      state.firstPartyVehicleInformation.renterIsAlsoDriver = false;
    },
    makeRenterNotDriver: (state) => {
      state.firstPartyVehicleInformation.renterIsAlsoDriver = false;
      if (state.firstPartyVehicleInformation.driver) {
        delete state.firstPartyVehicleInformation.driver.personalInformation;
      } else {
        state.firstPartyVehicleInformation.driver = {};
      }
      if (state.firstPartyVehicleInformation.renter?.partyInformation?.insurance) {
        state.firstPartyVehicleInformation.driver.insurance =
          state.firstPartyVehicleInformation.renter.partyInformation.insurance;
        delete state.firstPartyVehicleInformation.renter.partyInformation.insurance;
      }
    },
    makeRenterDriver: (state) => {
      state.firstPartyVehicleInformation.renterIsAlsoDriver = true;
      if (state.firstPartyVehicleInformation.driver?.insurance) {
        state.firstPartyVehicleInformation.renter = {
          ...state.firstPartyVehicleInformation.renter,
          partyInformation: {
            ...state.firstPartyVehicleInformation.renter?.partyInformation,
            insurance: state.firstPartyVehicleInformation.driver?.insurance,
          },
        };
      }
      delete state.firstPartyVehicleInformation.driver;
    },
    setInsuranceInformation: (state, action: PayloadAction<Insurance | undefined>) => {
      const isRenterInfo =
        state.firstPartyVehicleInformation.renterIsAlsoDriver ||
        state.firstPartyVehicleInformation.renterIsAlsoDriver === undefined;
      if (state.onRent && isRenterInfo && state.firstPartyVehicleInformation.renter?.partyInformation) {
        state.firstPartyVehicleInformation.renter.partyInformation.insurance = action.payload;
      } else if (state.firstPartyVehicleInformation.driver) {
        state.firstPartyVehicleInformation.driver.insurance = action.payload;
      }
    },
  },
});

export const {
  setClaim,
  setClaimTypeId,
  addClaimQuestion,
  addClaimQuestionDefault,
  setOnRent,
  setRentalAgreementNumber,
  setIncidentTypeId,
  setGroupBranch,
  setDriverInformation,
  setRenterInformation,
  removeDriverInformation,
  makeRenterDriver,
  makeRenterNotDriver,
  setInsuranceInformation,
} = claimSlice.actions;

export const claimData = (state: RootState) => state.claim;
export const selectClaimType = (state: RootState) => state.claim.claimLevelInformation.claimTypeId;
export const selectIncidentType = (state: RootState) => state.claim.claimLevelInformation.incidentTypeId;
export const selectRentalAgreement = (state: RootState) =>
  state.claim.rentalContract?.rentalContractNumber
    ? `urn:com.ehi:${getConfig().environment}:rental:rentalAgreement:${state.claim.rentalContract.rentalContractNumber}`
    : undefined;
export const selectDriverPersonalInformation = (state: RootState) =>
  state.claim.firstPartyVehicleInformation?.driver?.personalInformation;
export const selectIsRenterDriver = (state: RootState) =>
  !!state.claim.firstPartyVehicleInformation?.renterIsAlsoDriver ||
  state.claim.firstPartyVehicleInformation.driver == null;

export const selectInsuranceInfo = (state: RootState) => {
  const isRenterInfo =
    state.claim.firstPartyVehicleInformation.renterIsAlsoDriver ||
    state.claim.firstPartyVehicleInformation.renterIsAlsoDriver === undefined;
  if (state.claim.onRent && isRenterInfo) {
    return state.claim.firstPartyVehicleInformation.renter?.partyInformation?.insurance;
  } else {
    return state.claim.firstPartyVehicleInformation.driver?.insurance;
  }
};

export default claimSlice.reducer;
