import styled from 'styled-components';

export const StyledClaimRequiredModal = styled.span`
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 70%;
  background-color: white;
  padding: 7px;
  box-shadow: 0px 24px 38px rgba(0, 0, 0, 0.14), 0px 9px 46px rgba(0, 0, 0, 0.12), 0px 11px 15px rgba(0, 0, 0, 0.2);
  border-radius: 4px;
  color: black;
  .bold {
    font-weight: bold;
    font-size: 20px;
  }
`;
