import React, { FC, ReactNode, useEffect, useState } from 'react';

import { useLdProvider } from '../../services/launchDarkly/LaunchDarklyService';
import { CircularProgress } from '@mui/material';

type FeatureFlagProviderProps = {
  children: React.ReactNode;
};

export const LaunchDarklyProvider = ({ children }: FeatureFlagProviderProps): JSX.Element => {
  const [LDProvider, setLDProvider] = useState<FC<{ children: ReactNode }> | undefined>(undefined);
  const { getLdProvider } = useLdProvider();

  useEffect(() => {
    getLdProvider().then((provider) => setLDProvider(() => provider));
  }, []);

  return LDProvider ? <LDProvider>{children}</LDProvider> : <CircularProgress></CircularProgress>;
};
