import React from 'react';
import { useTranslation } from 'react-i18next';
import { Block, Check } from '@mui/icons-material';
import { Card, Typography, Stack } from '@mui/material';
import { EquipmentToggleButton } from '../../../styles/screens/StyledEquipmentToggleButton';

interface EquipmentToggleProps {
  onChange: (present: boolean) => unknown;
  isEquipmentPresent: boolean;
  name: string;
}

export const EquipmentToggle = ({ onChange, isEquipmentPresent, name }: EquipmentToggleProps) => {
  const { t } = useTranslation();
  return (
    <Card elevation={4} aria-labelledby={`${name}`}>
      <label id={`${name}`}>
        <strong>{name}</strong>
      </label>
      <Stack direction='row'>
        <EquipmentToggleButton
          variant='outlined'
          aria-label={`${name} present`}
          onClick={() => onChange(true)}
          endIcon={<Check />}
          sx={{ backgroundColor: isEquipmentPresent ? 'rgba(76, 175, 80, 0.08)' : 'transparent' }}
          color={isEquipmentPresent ? 'success' : 'primary'}>
          <Typography>{t('condition.capture.present')}</Typography>
        </EquipmentToggleButton>
        <EquipmentToggleButton
          variant='outlined'
          aria-label={`${name} missing`}
          onClick={() => onChange(false)}
          endIcon={<Block />}
          sx={{ backgroundColor: isEquipmentPresent ? 'transparent' : 'rgba(255, 161, 0, 0.1)' }}
          color={isEquipmentPresent ? 'primary' : 'warning'}>
          <Typography>{t('condition.capture.missing')}</Typography>
        </EquipmentToggleButton>
      </Stack>
    </Card>
  );
};
