import { store } from '../../redux-tool-kit/store';
import { ReportErrors } from '../../types';
import { setModal } from '../../redux-tool-kit/globalErrorModalSlice';

export const setReportErrorModal = (errors: ReportErrors, soft = false) => {
  const modalData = {
    displaying: true,
    type: soft ? 'ReportSubmitSoftErr' : 'ReportSubmitErr',
    modalContent: { ...errors },
  };

  store.dispatch(setModal(modalData));
};
