import { Button, Divider, Grid, Modal } from '@mui/material';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { StyledClaimRequiredModal } from '../../styles/screens';
import { useAppNavigation } from '../../utils/useURLs';

interface ClaimRequiredModalProps {
  open: boolean;
  onCancel: () => void;
}

export const ClaimRequiredModal: React.FunctionComponent<ClaimRequiredModalProps> = ({
  open,
  onCancel,
}: ClaimRequiredModalProps): JSX.Element => {
  const { t } = useTranslation();
  const navigation = useAppNavigation();

  const closeModalAndForward = () => {
    onCancel();
    navigation.push('/claim');
  };

  return (
    <Modal open={open} onClose={onCancel}>
      <StyledClaimRequiredModal>
        <Grid item container data-testid='claimRequired' padding={1}>
          <Grid item xs={12} className='title bold' data-testid='title'>
            {t('claims.labels.claimRequired')}
          </Grid>
        </Grid>
        <Divider />
        <Grid item container data-testid='claimRequiredMessage' padding={1}>
          <Grid item xs={12} data-testid='message'>
            {t('claims.claimRequiredMessage')}
          </Grid>
        </Grid>
        <Grid item container direction='column' alignItems='flex-end' data-testid='action-buttons' padding={1}>
          <Grid item xs={12}>
            <Button data-testid='cancel-button' sx={{ mr: '16px' }} onClick={() => onCancel()}>
              {t('cancel')}
            </Button>
            <Button variant='contained' data-testid='ok-button' onClick={closeModalAndForward}>
              {t('ok')}
            </Button>
          </Grid>
        </Grid>
      </StyledClaimRequiredModal>
    </Modal>
  );
};
