import { ConditionImage } from './ConditionImage';

export interface CommonCondition {
  comment: string;
  component: string;
  isClaimable: boolean;
  images: ConditionImage[];
  conditionType: string;
  claimNumber?: string;
  urn: string;
}

export interface Condition extends CommonCondition {
  closeDateTime?: string;
  openDateTime?: string;
  status?: string;
  claimNumber: string;
}

export type NewCondition = Omit<CommonCondition, 'urn'>;

export interface ConditionCheck {
  component: string;
  checked: boolean;
  isClaimable: boolean;
}

export const conditionInitialState: Condition = {
  component: '',
  isClaimable: false,
  conditionType: '',
  images: [],
  closeDateTime: '',
  claimNumber: '',
  urn: '',
  openDateTime: '',
  status: '',
  comment: '',
};
