import React from 'react';
import { Box, Grid, GridProps, Button, Typography } from '@mui/material';
import AddIcon from '@mui/icons-material/Add';
import VehicleSVG from './VehicleSVG';
import { useAppNavigation } from '../../../utils/useURLs';
import { mostSignificant } from '../../../utils/urn';
import { useTranslation } from 'react-i18next';

interface SingleFlatFishProps extends GridProps {
  imageHeight?: number;
  imageUrn: string;
  onSVGClick?: (e: React.MouseEvent) => void;
  addButton?: boolean;
  setLocalIndicators?: React.Dispatch<React.SetStateAction<NodeListOf<SVGUseElement>>>;
}

export const SingleFlatFish = ({
  imageHeight,
  imageUrn,
  addButton,
  onSVGClick,
  setLocalIndicators,
  ...props
}: SingleFlatFishProps) => {
  const { t } = useTranslation();
  const navigation = useAppNavigation();
  const onFlatfishSelect = (_e: any): void => {
    navigation.push(`/add/${mostSignificant(imageUrn)}`);
  };

  return (
    <Grid item container spacing={0} {...props}>
      <Grid item xs={12} sx={{ width: '100%' }}>
        <Box className='flatfish-wrapper' height={imageHeight}>
          <VehicleSVG imageUrn={imageUrn} clickHandler={onSVGClick} setLocalIndicators={setLocalIndicators} />
          {addButton && (
            <Button
              style={{ top: 30, width: 'fit-content' }}
              variant='outlined'
              startIcon={<AddIcon />}
              data-testid='flatfish-add-button'
              className='flatfish-add-button'
              onClick={onFlatfishSelect}>
              <Typography className='flatfish-add-button-text'>{t('add')}</Typography>
            </Button>
          )}
        </Box>
      </Grid>
    </Grid>
  );
};
