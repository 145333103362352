import React from 'react';
import { Box, Grid, Typography } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { ConditionImage } from '../../../types';
import { setImgElemStyle } from '../../../utils/condition/ImageDisplayHelper';
import { visTimestampToEpoch } from '../../../utils/image';

interface ImageThumbnailProps {
  img: ConditionImage;
  onClick: () => void;
}

const ImageThumbnail = ({ img, onClick }: ImageThumbnailProps) => {
  const { t } = useTranslation();
  const epochTime = visTimestampToEpoch(img);
  return (
    <Grid data-testid={epochTime.toISOString()} item padding={1} xs={6} sm={3}>
      <Box sx={setImgElemStyle(img)} onClick={onClick} />
      <Typography className='takenOn-label'>{t('takenOn')}</Typography>
      <Typography sx={{ textAlign: 'center' }}>
        <b>{t('intlImageDate', { date: epochTime })}</b>
        {t('intlImageTime', { date: epochTime })}
      </Typography>
    </Grid>
  );
};

export default ImageThumbnail;
