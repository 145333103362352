import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { GlobalErrorModalState, GlobalErrorModalPayload, SoftErrorDismissPayload } from '../types';
import type { RootState } from './store';

export const globalErrorModalSliceInitialState: GlobalErrorModalState = {
  displaying: false,
  type: '',
  modalContent: null,
  softErrorDismiss: false,
};

export const globalErrorModal = createSlice({
  name: 'globalErrorModal',
  initialState: globalErrorModalSliceInitialState,
  reducers: {
    setModal: (state, action: PayloadAction<GlobalErrorModalPayload>) => {
      const newState: GlobalErrorModalState = {
        ...state,
        ...action.payload,
      };
      return newState;
    },
    clearModal: (state) => {
      const newState: GlobalErrorModalState = {
        ...state,
        ...globalErrorModalSliceInitialState,
      };
      return newState;
    },
    setSoftErrorDismiss: (state, action: PayloadAction<SoftErrorDismissPayload>) => {
      const newState: GlobalErrorModalState = {
        ...state,
        ...action.payload,
      };
      return newState;
    },
  },
});

export const { setModal, clearModal, setSoftErrorDismiss } = globalErrorModal.actions;

export const globalErrorModalData = (state: RootState) => state.globalErrorModal;

export default globalErrorModal.reducer;
