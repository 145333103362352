import styled from 'styled-components';

export const StyledImageCarousel = styled.div`
  .header {
    padding: 15px;
    font-weight: bolder;
  }
  .date-time-taken {
    padding: 10px;
  }
  div.react-swipeable-view-container div[data-swipeable] {
    background-color: #fff;

    > div {
      max-width: 100%;
      max-height: 100%;
      position: relative;
      top: 50%;
      transform: translateY(-50%);
      overflow: hidden;
    }
  }
`;
