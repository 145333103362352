import React, { useState } from 'react';
import { uniqueId } from 'lodash';

import ImageWidget from '../../../shared/images/ImageWidget';
import { useAppDispatch } from '../../../redux-tool-kit/hooks';
import { addDocument } from '../../../redux-tool-kit/documentsSlice';
import { Box, Button, Dialog, Divider, Grid } from '@mui/material';
import { setClaimImgElemStyle } from '../../../utils/condition/ImageDisplayHelper';
import { ImageCarousel } from '../../../shared/images/ImageCarousel/ImageCarousel';
import { useTranslation } from 'react-i18next';
import { StyledClaimUpload } from '../../../styles/screens';

interface DocumentUploaderProps {
  carouselHeader?: string;
}

const DocumentUploader = ({ carouselHeader }: DocumentUploaderProps) => {
  const dispatch = useAppDispatch();
  const [files, setFiles] = useState<Record<string, string>[]>([]);
  const [initialImageSelection, setInitialImageSelection] = useState(0);
  const [showCarousel, setShowCarousel] = useState(false);
  const { t } = useTranslation();

  const createDatedImages = (files: Record<string, string>[]) => {
    const carouselFiles = files.map((curFile) => ({
      src: curFile.img,
    }));
    return carouselFiles;
  };

  return (
    <StyledClaimUpload container spacing={2}>
      {files.map((img, idx) => (
        <Grid item key={img.name} xs={3} sm={3} data-testid={img.name}>
          <Box
            sx={setClaimImgElemStyle(img.img)}
            onClick={() => {
              setInitialImageSelection(idx);
              setShowCarousel(true);
            }}
          />
        </Grid>
      ))}
      <Grid item xs={3} sm={3}>
        <ImageWidget
          onUpload={(img: string, name?: string) => {
            const filename = name ?? `${uniqueId('document_')}.bin`;
            const curFile1 = { name: filename, img };
            const curFile2 = { name: filename, bytes: img.split(',')[1] };
            setFiles([...files, curFile1]);
            dispatch(addDocument(curFile2));
          }}
        />
      </Grid>
      <Dialog open={showCarousel} onClose={() => setShowCarousel(false)}>
        <ImageCarousel
          images={createDatedImages(files)}
          initialIndex={initialImageSelection}
          header={carouselHeader || t('uploadedImages')}
        />
        <Divider />
        <Grid container justifyContent='flex-end'>
          <Grid item>
            <Button size='small' variant='contained' onClick={() => setShowCarousel(false)}>
              {t('close')}
            </Button>
          </Grid>
        </Grid>
      </Dialog>
    </StyledClaimUpload>
  );
};

export default DocumentUploader;
