export enum QuestionType {
  RENTER_PRESENT = 1,
  RENTER_KNOWN_DAMAGE = 2,
  DRIVER_IS_RENTER = 3,
  DRIVER_INSURANCE_INFO = 4,
  PASSENGERS_IN_OUR_VEHICLE = 5,
  OTHER_VEHICLE_INVOLVED = 6,
  THIRD_PARTY_HAS_INSURANCE = 7,
  PASSENGERS_IN_OTHER_VEHICLE = 8,
  WITNESS_PEDESTRIAN_INVOLVED = 9,
  INJURIES = 10,
  POLICE_REPORT = 11,
  //  AMOUNT_OWED = 12,
  DEDUCTIBLE_MATRIX_PRICING = 13,
  //  EU_ACCIDENT_REPORT = 14,
}
