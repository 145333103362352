import { Condition, NewCondition } from './Condition';
import { ConditionSource } from './ConditionSource';
import { OdometerReading } from './OdometerReading';
import { ConditionReportPayload } from './ConditionReportPayload';
import { omit } from 'lodash';

export interface ConditionSubmitBody {
  wasVehiclePresent: boolean;
  odometerReading: OdometerReading;
  source: ConditionSource;
  comment: string;
  overriddenConditions?: Condition[];
  newConditions?: NewCondition[];
  closedConditions?: Condition[];
}

export function mapConditionData(conditionBody: ConditionReportPayload): ConditionSubmitBody {
  return {
    wasVehiclePresent: conditionBody.wasVehiclePresent,
    odometerReading: conditionBody.odometerReading,
    source: conditionBody.source,
    comment: conditionBody.comment,
    overriddenConditions: conditionBody.overriddenConditions,
    newConditions: Object.values(conditionBody.newConditions || []).map((condition) => omit(condition, 'urn')),
    closedConditions: conditionBody.closedConditions,
  };
}
