import jwtDecode from 'jwt-decode';

export const jwtObString = (jwt: string) => {
  const decode = jwtDecode(jwt);
  return JSON.stringify(decode);
};

export const cookieTranslation = (cookie: string) => {
  // get the "ehi-user" part of the cookie
  let cookieString: string[] | string = cookie.split(';').filter((item) => item.includes('ehi'));
  cookieString = cookieString.find((memb) => memb.indexOf('ehi-user') !== -1) || '';

  // decode entity translations in cookie object
  cookieString = decodeURI(cookieString);
  cookieString = cookieString.replace(/%3A/g, ':').replace(/%2C/g, ',');

  // turn string into expression
  cookieString = cookieString.split('=')[1];
  const cookieData = JSON.parse(cookieString);
  // get translated JWT (as string)
  cookieData.jwtData = jwtObString(cookieData.jwt);

  return cookieData;
};
