import { ClaimInfoType } from './enums/ClaimInfoType';
import { ClaimWorkflowStageTypeId } from './enums/ClaimWorkflowStageType';
import { ClaimLocation } from './ClaimLocation';
import { FirstNoticeOfLossInformation } from './FirstNoticeOfLossInformation';
import { Note } from './Note';

export interface ClaimLevelInformation {
  accidentReportedByGroupBranchLegacyNumber?: string;
  accidentReportedByUserFirstName?: string;
  accidentReportedByUserId?: string;
  accidentReportedByUserLastName?: string;
  accidentReportedDate?: Date;
  accidentReportedApplication?: string;
  accidentReportCompletedByGroupBranchLegacyNumber?: string;
  accidentReportCompletedByUserFirstName?: string;
  accidentReportCompletedByUserId?: string;
  accidentReportCompletedByUserLastName?: string;
  accidentReportCompletedDate?: Date;
  accidentReportCompletedApplication?: string;
  branchLegacyIorgId: string;
  claimId?: number;
  claimNumber?: number;
  claimLocation?: ClaimLocation;
  claimSourceCompanyType?: string;
  claimTypeId: ClaimInfoType | null;
  claimWorkFlowStageTypeId?: ClaimWorkflowStageTypeId;
  companyClaimNumber?: string;
  factsOfLoss: string;
  groupLegacyIorgId: string;
  incidentTypeId: number | null;
  keysReturned?: boolean;
  legacyClaimNumber?: string;
  legacyShortClaimNumber?: string;
  lossCity?: string;
  lossCounty?: string;
  lossCountryIsoCode: string;
  lossDate?: Date;
  lossLocation?: string;
  lossState?: string;
  notes?: Note[];
  numberOfPassengers?: number;
  privateClaim?: boolean;
  theftReportedAgencyName?: string;
  theftReportedDate?: string;
  tripPurpose?: string;
  lossId?: string;
  bypassClaimIndicator?: boolean;
  validUnitNoIndicator?: boolean;
  mainCauseCode?: string;
  subCauseCode?: string;
  fnolInformation?: FirstNoticeOfLossInformation;
}

export const claimLevelInformationInitialState: ClaimLevelInformation = {
  branchLegacyIorgId: '',
  claimTypeId: null,
  factsOfLoss: '',
  groupLegacyIorgId: '',
  incidentTypeId: null,
  lossCountryIsoCode: '',
  accidentReportCompletedApplication: 'Condition Capture',
};
