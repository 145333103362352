import React from 'react';
import { Grid, Checkbox, Typography } from '@mui/material';
import { groupBy, isEmpty } from 'lodash';
import { checkAllOfType } from '../../../redux-tool-kit/conditionSlice';
import { store } from '../../../redux-tool-kit/store';
import VehicleConditionGumstick from './VehicleConditionGumstick';
import { StyledDot } from '../../../styles/screens/StyledVehicleCondition';
import { CommonCondition } from '../../../types/conditionTypes/Condition';
import { useTranslation } from 'react-i18next';

interface VehicleConditionsListProps {
  conditions: CommonCondition[];
  isModal: boolean;
  isAllChecked?: boolean;
  claimable: boolean;
}

const VehicleConditionsList = ({
  conditions,

  isModal,
  isAllChecked = false,
  claimable,
}: VehicleConditionsListProps) => {
  const { t } = useTranslation();
  const damageType = claimable ? 'damage' : 'wearAndTear';
  const title = claimable ? t('condition.capture.damages') : t('condition.capture.wearTear');
  const tempConditionLists = groupBy(conditions, ({ component }) => component);

  const handleSelectAllChange = () => {
    store.dispatch(checkAllOfType(claimable));
  };

  const getModalXsNumber = () => {
    if (damageType === 'damage' && isModal) {
      return 4.5;
    }
    if (damageType === 'damage' && !isModal) {
      return 3.5;
    }
    if (!isModal) {
      return 4.6;
    } else {
      return 6;
    }
  };

  return (
    <Grid container data-testid={damageType}>
      <Grid container data-testid='damage-title' paddingBottom={2} alignItems='center'>
        {!isModal && (
          <Grid item md={1} xs={1.5}>
            <Checkbox
              data-testid='toggle-icon'
              id={title}
              disabled={isEmpty(conditions)}
              checked={isAllChecked}
              onChange={handleSelectAllChange}
            />
          </Grid>
        )}
        <Grid item md={damageType === 'damage' ? 2.2 : 2.8} xs={getModalXsNumber()}>
          <Typography className='condition-list-title' sx={{ color: 'gray', marginLeft: isModal ? '20px' : 0 }}>
            <>
              {title} ({conditions.length})
            </>
          </Typography>
        </Grid>
        <Grid item xs={1}>
          <StyledDot title={title} style={{ backgroundColor: damageType === 'damage' ? '#FFA100' : '#6A3E9D' }} />
        </Grid>
      </Grid>
      <Grid item xs={isModal ? 11.5 : 12}>
        {tempConditionLists &&
          Object.entries(tempConditionLists).map(([key, conditionList]) => (
            <VehicleConditionGumstick key={key} isModal={isModal} conditions={conditionList} />
          ))}
      </Grid>
    </Grid>
  );
};
export default VehicleConditionsList;
