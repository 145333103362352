import React from 'react';
import { Grid, TextField } from '@mui/material';
import { Question } from '../Question';
import { YesNoResponse } from '../YesNoResponse';
import { useTranslation } from 'react-i18next';
import { addClaimQuestion, claimData as claimState, setClaim } from '../../../../redux-tool-kit/claimSlice';
import { useAppDispatch, useAppSelector } from '../../../../redux-tool-kit/hooks';
import { getQuestionYesNoAnswer } from '../../../../utils/claims/ClaimHelpers';
import { QuestionType } from '../../../../types/enums';
import DocumentUploader from '../DocumentUploader';
import { YesNo } from '../../../../types/util/UtilTypes';

export const OxOtherLossQuestions = () => {
  const claimData = useAppSelector(claimState);
  const { t } = useTranslation();
  const dispatch = useAppDispatch();

  return (
    <Grid container item data-testid='other-loss-questions' sx={{ pt: '0px !important', marginBottom: '3%' }}>
      <Question>{t('claims.policeReport')}</Question>
      <YesNoResponse
        change={(e: React.MouseEvent<HTMLElement, MouseEvent>, value: YesNo): void => {
          dispatch(setClaim({ policeDepartments: [] }));
          dispatch(
            addClaimQuestion({
              questionTypeId: QuestionType.POLICE_REPORT,
              answerIndicator: value === 'yes',
            })
          );
        }}
        value={getQuestionYesNoAnswer(claimData.questionsInformation, QuestionType.POLICE_REPORT)}
      />
      {getQuestionYesNoAnswer(claimData.questionsInformation, QuestionType.POLICE_REPORT) === 'yes' && (
        <Grid
          container
          sx={{ backgroundColor: 'rgba(245,245,245,1)', marginTop: '3%', paddingLeft: '2rem' }}
          justifyContent='center'>
          <Grid container sx={{ width: '90%' }}>
            <Grid item xs={12}>
              <p>
                <strong style={{ color: 'rgba(0,0,0,0.87)' }}>{`${t('claims.policeReportLabel')}`} </strong>
              </p>
            </Grid>
            <Grid item xs={6}>
              <TextField
                sx={{ mt: 1, width: '90%' }}
                label={t('claims.labels.reportNumber')}
                InputProps={{ 'aria-label': t('claims.labels.reportNumber') }}
                variant='outlined'
                value={claimData.policeDepartments![0]?.reportNumber ?? ''}
                onChange={(e: React.ChangeEvent<HTMLInputElement>): void => {
                  dispatch(
                    setClaim({
                      policeDepartments: [{ ...claimData.policeDepartments![0], reportNumber: e.target.value }],
                    })
                  );
                }}
              />
            </Grid>
            <Grid item xs={6}>
              <TextField
                sx={{ mt: 1, width: '90%' }}
                label={t('claims.labels.stationName')}
                InputProps={{ 'aria-label': t('claims.labels.stationName') }}
                variant='outlined'
                value={claimData.policeDepartments![0]?.departmentName ?? ''}
                onChange={(e: React.ChangeEvent<HTMLInputElement>): void => {
                  dispatch(
                    setClaim({
                      policeDepartments: [{ ...claimData.policeDepartments![0], departmentName: e.target.value }],
                    })
                  );
                }}
              />
            </Grid>
            <DocumentUploader carouselHeader={t('claims.claimInformationDocuments')} />
          </Grid>
        </Grid>
      )}
    </Grid>
  );
};
