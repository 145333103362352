import { store } from '../../redux-tool-kit/store';
import { ConditionSubmitBody } from '../../types/conditionTypes/ConditionSubmit';
import { getConfig } from '../../utils/config';
import { failConditionCapture } from '../../utils/navigation';
import { edgePostFetcher } from '../index';

export const submitConditionReport = async (report: ConditionSubmitBody, vid: string) => {
  const jwt = store.getState().user.jwt;
  const url = `${getConfig().submitConditionReport}&id=${vid}`;
  try {
    const { conditionReport } = await edgePostFetcher(url, jwt, report, 'errors.submitConditionError');
    return conditionReport;
  } catch (e) {
    console.error(e);
    failConditionCapture();
  }
};
