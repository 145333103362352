import styled from 'styled-components';
import { Grid, Skeleton } from '@mui/material';

export const StyledBasicVehicleData = styled.div`
  .label {
    width: 90px;
  }
  .fill {
    width: 148px;
  }
  padding: 5px 5px 10px 5px;
  color: #000000;
`;

export const StyledBasicVehicleDataClaims = styled.div`
  padding-left: 150px;
`;

export const StyledVehicleConditionHeader = styled(Grid)`
  font-size: 20px;
  font-weight: bold;
  line-height: 24px;
  letter-spacing: 0.15px;
`;

export const StyledVehicleHeaderRight = styled(Grid)`
  font-size: 20px;
  margin-left: auto;

  font-weight: bold;
  line-height: 24px;
  text-align: right;
  width: 148px;
  letter-spacing: 0.15px;
`;

export const StyledVehicleMakeHeader = styled(Grid)`
  line-height: 24px;
  letter-spacing: 0.15px;
  .bold {
    font-size: 20px;
  }
  .connected {
    padding-left: 0.5em;
    margin-bottom: -0.07em;
    align-self: center;
  }
  .MuiSvgIcon-root {
    padding-left: 3px;
    margin-bottom: -0.15em;
    align-self: center;
  }
`;

export const StyledRightSideValues = styled(Grid)`
  white-space: pre;
  font-size: 16px;
  margin-bottom: 10px;
  font-weight: 700;
  line-height: 24px;
`;

export const BasicVehicleDataCard = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 35px 35px 15px 30px;
  margin-bottom: 20px;
  box-shadow: 0px 8px 10px rgba(0, 0, 0, 0.14), 0px 3px 14px rgba(0, 0, 0, 0.12), 0px 5px 5px rgba(0, 0, 0, 0.2);
  background-color: #fff;
`;

export const RaLabel = styled(Grid)`
  font-size: 12px;
  font-weight: 400;
  line-height: 16px;
  display: flex;
  position: relative;
  left: 0;
  letter-spacing: 0.4px;
  color: #666666;
  flex: none;
  order: 0;
  flex-grow: 0;
  margin-right: 100px;
`;

export const StyledLabel = styled(Grid)`
  font-size: 12px;
  font-weight: 400;
  line-height: 16px;
  display: flex;
  align-items: center;
  letter-spacing: 0.4px;
  color: #666666;
  flex: none;
  order: 0;
  padding: 5px;
  flex-grow: 0;
`;

export const StyledRightSideLabel = styled(Grid)`
  font-size: 12px;
  font-weight: 400;
  line-height: 16px;
`;

export const StyledValue = styled(Grid)`
  font-size: 16px;
  font-weight: 400;
  line-height: 24px;
  letter-spacing: 0.5px;
  color: rgba(0, 0, 0, 0.87);
  flex: none;
  order: 1;
  flex-grow: 0;
`;

export const StyledWidgetPlaceholder = styled(Skeleton)`
  position: relative;
  top: -8px;
  left: 5px;
  width: 30px;
  height: 40px;
`;
