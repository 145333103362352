import type {} from '@mui/lab/themeAugmentation';
import { createTheme } from '@mui/material/styles';

export const customTheme = createTheme({
  palette: {
    primary: {
      main: '#4d789d',
    },
  },
});
