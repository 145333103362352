import useSWRImmutable from 'swr/immutable';

import { useAppSelector } from '../redux-tool-kit/hooks';
import { selectJwt } from '../redux-tool-kit/userSlice';
import { getConfig } from '../utils/config';
import { edgeGetFetcher } from '../services';
import useAppParams from './useAppParams';
import { ExistingClaimsResponse } from '../types/apiTypes';
import useUnitLocation from './useUnitLocation';

const fetcher = ([url, jwt, unitLocation]: [string, string | null, string | null]) =>
  edgeGetFetcher(url, jwt, 'errors.getExistingClaimsError', { 'condition-unit-location': unitLocation }).then(
    (states) => states
  );

export const useExistingClaims = () => {
  const jwt = useAppSelector(selectJwt);
  const { vid } = useAppParams();
  const unitLocation = useUnitLocation();
  return useSWRImmutable<ExistingClaimsResponse[]>(
    vid ? [`${getConfig().getExistingClaim}&unitNumber=${vid}`, jwt, unitLocation] : null,
    fetcher
  );
};
