import i18n from 'i18next';
import moment from 'moment';
import LanguageDetector from 'i18next-browser-languagedetector';
import Backend from 'i18next-http-backend';
import { initReactI18next } from 'react-i18next';

i18n
  .use(Backend)
  .use(initReactI18next)
  // Detects user language
  .use(LanguageDetector)
  .init({
    // Required locales defined below
    supportedLngs: ['en-US', 'en-CA', 'en-GB', 'fr-CA', 'fr-FR', 'de-DE', 'es-ES'],
    fallbackLng: 'en-US',
    keySeparator: '.',
    load: 'currentOnly',
    backend: {
      loadPath: '/locales/{{lng}}/translation.json?cb=' + new Date().getTime(),
    },
    react: {
      useSuspense: true,
    },
    interpolation: {
      escapeValue: false,
      format: function (value, format) {
        if (value instanceof Date) return moment(value).format(format);
        return value;
      },
    },

    // Code block below specifies where i18Next should look for a user's language in the browser
    detection: {
      order: ['sessionStorage', 'cookie', 'queryString'],
      lookupSessionStorage: 'i18nextLng',
      caches: ['sessionStorage'],
    },
  });

export default i18n;
