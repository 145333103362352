import { MenuItem } from '@mui/material';
import styled from 'styled-components';

export const StyledMenuItem = styled(MenuItem)`
  color: #4d789d;
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  letter-spacing: 0.5px;
`;
